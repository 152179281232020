var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"d-flex flex-column"},[_c('Title',{attrs:{"text":"tasks.field"}}),_c('div',{staticClass:"d-flex flex-column align-self-center",staticStyle:{"max-width":"1200px"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"30"}},[_vm._v("mdi-soccer-field")]),_vm._v(" "+_vm._s(_vm.$ml.get("tasks.selectField"))+" ")],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.taskField.field = 'white'}}},[_vm._v(" En blanc ")]),_c('v-list-item',{on:{"click":function($event){_vm.taskField.field = 'football'}}},[_vm._v(" Futbol camp sencer ")]),_c('v-list-item',{on:{"click":function($event){_vm.taskField.field = 'half_football'}}},[_vm._v(" Futbol mig camp ")]),_c('v-list-item',{on:{"click":function($event){_vm.taskField.field = 'futsal'}}},[_vm._v(" Futbol sala camp sencer ")]),_c('v-list-item',{on:{"click":function($event){_vm.taskField.field = 'half_futsal'}}},[_vm._v(" Futbol sala mig camp ")])],1)],1),_c('v-btn',{staticClass:"ml-4",on:{"click":function () { return (_vm.resetDialog = true); }}},[_c('v-icon',[_vm._v("mdi-reload")]),_vm._v(" Reset ")],1)],1),_c('div',{staticClass:"d-flex align-center justify-space-around my-2"},[_c('div',{staticClass:"\n          tool-box\n          d-flex\n          flex-column\n          align-center\n          justify-space-around\n          pa-2\n        "},[_c('div',{staticClass:"pa-1",class:{ 'tool-selected': _vm.tool === 'players' }},[_c('Player',{attrs:{"color":_vm.iconColor,"size":40},nativeOn:{"click":function($event){_vm.tool = _vm.tool !== 'players' ? 'players' : null}}})],1),_vm._l((_vm.iconsEntries),function(ref){
var type = ref[0];
var icon = ref[1];
return _c('div',{key:type,staticClass:"pa-2",class:{ 'tool-selected': _vm.tool === type }},[_c('v-icon',{staticStyle:{"fill":"black"},attrs:{"size":"30","color":"black"},on:{"click":function () { return (_vm.tool = _vm.tool !== type ? type : null); }}},[_vm._v(" "+_vm._s(icon)+" ")])],1)}),_c('div',{staticClass:"pa-2",class:{ 'tool-selected': _vm.tool === 'lines' },on:{"click":function () { return (_vm.tool = _vm.tool !== 'lines' ? 'lines' : null); }}},[_c('svg',{attrs:{"height":"30","width":"30"}},[_c('line',{staticStyle:{"stroke-width":"2","stroke":"black"},attrs:{"x1":"0","y1":"0","x2":"30","y2":"30"}})])]),_c('div',{staticClass:"pa-2",class:{ 'tool-selected': _vm.tool === 'dashed' },on:{"click":function () { return (_vm.tool = _vm.tool !== 'dashed' ? 'dashed' : null); }}},[_c('svg',{attrs:{"height":"30","width":"30"}},[_c('line',{staticStyle:{"stroke-width":"2","stroke":"black"},attrs:{"x1":"0","y1":"0","x2":"5","y2":"5"}}),_c('line',{staticStyle:{"stroke-width":"2","stroke":"black"},attrs:{"x1":"10","y1":"10","x2":"20","y2":"20"}}),_c('line',{staticStyle:{"stroke-width":"2","stroke":"black"},attrs:{"x1":"25","y1":"25","x2":"30","y2":"30"}})])])],2),_c('div',{ref:"field",staticClass:"field pa-2 mx-4",on:{"click":_vm.clickField,"mousemove":_vm.mouseMove}},[_vm._l((_vm.taskField.players),function(player,index){return _c('div',{key:("Player " + index),staticClass:"icon pa-4 rounded-circle",class:_vm.playersClass[index],style:(_vm.playersStyle[index]),on:{"click":function($event){$event.stopPropagation();return _vm.onSelect(player, 'players', index)}}},[_c('Player',{attrs:{"color":player.color,"size":player.size}})],1)}),_vm._l((Object.entries(_vm.taskField.icons)),function(ref){
var type = ref[0];
var iconsList = ref[1];
return _c('div',{key:type},_vm._l((iconsList),function(icon,index){return _c('div',{key:(type + " " + index),staticClass:"icon pa-2 rounded-circle",class:_vm.iconsClass[type][index],style:(_vm.iconsStyle[type][index]),on:{"click":function($event){$event.stopPropagation();return _vm.onSelect(icon, type, index, true)}}},[_c('v-icon',{attrs:{"color":icon.color || 'black',"size":icon.size || 40}},[_vm._v(" "+_vm._s(_vm.icons[type])+" ")])],1)}),0)}),_vm._l((_vm.taskField.texts),function(item,index){return _c('span',{key:("Text " + index),staticClass:"text pa-4 rounded-circle",class:_vm.textsClass[index],style:(_vm.textsStyle[index]),on:{"click":function($event){$event.stopPropagation();return _vm.onSelect(item, 'texts', index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),_vm._l((_vm.taskField.lines),function(line,index){return _c('div',{key:("Line " + index),staticClass:"line pa-2",class:_vm.linesClass[index],style:(_vm.linesStyle[index]),on:{"mousemove":_vm.mouseMove,"click":function($event){$event.stopPropagation();return _vm.onSelect(line, 'lines', index)}}},[_c('div',{staticStyle:{"height":"0"},style:(("border-top: 3px " + (line.dashed ? 'dashed' : 'solid') + " " + (line.color || 'black')))})])}),(_vm.tempLine)?_c('div',{staticClass:"line pa-2",style:({
            left: ((_vm.tempLine.x) + "%"),
            top: ((_vm.tempLine.y) + "%"),
            width: ((_vm.tempLine.length) + "px"),
            transform: ("rotate(" + (_vm.tempLine.angle) + "rad)"),
            '-webkit-transform': ("rotate(" + (_vm.tempLine.angle) + "rad)") /* WebKit */,
            '-moz-transform': ("rotate(" + (_vm.tempLine.angle) + "rad)") /* Mozilla */,
            '-o-transform': ("rotate(" + (_vm.tempLine.angle) + "rad)") /* Opera */,
            '-ms-transform': ("rotate(" + (_vm.tempLine.angle) + "rad)") /* IE */,
          })},[_c('div',{style:(("border-top: 3px " + (_vm.tempLine.dashed ? 'dashed' : 'solid') + " " + (_vm.tempLine.color || 'black')))})]):_vm._e(),_vm._l((_vm.taskField.squares),function(square,index){return _c('div',{key:("Square " + index),staticClass:"square pa-2",class:_vm.squaresClass[index],style:(_vm.squaresStyle[index]),on:{"click":function($event){$event.stopPropagation();return _vm.onSelect(square, 'squares', index)},"mousemove":_vm.mouseMove}},[_c('div',{staticStyle:{"width":"100%","height":"100%","opacity":"0.9"},style:({
              'background-color':
                !square.color || square.color === 'black'
                  ? 'white'
                  : square.color,
              border: ("2px solid " + (square.borderColor || 'black')),
            })})])}),(_vm.tempSquare)?_c('div',{staticClass:"square pa-2",style:(Object.assign({}, _vm.tempSquare,
            {transform: 'translate(-8px, -8px)'}))},[_c('div',{staticStyle:{"width":"100%","height":"100%","opacity":"0.9"},style:({
              'background-color': _vm.iconColor === 'black' ? 'white' : _vm.iconColor,
              border: ("2px solid " + _vm.borderColor),
            })})]):_vm._e(),(_vm.showOverlay())?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.taskField.field !== 'white')?_c('v-img',{attrs:{"src":_vm.taskFieldBackground,"width":"840","height":"600","contain":""}}):_c('div',{staticStyle:{"width":"840px","height":"600px"}})],2),_c('div',{staticClass:"tool-box d-flex flex-column align-center justify-center pa-1"},[_c('ColorSelector',{attrs:{"selectedColor":_vm.iconColor},on:{"update":_vm.onUpdateColor}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected && _vm.selected.type === 'squares'),expression:"selected && selected.type === 'squares'"}]},[_c('ColorSelector',{attrs:{"selectedColor":_vm.borderColor,"icon":"mdi-border-color"},on:{"update":_vm.onUpdateBorderColor}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected && _vm.selected.type !== 'squares'),expression:"selected && selected.type !== 'squares'"}],staticClass:"mb-8",attrs:{"fab":"","color":"green"},on:{"click":_vm.prepareMove}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-cursor-move ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.selected &&
            _vm.selected.type !== 'lines' &&
            _vm.selected.type !== 'squares'
          ),expression:"\n            selected &&\n            selected.type !== 'lines' &&\n            selected.type !== 'squares'\n          "}],staticClass:"mb-8",attrs:{"fab":"","color":"yellow"},on:{"click":_vm.prepareRotate}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-rotate-left ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.selected &&
            _vm.selected.type !== 'lines' &&
            _vm.selected.type !== 'squares'
          ),expression:"\n            selected &&\n            selected.type !== 'lines' &&\n            selected.type !== 'squares'\n          "}],staticClass:"mb-8",attrs:{"fab":"","color":"blue"},on:{"click":function($event){return _vm.updateSize(3)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-plus ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.selected &&
            _vm.selected.type !== 'lines' &&
            _vm.selected.type !== 'squares'
          ),expression:"\n            selected &&\n            selected.type !== 'lines' &&\n            selected.type !== 'squares'\n          "}],staticClass:"mb-8",attrs:{"fab":"","color":"blue"},on:{"click":function($event){return _vm.updateSize(-3)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-minus ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected && _vm.selected.type === 'texts'),expression:"selected && selected.type === 'texts'"}],staticClass:"mb-8",attrs:{"fab":"","color":"purple"},on:{"click":_vm.updateText}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-format-title ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected),expression:"selected"}],staticClass:"mb-8",attrs:{"fab":"","color":"error"},on:{"click":_vm.onDeleteSelected}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-delete ")])],1)],1)])]),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.textDialog),callback:function ($$v) {_vm.textDialog=$$v},expression:"textDialog"}},[(_vm.text)?_c('v-card',{staticClass:"pa-4 d-flex flex-column"},[_c('v-text-field',{attrs:{"id":"field.text","autofocus":""},model:{value:(_vm.text.text),callback:function ($$v) {_vm.$set(_vm.text, "text", $$v)},expression:"text.text"}}),_c('v-btn',{attrs:{"dark":""},on:{"click":_vm.onWrite}},[_vm._v("Write")])],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.resetDialog),callback:function ($$v) {_vm.resetDialog=$$v},expression:"resetDialog"}},[_c('DeleteConfirmation',{on:{"no":function($event){_vm.resetDialog = false},"yes":_vm.resetData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }