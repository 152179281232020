<template>
  <v-card>
    <v-container v-if="savedCoach">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <Avatar
              :src="fileUrl || (coach.avatar && coach.avatar.route)"
              class="mb-4"
            />
            <FileUpload @change="onSelectAvatar" />
            <Title text="defaults.personalData" class="mb-2" />
            <text-field
              id="coach.name"
              label="defaults.name"
              v-model="coach.name"
              :rules="[rules.required]"
            />
            <text-field
              id="coach.surenames"
              label="defaults.surename"
              v-model="coach.surenames"
              :rules="[rules.required]"
            />
            <text-field id="coach.DNI" label="DNI" v-model="coach.DNI" />
            <text-field
              id="coach.CatSalut"
              label="CatSalut"
              v-model="coach.CATsalut"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <Title text="defaults.contactData" />
            <text-field
              id="coach.phone"
              label="defaults.phone"
              v-model="coach.phone"
            />
            <text-field
              id="coach.email"
              label="defaults.email"
              readonly
              :value="coach.email"
            />

            <text-field
              id="coach.address"
              label="defaults.address"
              v-model="coach.address"
            />
            <text-field
              id="coach.population"
              label="defaults.population"
              v-model="coach.population"
            />
            <v-menu
              v-model="birthMenu"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="coach.birth"
                  :value="formatedBirth"
                  append-icon="mdi-calendar"
                  readonly
                  hide-details
                  filled
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  :label="$ml.get('defaults.birth')"
                />
              </template>
              <v-date-picker
                id="coach.birth.picker"
                v-model="formatedBirth"
                @input="birthMenu = false"
              />
            </v-menu>
            <v-autocomplete
              :label="$ml.get('defaults.size')"
              filled
              hide-details
              menu-props="offset-y"
              class="mb-4"
              :items="sizes"
              v-model="coach.size"
            />
            <Title text="Admin" />
            <div class="d-flex justify-center">
              <v-switch
                v-model="coach.isAdmin"
                :label="coach.isAdmin ? 'Is Admin' : 'Isn\'t Admin'"
                :disabled="!this.$auth.user.isAdmin"
              ></v-switch>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <Title text="defaults.teams" />
            <v-autocomplete
              :label="$ml.get('defaults.teams')"
              v-model="coach.teams"
              :items="teams"
              multiple
              hide-details
              filled
              chips
              deletable-chips
              menu-props="offset-y"
              item-text="name"
              item-value="_id"
              class="mb-2"
            />

            <v-autocomplete
              label="Equip principal"
              v-model="coach.mainTeam"
              :items="coachTeams"
              hide-details
              filled
              clearable
              menu-props="offset-y"
              item-text="name"
              item-value="_id"
              class="mb-2"
            />
            <Title text="players.evaluation" />
            <div class="d-flex justify-center flex-wrap">
              <v-menu
                offset-y
                v-for="(semaphore, index) in coachSemaphores"
                :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="semaphores[coach[`semaphore${semaphore}`]]"
                    v-on="on"
                    v-bind="attrs"
                    min-width="90"
                    class="ma-2"
                  >
                    {{ $ml.get(`coaches.${semaphore.toLowerCase()}`) }}
                  </v-btn>
                </template>

                <v-list color="amber lighten-1">
                  <v-list-item class="d-flex justify-center">
                    <v-btn
                      color="white"
                      fab
                      small
                      @click="$set(coach, `semaphore${semaphore}`, null)"
                    />
                  </v-list-item>
                  <v-list-item
                    v-for="(color, index) in semaphores"
                    :key="color"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      :color="color"
                      fab
                      small
                      @click="$set(coach, `semaphore${semaphore}`, index)"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="coachId">
          <v-col>
            <Documents
              type="coaches"
              :id="coachId"
              :files="coach.files || []"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="saveCoach"
        class="mr-4"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        color="red"
        @click="deleteDialog = true"
        v-if="$auth.user.isAdmin && coachId"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-dialog v-model="deleteDialog" max-width="500" persistent>
        <DeleteConfirmation @no="deleteDialog = false" @yes="remove" />
      </v-dialog>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import Title from "../../components/shared/Title.vue";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";
import TextField from "../../components/shared/text-field.vue";
import FileUpload from "../../components/Files/FileUpload.vue";
import Avatar from "../../components/Files/Avatar.vue";
import Documents from "../../components/Files/Documents.vue";

import {
  sizes,
  semaphores,
  coachSemaphores,
} from "../../constants/selectorsList";
import alerts from "../../constants/alerts";
import rules from "../../constants/rules";
import objDiff from "../../utils/objDiff";

const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

export default {
  name: "CoachDetail",
  components: {
    Title,
    DeleteConfirmation,
    CloseConfirmation,
    TextField,
    FileUpload,
    Avatar,
    Documents,
  },
  data: () => ({
    coach: {},
    file: null,
    fileUrl: "",

    formatedBirth: "",
    savedCoach: null,

    birthMenu: false,
    deleteDialog: false,
    closeDialog: false,

    rules,
    sizes,
    semaphores,
    coachSemaphores,
  }),
  methods: {
    ...mapActions([
      "getById",
      "update",
      "delete",
      "sendFile",
      "getList",
      "create",
    ]),
    ...mapMutations(["setAlert", "addFile", "openDetails"]),
    async getSavedCoach() {
      this.savedCoach = await this.getById({
        type: "coaches",
        id: this.coachId,
      });
    },
    defineCoach() {
      this.coach = { ...this.savedCoach };
      this.formatedBirth = this.savedCoach?.birth?.slice(0, 10);
    },
    onSelectAvatar(file) {
      const isValidType = allowedTypes.some((type) => type === file.type);
      if (isValidType) {
        this.fileUrl = URL.createObjectURL(file);
        this.file = file;
      } else {
        this.setAlert(alerts.invalidExtension);
      }
    },
    async saveCoach() {
      if (this.$refs.form.validate()) {
        const coach = { ...this.coachToUpdate };
        let file;
        if (this.file) {
          const fileExtension = this.file.name.split(".").pop();
          file = await this.sendFile({
            file: this.file,
            fileName: `avatar.${fileExtension}`,
            type: "coaches",
            id: this.coachId,
          });
          coach.avatar = file._id;
        }

        let coachId = this.coachId;
        if (!coachId) {
          const coachToCreate = {
            name: this.coach.name,
            surenames: this.coach.surenames,
          };

          const coach = await this.create({
            type: "coaches",
            createData: coachToCreate,
          });

          coachId = coach._id;
        }

        this.update({
          type: "coaches",
          id: coachId,
          updateData: coach,
          filter: { teams: this.$auth.user.teams },
        });
      } else this.setAlert(alerts.emptyError);
    },
    remove() {
      this.delete({
        type: "coaches",
        id: this.coachId,
      });
    },
    closeDetails() {
      if (Object.keys(this.coachToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["teams", "details"]),
    coachId() {
      return this.details.id;
    },
    coachToUpdate() {
      const coachDiff = objDiff(this.coach, this.savedCoach);

      if (this.savedCoach?.birth) {
        const savedBirth = this.savedCoach.birth.slice(0, 10);
        if (savedBirth !== this.formatedBirth)
          coachDiff.birth = new Date(this.formatedBirth);
      } else if (this.formatedBirth) {
        coachDiff.birth = new Date(this.formatedBirth);
      }

      if (coachDiff.teams) {
        coachDiff.teamsToAdd = coachDiff.teams.filter(
          (teamId) =>
            !this.savedCoach.teams.find((savedId) => teamId === savedId)
        );

        coachDiff.teamsToRemove = this.savedCoach.teams.filter(
          (savedId) => !coachDiff.teams.find((teamId) => teamId === savedId)
        );

        if (!(coachDiff.teamsToAdd.length || coachDiff.teamsToRemove.length)) {
          delete coachDiff.teams;
          delete coachDiff.teamsToAdd;
          delete coachDiff.teamsToRemove;
        }
      }

      return coachDiff;
    },
    coachTeams() {
      const coachTeams = this.coach.teams;
      if (!coachTeams?.length) return [];
      return this.teams.filter((team) =>
        coachTeams.some((coachTeam) => coachTeam === team._id)
      );
    },
  },
  created() {
    if (!this.teams.length) {
      const filter = { teams: this.$auth.user.teams };
      this.getList({ type: "teams", filter });
    }

    if (this.coachId) this.getSavedCoach().then(this.defineCoach);
    else this.savedCoach = { teams: [] };
  },
};
</script>
