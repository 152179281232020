export default {
  filter: {
    filters: "Filtrar",
    sorters: "Ordenar",
    alphabetic: "Alfabético",
    creation: "Creación",
    year: "Año",
    noBirth: "Sin fecha de nacimiento definida",
  },
  header: {
    theme: "Cambiar el tema",
  },
  user: {
    title: "Usuario",
    add: "Añadir un usuario",
    delete: "Eliminar usuario",
    type: "Tipo de usuario",
    updatePassword: "Cambiar contraseña",
    newPassword: "Nueva contraseña",
    repeatPassword: "Repetir contraseña",
    oldPassword: "Contraseña actual",
    logout: "Cerrar sesion",
  },
  players: {
    title: "Deportista",
    noPlayers: "No hay deportistas",
    noFiltered: "Con los filtros actuales no hay deportistas",
    modal: "Añadir deportista",

    age: "Edad",
    parentalData: "Datos familiares",
    evaluation: "Evaluación",
    mental: "Mental",
    conditional: "Condición",
    game: "Juego",
  },
  coaches: {
    title: "Personal técnico",
    noCoaches: "No hay entrenadores",
    noFiltered: "Con los filtros actuales no hay entrenadores",
    modal: "Añadir personal técnico",

    order: "Orden",
    passon: "Transmitir",
    sessiondesign: "Diseño sesión",
    sessiondirection: "Dirección sesión",
    matchmanagement: "Dirección partidos",
    groupmanagement: "Gestión grupo",
  },
  teams: {
    title: "Equipo",
    noTeams: "No hay equipos",
    noFiltered: "Con los filtros actuales no hay equipos",

    teamData: "Información del equipo",
    workLine: "Línea de trabajo",
    matchTime: "Minutos por partido",

    modal: "Añadir equipo",

    category: "Categoría",
    season: "Temporada",

    trainings: {
      title: "Días de entrenamiento",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      start: "Inicio",
      end: "Final",
    },
  },
  tasks: {
    title: "Tarea",
    noTasks: "No hay tareas",
    noFiltered: "Con los filtros actuales no hay tareas",
    modal: "Crear una tarea",
    field: "Gráfico",
    selectField: "Selecciona el campo",

    intention: "Intención",
    pass: "Superar",
    finish: "Finalizar",
    recover: "Recuperar",

    specificity: "Especificidad",
    general: "General",
    directed: "Dirigida",
    special: "Especial",
    competitive: "Competitiva",

    taskType: "Tipo de tarea",
    game: "Juego",
    RT1: "RT1",
    RT2: "RT2",
    RT3: "RT3",
    round: "Rondo",
    conservation: "Conservación",
    position: "Juego de posición",
    situation: "Juego de situación",
    atacDefense: "Ataque-defensa",
    match: "Partido condicionado",

    teaching: "Enseñanza",
    introduction: "Introducción",
    learning: "Aprendizaje",
    control: "Dominio",

    content: "Contenido",
    aproximation: "Aproximación",
    creator: "Creador",
    sessionDate: "Fecha de la sesión",
    sessionNumber: "Número de la sesión",

    explanation: "Explicación",
    regulations: "Normativa",
    punctuation: "Puntuación",
    slogan: "Consignas",

    connectToCommunicate: "Conectar para comunicar",
    moveToFind: "Mover para encontrar",
    chopToFinish: "Picar para finalizar",
    putToFinish: "Poner (jugar) para finalizar",
    occupyNotToMakePossible: "Ocupar para no posibilitar",
    pressToForce: "Presionar para forzar",
    occupyToCover: "Ocupar para tapar",
    identifyToPrevent: "Identificar para impedir",
    beInLinePassing: "Estar en línea de pase",
    adjustTheAmplitude: "Ajustar la amplitud",
    adjustTheDepth: "Ajustar la profundidad",
    passToKeep: "Pase para mantener",
    passToOvercome: "Pase para superar",
    coverage: "Cobertura",
    swap: "Permuta",
    deletePassLine: "Eliminar línia de pase",
    controlToGive: "Control para dar",
    controlToOvercome: "Control para superar",
    controlToProtect: "Control para proteger",
    drivingToOvercome: "Conducción para superar",
    drivingToFix: "Conducción para fijar",
    wateredToOvercome: "Regate para superar",
    unmarkedToReceive: "Desmarque para recibir",
    shotToPlace: "Chut para colocar",
    powerfulShot: "Chut con potencia",
    finishedToScore: "Remate para marcar",
    dialingToWatch: "Marca para vigilar",
    anticipateToAttack: "Anticipar para atacar",
    interceptToRefuse: "Interceptar para rechazar",
    brakeInput: "Entrada para frenar",
    entryToRecover: "Entrada para recuperar",
    entryToRefuse: "Entrada para rechazar",
    individualMarkingWithinTheArea: "Marca individual dentro del área",
    forceDisplacementChangesOfDirection:
      "Fuerza desplazamiento_Cambios de dirección",
    forceDisplacementStartsAndBrakes:
      "Fuerza desplazamiento_Arranques y frenadas",
    forceShiftRhythmChanges: "Fuerza desplazamiento_Cambios de ritmo",
    strongStrugglePush: "Fuerza lucha_Empujar",
    strongStruggleUnbalance: "Fuerza lucha_Desequilibrar",
    forceJumpImpulse: "Fuerza salto_Impulso",
    forceJumpActionsInSuspension: "Fuerza salto_Acciones en suspensión",
    strongPerformanceOnBallPassada: "Fuerza actuación sobre balón_Pase",
    strongPerformanceOnBallHitting: "Fuerza actuación sobre balón_Golpe",
    strongPerformanceOnBallReceptions:
      "Fuerza actuación sobre balón_Recepciones",
    strongActionOnBallRefusals: "Fuerza actuación sobre balón_Rechaces",
    eoEoISeeYou: "Eo eo te veo",
    iDecide: "Yo decido",
    nowWeWillMark: "Ahora marcaremos",
    yourMark: "Toma, marca tú",
    readToRecover: "Leer para recuperar",
    IForceYouTo: "Yo te fuerzo a…",
    youWontEvenTouchIt: "Ni la tocarás",
    youWillNotScore: "No marcarás",

    connectToCommunicate_slogan: [
      "Tomar para generar, dejar para ocupar",
      "Misma altura amplitud, altura diferente profundidad",
    ],
    moveToFind_slogan: [
      "Espacio voy, oposición doy",
      "Oposición lejos, pase cerca",
      "Oposición cerca, pase lejos",
      "Estoy orientada, estoy activada",
      "No orientada, toco de cara",
    ],
    chopToFinish_slogan: [
      "Intervalo",
      "Atacar, marca",
      "arrastrar",
      "Entrar para rematar",
      "Quedarse para proteger",
    ],
    putToFinish_slogan: ["Chutar para marcar; poner para finalizar"],
    occupyNotToMakePossible_slogan: [
      "POP: Balón, oponente, portería",
      "Dentro-Dentro",
      "Fuera-Fuera",
      "Jugadora en acción provoca recuperación",
    ],
    pressToForce_slogan: [
      "En curva para forzar, recto para apretar",
      "Saltar y condicionar",
    ],
    occupyToCover_slogan: ["Identificar, tocar, rechazar", "Estar para lanzar"],
    identifyToPrevent_slogan: ["Tocar para obstaculizar"],
    beInLinePassing_slogan: [
      "Ninguna oponente entre el balón y yo",
      "Tener los pies activos",
      "Gesto para indicar dónde quiero recibir el balón",
    ],
    adjustTheAmplitude_slogan: [
      "Evitar que una defensa pueda marcar a dos atacantes a la vez",
      "Dar línea de pase",
    ],
    adjustTheDepth_slogan: [
      "Separarse hacia la portería más cercana",
      "Dar línea de pase",
    ],
    passToKeep_slogan: [
      "Con ventaja para la compañera",
      "Al pie de la compañera",
      "Contacto visual con la receptora",
      "En diagonal",
      "Tenso y raso",
    ],
    passToOvercome_slogan: [
      "Con ventaja para la compañera",
      "Contacto visual con la receptora",
      "Tenso",
      "En diagonal o en vertical",
      "Raso o aéreo",
      "Gesto para ayudar a la receptora",
    ],
    coverage_slogan: [
      "Estar por detrás de la compañera que defiende a la oponente que tiene el balón",
      "Ubicarse en el carril central",
      "Saltar a reducir si la compañera es superada con el objetivo de frenar a la oponente",
    ],
    swap_slogan: [
      "Ocupar el lugar que ha abandonado la compañera",
      "Desplazamiento rápido",
      "Volver por el carril central",
    ],
    deletePassLine_slogan: [
      "Colocarse entre la oponente que tiene el balón y una oponente que lo pueda recibir",
      "Ser consciente del espacio que tengo que proteger",
      "Trayectorias: recta o en curva",
    ],
    controlToGive_slogan: [
      "No parar el balón",
      "Un pie mira al balón y el otro mira hacia donde quiero ir",
      "Utilizar la pierna alejada",
      "Relajar el pie para quedarse el balón",
      "Levantar la cabeza antes de recibir el balón",
      "Fintar para crearse más espacio",
      "Controlar con la pierna más alejada de la oponente",
      "Protegerse con los brazos de la oponente",
    ],
    controlToOvercome_slogan: [
      "Arrancar con el control",
      "Un pie mira el balón y el otro mira hacia donde quiero ir",
      "Atacar el balón",
      "Levantar la cabeza antes de recibir el balón",
      "Fintar para crearse más espacio",
      "Controlar con la pierna más alejada de la oponente",
      "Protegerse con los brazos de la oponente",
    ],
    controlToProtect_slogan: [
      "De cara a mi portería",
      "Sacar el culo",
      "Usar la planta del pie",
      "Protegerse con los brazos de la oponente",
    ],
    drivingToOvercome_slogan: [
      "Hacia un espacio libre y en dirección a portería",
      "Dominar el balón",
      "Rápida",
      "Utilizar el exterior",
      "Utilizar la pierna más alejada de la oponente",
      "Levantar la cabeza",
    ],
    drivingToFix_slogan: [
      "Hacia una oponente",
      "Dominar el balón",
      "Utilizar el exterior",
      "Levantar la cabeza durante la conducción",
      "Identificar cuándo la oponente está pendiente de mí",
    ],
    wateredToOvercome_slogan: [
      "Fijar + Engañar (trabajar diferentes tipos de regate) + Cambiar la dirección y el ritmo",
      "Cruzarse por delante de la oponente una vez superada",
      "En dirección a portería",
    ],
    unmarkedToReceive_slogan: [
      "Identificar el espacio que quiero ocupar",
      "Cambiar el ritmo",
      "Contacto visual con la poseedora",
      "Gesto para pedir el balón",
      "Fintar para crearse más espacio",
      "Ponerse por delante de la oponente",
    ],
    shotToPlace_slogan: [
      "Utilizar el interior",
      "Impactar el balón, no acompañarlo",
      "El pie de apoyo guía la dirección y la altura del chut",
      "Chut cruzado",
      "Mirar a la portera",
      "Chutar y seguir la acción",
      "Utilizar el exterior",
    ],
    powerfulShot_slogan: [
      "Utilizar el empeine",
      "Impactar el balón, no acompañarlo",
      "El pie de apoyo guía la dirección y la altura del chut",
      "Inclinar el cuerpo hacia adelante",
      "Mirar a la portera",
      "Chutar y seguir la acción",
    ],
    finishedToScore_slogan: [
      "Atacar el balón",
      "Utilizar solo 1 contacto",
      "Impactar el balón, no acompañarlo",
      "Impactar el balón cuando cae, y no cuando sube",
      "Mirar a la portera",
    ],
    dialingToWatch_slogan: ["POP: Pelota, oponente, porteria"],
    anticipateToAttack_slogan: [
      "Pies activos",
      "Cambio de ritmo para ponerse por delante de la oponente",
      "Utilizar partes grandes del cuerpo para quedarse el balón",
      "Arrancar con el control",
    ],
    interceptToRefuse_slogan: [
      "Aéreo o raso",
      "Fuerte",
      "Lo más lejos posible o a la línea de banda más cercana",
      "En la zona donde hay una compañera para el contraataque",
    ],
    brakeInput_slogan: [
      "Reducir la distancia con la oponente",
      "Colocar el cuerpo lateralmente",
      "Llevar a la oponente hacia la banda más cercana",
      "Correr hacia atrás con la oponente",
    ],
    entryToRecover_slogan: [
      "Reducir la distancia con la oponente",
      "Colocar el cuerpo lateralmente",
      "Llevar a la oponente hacia la banda más cercana",
      "Correr hacia atrás con la oponente",
      "Interponer el cuerpo entre la oponente y el balón",
      "Entrada tirándose al suelo",
    ],
    entryToRefuse_slogan: [
      "Reducir la distancia con la oponente",
      "Colocar el cuerpo lateralmente",
      "Correr hacia atrás con la atacante (si es necesario)",
      "Entrar con cualquiera de los dos pies",
      "Entrada tirándose al suelo",
    ],
    individualMarkingWithinTheArea_slogan: [
      "Ubicarse entre la oponente y la portería",
      "Seguir a la oponente",
      "Tocar a la oponente",
    ],
    forceDisplacementChangesOfDirection_slogan: [
      "Cambios de dirección",
      "Arranques y frenadas",
      "Cambio de ritmo y frecuencia de apoyos",
    ],
  },
  sessions: {
    title: "Sesión",
    noSessions: "No hay sesiones",
    noFiltered: "Con los filtros actuales no hay sesiones",
    modal: "Crea una sesión",
    microcycle: "Microciclo",
    session: "Nº de la sesión",
    material: "Material",
    asistence: "Asistencia",
    date: "Fecha de la sesión",
    time: "Hora",
    addTask: "Añadir tarea",
    instrucions: "Instrucciones",

    serie: "Series",
    serieTime: "Duracion (min)",
    serieRecuperation: "Descanso (min)",
    totalTime: "Total (min)",
    selectTask: "Selecciona la tarea",
    pdf: "Generar PDF",
  },
  matches: {
    title: "Partidos",
    noMatches: "No hay partidos",
    noFiltered: "Con los filtros actuales no hay partidos",
    modal: "Crear un partido",

    rival: "Rival",
    competition: "Competición",
    turn: "Jornada",
    playingHome: "Jugando en casa",
    playingOut: "Jugando fuera",
    hour: "Hora",
    result: "Resultado",

    goal: "Gol",
    change: "Cambio",
    yellowCard: "Tarjeta amarilla",
    redCard: "Tarjeta roja",

    summoned: "Convocatoria",
    lineUp: "Alineación",
    minute: "Minuto",
    events: "Eventos",

    entring: "Entra",
    outgoing: "Sale",
    date: "Fecha de partido",

    league: "Liga",
    friendly: "Amistoso",
  },
  statistics: {
    goals: "Goles",
    matchMinutes: "Minutos jugados",
    attendance: "Asistencia a los entrenamientos",
    noTeam: "Selecciona un equipo para ver las estadisticas",
  },
  trackings: {
    watch: "Observar",
    discard: "Descartar",
    follow: "Seguir",
    sign: "Fichar",

    leg: "Pierna dominante",
    position: "Posición",
    right: "Derecha",
    left: "Izquierda",
    comments: "Comentarios",
  },
  accounting: {
    scholarships: "Becas",
    quota: "Cuota",
    enrollment: "Matrícula",

    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
  },
};
