<template>
  <section>
    <FiltersAndSorters
      icon="mdi-eye"
      initial="creation"
      :title="$ml.get('defaults.tracking')"
      :sorters="sortersConfig"
      @search="(sort) => search(sort)"
      @filters-reset="reset"
    >
      <template slot="filters">
        <v-text-field
          :label="$ml.get('defaults.name')"
          id="filter-players-name"
          v-model="filters.name"
          hide-details
          clearable
        />
        <div class="ml-4">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateText"
                :label="$ml.get('defaults.birth')"
                prepend-inner-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="filters.birth" range></v-date-picker>
          </v-menu>
        </div>
      </template>
    </FiltersAndSorters>
    <v-container>
      <div v-if="trackingsSearch.length">
        <v-row
          class="d-flex justify-space-between text-h6 mb-2"
          :class="{
            'separator-white': $vuetify.theme.dark,
            'separator-black': !$vuetify.theme.dark,
          }"
        >
          <v-col>
            <span
              class="py-2 px-6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("defaults.name") }}
            </span>
          </v-col>
          <v-col class="text-center">
            <span
              class="py-2 px-6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("filter.year") }}
            </span>
          </v-col>
          <v-col class="text-center">
            <span
              class="py-2 px-6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("teams.title") }}
            </span>
          </v-col>
          <v-col class="text-right">
            <span
              class="py-2 px-6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("tasks.intention") }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-for="tracking in trackingsSearch"
          :key="tracking._id"
          style="cursor: pointer"
          class="my-1 text-h6 d-flex justify-space-between"
          :class="{
            'tracking-white': $vuetify.theme.dark,
            'tracking-black': !$vuetify.theme.dark,
          }"
          @click="callOpenDetails(tracking._id)"
        >
          <v-col>{{ tracking.name }}</v-col>
          <v-col class="text-center">{{ tracking.birth }}</v-col>
          <v-col class="text-center">{{ tracking.actualTeam }}</v-col>
          <v-col class="text-right">
            {{ $ml.get(`trackings.${tracking.intention}`) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-pagination v-model="page" :length="pageLength"></v-pagination>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <p class="text-h4 text-center mt-4">
          {{ $ml.get("players.noPlayers") }}
        </p>
      </div>
    </v-container>

    <v-btn
      fab
      large
      fixed
      bottom
      right
      dark
      :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
      @click="openNewDetails()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import FiltersAndSorters from "../../components/FiltersAndSorters/FiltersAndSorters.vue";

const sortersConfig = [
  {
    text: "alphabetic",
    icon: "mdi-sort-alphabetical-ascending",
    invertedIcon: "mdi-sort-alphabetical-descending",
    translation: "filter.alphabetic",
  },
  {
    text: "birth",
    icon: "mdi-cake-variant",
    translation: "defaults.birth",
  },
  {
    text: "creation",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "filter.creation",
  },
];

const defaultFilter = () => ({
  name: "",
  birth: [],
  sorter: { filter: "creation", state: true },
});

export default {
  name: "Tracking",
  components: { FiltersAndSorters },
  data: () => ({
    trackingsSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    dialog: false,
  }),
  methods: {
    ...mapActions(["getSearch"]),
    ...mapMutations(["openDetails"]),
    callOpenDetails(id) {
      this.openDetails({ type: "trackings", id });
    },
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (sort) filter.sorter = sort;
      this.getSearch({ type: "trackings", filter }).then(({ page, total }) => {
        this.trackingsSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter();
      this.search();
    },
    openNewDetails() {
      this.openDetails({ type: "trackings", id: null });
    },
  },
  computed: {
    ...mapGetters(["details"]),
    pageLength() {
      return Math.ceil(this.total / 10);
    },
    dateText() {
      if (this.filters.birth.length !== 2) return "";
      else return `${this.filters.birth[0]} | ${this.filters.birth[1]}`;
    },
  },
  watch: {
    page() {
      this.search();
    },
    details() {
      if (!this.details) this.search();
    },
  },
  created() {
    this.search();
  },
};
</script>

<style lang="sass" scoped>
.separator-black
  border-bottom: solid black 2px

.separator-white
  border-bottom: solid white 2px

.tracking-black
  border-bottom: solid black 1px

.tracking-white
  border-bottom: solid white 1px

.background-black
  background-color: black

.background-white
  background-color: white
</style>
