<template>
  <section class="d-flex mx-2 my-4">
    <div
      class="mr-2"
      @click="callOpenDetails(route, player._id)"
      style="cursor: pointer"
    >
      <Avatar :src="player.avatar" :size="140" :alt="player.name" />
    </div>
    <div class="d-flex flex-column justify-space-between text-h6">
      <div class="text-h6 text-uppercase text-truncate">
        <p
          class="player__name ma-0"
          style="cursor: pointer"
          @click="callOpenDetails(route, player._id)"
        >
          {{ player.name }}
        </p>
        <v-divider color="black" />
        <div>
          <span
            v-if="player.mainTeam"
            style="cursor: pointer"
            @click="callOpenDetails('teams', player.mainTeam._id)"
          >
            {{ player.mainTeam.name }}
          </span>

          <span v-else>-</span>
          | {{ player.birth || "-" }}
        </div>
        <br />
      </div>

      <div class="d-flex">
        <v-tooltip
          bottom
          v-for="semaphore in playerSemaphores"
          :key="semaphore"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              :color="semaphores[player[`semaphore${semaphore}`]]"
              height="30"
              width="60"
              class="ma-1"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ $ml.get(`players.${semaphore.toLowerCase()}`) }}</span>
        </v-tooltip>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import { playerSemaphores, semaphores } from "../constants/selectorsList";

import Avatar from "./Files/Avatar.vue";

export default {
  name: "PlayerListItem",
  props: {
    player: Object,
  },
  components: { Avatar },
  data: () => ({
    playerSemaphores,
    semaphores,
  }),
  computed: {
    playerYear() {
      if (!this.player.birth) return null;
      return new Date(this.player.birth).getFullYear();
    },
    route() {
      return this.$route.name.toLowerCase();
    },
  },
  methods: {
    ...mapMutations(["openDetails"]),
    callOpenDetails(type, id) {
      this.openDetails({ type, id });
    },
  },
};
</script>

<style lang="sass" scoped>
.player__name
  width: 200px
</style>
