<template>
  <div class="d-flex justify-center">
    <v-avatar rounded :size="size">
      <v-img :src="url" :alt="alt" />
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    src: String,
    size: {
      type: Number,
      default: 200,
    },
    alt: {
      type: String,
      default: "Avatar",
    },
  },
  computed: {
    url() {
      if (this.src) {
        return this.$props.src.includes("http")
          ? this.$props.src
          : `https://api.barcelonawss.com/${this.$props.src}`;
      } else {
        return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
      }
    },
  },
};
</script>