<template>
  <section>
    <FiltersAndSorters
      icon="mdi-scoreboard-outline"
      initial="date"
      :title="$ml.get('matches.title')"
      :sorters="sortersConfig"
      @search="(sort) => search(sort)"
      @filters-reset="reset"
    >
      <template slot="filters">
        <v-autocomplete
          :label="$ml.get('defaults.teams')"
          :items="teams"
          v-model="filters.team"
          item-text="name"
          item-value="_id"
          hide-details
          multiple
          chips
          small-chips
          deletable-chips
          :disabled="!$auth.user.isAdmin"
        />
        <div class="ml-4">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateText"
                :label="$ml.get('defaults.date')"
                prepend-inner-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="filters.date" range></v-date-picker>
          </v-menu>
        </div>
      </template>
    </FiltersAndSorters>

    <v-container style="min-width: 500px">
      <div v-if="matchesSearch.length">
        <v-row
          class="d-flex justify-space-between text-h5 mb-2"
          :class="{
            'separator-white': $vuetify.theme.dark,
            'separator-black': !$vuetify.theme.dark,
          }"
        >
          <v-col>
            <span
              class="py-2 px-6 text-h6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("teams.title") }}
            </span>
          </v-col>
          <v-col class="text-center">
            <span
              class="py-2 px-6 text-h6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("matches.rival") }}
            </span>
          </v-col>
          <v-col class="text-center">
            <span
              class="py-2 px-6 text-h6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("matches.result") }}
            </span>
          </v-col>
          <v-col class="d-none d-md-block text-right">
            <span
              class="py-2 px-6 text-h6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("defaults.date") }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-for="match in matchesSearch"
          :key="match._id"
          style="cursor: pointer"
          class="my-1 text-h6 d-flex justify-space-between"
          :class="{
            'match-white': $vuetify.theme.dark,
            'match-black': !$vuetify.theme.dark,
          }"
          @click="callOpenDetails(match._id)"
        >
          <v-col class="">{{ match.team.name }}</v-col>
          <v-col class="text-center">{{ match.rival }}</v-col>
          <v-col class="text-center">
            {{ match.result[0] }} - {{ match.result[1] }}
          </v-col>
          <v-col class="d-none d-md-block text-end">
            {{ match.date[0] }} | {{ match.date[1] }}h
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-pagination v-model="page" :length="pageLength"></v-pagination>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <p class="text-h4 text-center mt-4">
          {{ $ml.get("matches.noMatches") }}
        </p>
      </div>
    </v-container>

    <v-btn
      fab
      large
      fixed
      bottom
      right
      dark
      :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
      @click="openNewDetails()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import FiltersAndSorters from "../../components/FiltersAndSorters/FiltersAndSorters.vue";

const sortersConfig = [
  {
    text: "team",
    icon: "mdi-account-group",
    translation: "teams.title",
  },
  {
    text: "date",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "defaults.date",
  },
];

const defaultFilter = (teams) => ({
  date: [],
  team: teams || [],
  sorter: { filter: "date", state: true },
});

export default {
  name: "Matches",
  components: { FiltersAndSorters },
  data: () => ({
    matchesSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    dialog: false,
  }),
  methods: {
    ...mapMutations(["openDetails"]),
    ...mapActions(["getSearch", "getList"]),
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (sort) filter.sorter = sort;
      this.getSearch({ type: "matches", filter }).then(({ page, total }) => {
        this.matchesSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter(this.$auth.user.teams);
      this.search();
    },
    callOpenDetails(id) {
      this.openDetails({ type: "matches", id });
    },
    openNewDetails() {
      this.openDetails({ type: "matches", id: null });
    },
  },
  computed: {
    ...mapGetters(["teams", "details"]),
    pageLength() {
      return Math.ceil(this.total / 24);
    },
    dateText() {
      if (this.filters.date.length !== 2) return "";
      else return `${this.filters.date[0]} | ${this.filters.date[1]}`;
    },
  },
  watch: {
    page() {
      this.search();
    },
    details() {
      if (!this.details) this.search();
    },
  },
  created() {
    if (!this.$auth.user.isAdmin) this.filters.team = this.$auth.user.teams;

    if (!this.teams.length) {
      const filter = { teams: this.$auth.user.teams };
      this.getList({ type: "teams", filter });
    }
    this.search();
  },
};
</script>

<style lang="sass" scoped>
.separator-black
  border-bottom: solid black 2px

.separator-white
  border-bottom: solid white 2px

.match-black
  border-bottom: solid black 1px

.match-white
  border-bottom: solid white 1px

.background-black
  background-color: black

.background-white
  background-color: white
</style>
