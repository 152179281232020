export default {
  filter: {
    filters: "Filter",
    sorters: "Sort",
    alphabetic: "Alphabetic",
    creation: "Creation",
    year: "Year",
    noBirth: "No date of birth defined",
  },
  header: {
    theme: "Change theme",
  },
  user: {
    title: "User",
    add: "Add user",
    delete: "Delete user",
    type: "User type",
    updatePassword: "Change password",
    newPassword: "New password",
    repeatPassword: "Repeat password",
    oldPassword: "Actual password",
    logout: "Logout",
  },
  players: {
    title: "Player",
    noPlayers: "No players",
    noFiltered: "With the current filters there are no players",
    modal: "Add player",

    age: "Age",
    parentalData: "Parental data",
    evaluation: "Evaluation",
    mental: "Mental",
    conditional: "Conditional",
    game: "Game",
  },
  coaches: {
    title: "Coach",
    noCoaches: "No coaches",
    noFiltered: "With current filters there are no coaches",
    modal: "Add coach",

    order: "Order",
    passon: "Pass on",
    sessiondesign: "Session design",
    sessiondirection: "Session direction",
    matchmanagement: "Match management",
    groupmanagement: "Group management",
  },
  teams: {
    title: "Team",
    noTeams: "No teams",
    noFiltered: "With the current filters there are no teams",

    teamData: "Team data",
    workLine: "Work line",
    matchTime: "Minutes per game",

    modal: "Add team",

    season: "Season",
    category: "Category",

    trainings: {
      title: "Trainings days",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      start: "Start",
      end: "End",
    },
  },
  tasks: {
    title: "Task",
    noTasks: "No tasks",
    noFiltered: "With the current filters there are no tasks",
    modal: "Create task",
    field: "Graphic",
    selectField: "Select field",

    content: "Content",
    creator: "Creator",
    aproximation: "Approximation",
    sessionDate: "Session date",
    sessionNumber: "Session number",

    pass: "Pass",
    finish: "Finish",
    recover: "Recover",
    intention: "Intention",

    specificity: "Specificity",
    general: "General",
    directed: "Directed",
    special: "Special",
    competitive: "Competitive",

    taskType: "Task type",
    game: "Game",
    RT1: "RT1",
    RT2: "RT2",
    RT3: "RT3",
    round: "Round",
    conservation: "Conservation",
    position: "Position game",
    situation: "Situation game",
    atacDefense: "Attack-defence ",
    match: "Conditioned match",

    teaching: "Teaching",
    introduction: "Introduction",
    learning: "Learning",
    control: "Controlled",

    explanation: "Explanation",
    regulations: "Regulations",
    punctuation: "Punctuation",
    slogan: "Slogan",

    connectToCommunicate: "Connect to communicate",
    moveToFind: "Move to find",
    chopToFinish: "Hit to finish",
    putToFinish: "Put to finish",
    occupyNotToMakePossible: "Occupy for prevent",
    pressToForce: "Press to force",
    occupyToCover: "Occupy to cover",
    identifyToPrevent: "Identify to prevent",
    beInLinePassing: "Be in line for pass",
    adjustTheAmplitude: "Adjust the extent",
    adjustTheDepth: "Adjust the depth",
    passToKeep: "Pass to keep",
    passToOvercome: "Pass to surpass",
    coverage: "Coverage",
    swap: "Swap",
    deletePassLine: "Cut pass line",
    controlToGive: "Control to give",
    controlToOvercome: "Control to surpass",
    controlToProtect: "Control to protect",
    drivingToOvercome: "Pipe to surpass",
    drivingToFix: "Pipe to fix",
    wateredToOvercome: "Dribbling to surpass",
    unmarkedToReceive: "Get away to receive",
    shotToPlace: "Shot to place",
    powerfulShot: "Powerful shot",
    finishedToScore: "Strike to score",
    dialingToWatch: "Marking to watch",
    anticipateToAttack: "Anticipate to attack",
    interceptToRefuse: "Intercept to refuse",
    brakeInput: "Tackle to hold back",
    entryToRecover: "Tackle to recover",
    entryToRefuse: "Tackle to refuse",
    individualMarkingWithinTheArea: "Individual marking within the area",
    forceDisplacementChangesOfDirection:
      "Force displacement_Changes of direction",
    forceDisplacementStartsAndBrakes: "Force displacement_Starts and brakes",
    forceShiftRhythmChanges: "Force displacement_Rhythm changes",
    strongStrugglePush: "Force struggle_Push",
    strongStruggleUnbalance: "Force struggle_Unbalance",
    forceJumpImpulse: "Force jump_Impulse",
    forceJumpActionsInSuspension: "Force jump_Actions in suspension",
    strongPerformanceOnBallPassada: "Force performance on ball_Pass",
    strongPerformanceOnBallHitting: "Force performance on ball_Hitting",
    strongPerformanceOnBallReceptions: "Force performance on ball_Receptions",
    strongActionOnBallRefusals: "Force action on ball_Refusals",
    eoEoISeeYou: "Eo eo I see you",
    iDecide: "I decide",
    nowWeWillMark: "Now we will score",
    yourMark: "Take it, you score",
    readToRecover: "Read to recover",
    IForceYouTo: "I force you to…",
    youWontEvenTouchIt: "You won't even touch it",
    youWillNotScore: "You will not score",

    connectToCommunicate_slogan: [
      "Take to generate, leave to occupy",
      "Same height wideness, height another depth",
    ],
    moveToFind_slogan: [
      "Go to the clearance making opposition",
      "Far opposition, near pass",
      "Near opposition, far pass",
      "I'm focused, I'm active",
      "I'm not focused, play to the front",
    ],
    chopToFinish_slogan: [
      "Lapse",
      "Mark, attack",
      "Sweep",
      "Tackle to kick at goal, stay to defend",
    ],
    putToFinish_slogan: ["Kick at goal", "Put to finish"],
    occupyNotToMakePossible_slogan: [
      "POP: Ball, opponent, goal",
      "In-In",
      "Out-Out",
      "Active player makes recovery",
    ],
    pressToForce_slogan: [
      "Curved to force, straight to press",
      "Jump and condition",
    ],
    occupyToCover_slogan: ["Identify, touch, refuse", "Stay to throw"],
    identifyToPrevent_slogan: ["Touch to prevent"],
    beInLinePassing_slogan: [
      "No opponent between ball and me",
      "Active feet",
      "Signal to indicate where I want to receive the ball",
    ],
    adjustTheAmplitude_slogan: [
      "Prevent a defender from marking two attackers at once",
      "Give pass line",
    ],
    adjustTheDepth_slogan: ["Move away to the nearest goal", "Give pass line"],
    passToKeep_slogan: [
      "With advantage to the team-mate",
      "At the foot of the team-mate",
      "Visual contact with receiver",
      "Diagonally",
      "Tense and low",
    ],
    passToOvercome_slogan: [
      "With advantage to the team-mate",
      "Eye contact with receiver",
      "Tense",
      "Diagonally or vertically",
      "Low or high",
      "Signal to help the receiver",
    ],
    coverage_slogan: [
      "Remain behind the team-mate defending the opponent who has the ball",
      "Position in the middle lane",
      "Jump to reduce if the team-mate is surpassed for stopping the opponent",
    ],
    swap_slogan: [
      "Ocuppy the place left by a team-mate",
      "Fast movement",
      "Go back through the middle lane",
    ],
    deletePassLine_slogan: [
      "Position between the opponent with the ball and other opponent who can receive it",
      "Be aware of space I need to protect",
      "Paths: straight or curved",
    ],
    controlToGive_slogan: [
      "Don't stop the ball",
      "One foot towards the ball, the other towards where I want to go",
      "Use the farthest leg",
      "Relax the foot to keep the ball",
      "Raise head before receiving the ball",
      "Feint for create more room",
      "Control with the farthest leg from the opponent",
      "Protect yourself with your opponent's arms",
    ],
    controlToOvercome_slogan: [
      "Run with control",
      "One foot to the ball, the other to where I want to go",
      "Attack the ball",
      "Raise head before receiving the ball",
      "Feint for make more room",
      "Control with the farthest leg from the opponent",
      "Protect myself with the opponent's arms",
    ],
    controlToProtect_slogan: [
      "Facing my goal",
      "Get ass out",
      "Use the sole of the foot",
      "Protect myself with the opponent's arms",
    ],
    drivingToOvercome_slogan: [
      "Towards a clearance and to the goal",
      "Master the ball",
      "Fast",
      "Use external",
      "Use the farthest leg from the opponent",
      "Raise head",
    ],
    drivingToFix_slogan: [
      "Towards an opponent",
      "Master the ball",
      "Use external",
      "Raise head while piping",
      "Identify when the opponent keeps an eye on me",
    ],
    wateredToOvercome_slogan: [
      "Fix + Trick (work on different types of dribblings) + Change direction and pace",
      "Cross in front of the opponent once surpassed",
      "Towards the goal",
    ],
    unmarkedToReceive_slogan: [
      "Identify the clearance I want to occupy",
      "Change the pace",
      "Eye contact with the opponent having the ball",
      "Signal to ask for the ball",
      "Feint for make more room",
      "Surpass the opponent",
    ],
    shotToPlace_slogan: [
      "Use inner",
      "Hit the ball, not accompanying it",
      "Supporting foot marks direction and height of the shot",
      "Cross shot",
      "Look at the goalkeeper",
      "Kick and continue action",
      "Use external",
    ],
    powerfulShot_slogan: [
      "Use instep",
      "Hit the ball, not accompanying it",
      "Supporting foot marks direction and height of the shot",
      "Tilt the body forward",
      "Look at the goalkeeper",
      "Kick and continue action",
    ],
    finishedToScore_slogan: [
      "Attack the ball",
      "Use only 1 contact",
      "Hit the ball, not accompanying it",
      "Hit the ball when it falls, not when it rises",
      "Look at the goalkeeper",
    ],
    dialingToWatch_slogan: ["POP: Ball, opponent, goal"],
    anticipateToAttack_slogan: [
      "Active feet",
      "Change pace to surpass the opponent",
      "Use limbs to keep the ball",
      "Run with control",
    ],
    interceptToRefuse_slogan: [
      "high or low",
      "Strong",
      "As far as possible or to the nearest sideline",
      "To the area where a team-mate stays to counterattack",
    ],
    brakeInput_slogan: [
      "Reduce distance until the opponent",
      "Move the body sideways",
      "Take opponent to the nearest side",
      "Run back with the opponent",
    ],
    entryToRecover_slogan: [
      "Reduce distance until the opponent",
      "Move the body sideways",
      "Take opponent to the nearest side",
      "Run back with the opponent",
      "Position the body between opponent and the ball",
      "Tackle throwing on the ground",
    ],
    entryToRefuse_slogan: [
      "Reduce distance until the opponent",
      "Move the body sideways",
      "Run back with the attacker (if necessary)",
      "Tackle with either foot",
      "Tackle throwing on the ground",
    ],
    individualMarkingWithinTheArea_slogan: [
      "Position between opponent and the goal",
      "Follow opponent",
      "Touch opponent",
    ],
    forceDisplacementChangesOfDirection_slogan: [
      "Changes of direction",
      "Runs and brakings",
      "Changes of pace and frequency of support",
    ],
  },
  sessions: {
    title: "Session",
    noSessions: "No sessions",
    noFiltered: "With the current filters there are no sessions",
    modal: "Create a session",

    microcycle: "Microcycle",
    session: "No. of session",
    material: "Material",
    asistence: "Attendance",
    date: "Date of session",
    time: "Hour",
    addTask: "Add a task",
    instrucions: "Instructions",

    serie: "Series",
    serieTime: "Duration (min)",
    serieRecuperation: "Rest (min)",
    totalTime: "Total (min)",
    selectTask: "Select task",
    pdf: "Generate PDF",
  },
  matches: {
    title: "Matches",
    noMatches: "No matches",
    noFiltered: "With the current filters there are no matches",
    modal: "Create a match",

    rival: "Opponent",
    competition: "Competition",
    turn: "Turn",
    playingHome: "Playing home",
    playingOut: "Playing out",
    hour: "Hour",
    result: "Result",

    goal: "Goal",
    change: "Change",
    yellowCard: "Yellow card",
    redCard: "Red card",

    summoned: "Summoned",
    lineUp: "Line up",
    minute: "Minute",
    events: "Events",

    entring: "Incoming",
    outgoing: "Retiring",
    date: "Match date",

    league: "League",
    friendly: "Friendly",
  },
  statistics: {
    goals: "Goals",
    matchMinutes: "Played minutes",
    attendance: "Attendance at training",
    noTeam: "Select team to see statistics",
  },
  trackings: {
    watch: "Watch",
    discard: "Discard",
    follow: "Follow",
    sign: "Sign",

    leg: "Dominant leg",
    position: "Position",
    right: "Right",
    left: "Left",
    comments: "Comments",
  },
  accounting: {
    scholarships: "Scholarships",
    quota: "Quote",
    enrollment: "Enrolment",

    october: "October",
    november: "November",
    december: "December",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
  },
};
