<template>
  <form autocomplete="off" :disabled="disabled">
    <div class="d-flex flex-wrap justify-space-between">
      <div
        v-for="[day] in days"
        :key="day"
        class="d-flex flex-column align-center mb-1"
        style="width: 80px"
      >
        {{ $ml.get(`teams.trainings.${day}`) }}
        <v-switch v-model="daysMenu[day].active" :disabled="disabled" />

        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="daysMenu[day].start"
              :label="$ml.get('teams.trainings.start')"
              v-bind="attrs"
              v-on="on"
              hide-details
              :disabled="!daysMenu[day].active"
              :id="`${day}-start`"
            />
          </template>
          <v-time-picker
            full-width
            format="24hr"
            @change="(time) => (daysMenu[day].start = time)"
          />
        </v-menu>

        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="daysMenu[day].end"
              :label="$ml.get('teams.trainings.end')"
              v-bind="attrs"
              v-on="on"
              hide-details
              :disabled="!daysMenu[day].active"
              :id="`${day}-end`"
            />
          </template>
          <v-time-picker
            full-width
            format="24hr"
            @change="(time) => (daysMenu[day].end = time)"
          />
        </v-menu>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Trainings",
  props: {
    trainings: Array,
    reset: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    loaded: false,
    daysMenu: {
      monday: { active: false, start: "", end: "" },
      tuesday: { active: false, start: "", end: "" },
      wednesday: { active: false, start: "", end: "" },
      thursday: { active: false, start: "", end: "" },
      friday: { active: false, start: "", end: "" },
    },
  }),
  created() {
    this.$props.trainings?.forEach(({ day, start, end }) => {
      this.daysMenu[day] = { start, end, active: true };
    });
  },
  watch: {
    filteredDays: {
      deep: true,
      handler() {
        if (this.loaded) this.$emit("update", this.formatDays);
        else this.loaded = true;
      },
    },
    reset() {
      this.daysMenu = {
        monday: { active: false, start: "", end: "" },
        tuesday: { active: false, start: "", end: "" },
        wednesday: { active: false, start: "", end: "" },
        thursday: { active: false, start: "", end: "" },
        friday: { active: false, start: "", end: "" },
      };
    },
  },
  computed: {
    days() {
      return Object.entries(this.daysMenu);
    },
    filteredDays() {
      return this.days.filter(([, { active }]) => active);
    },
    formatDays() {
      return this.filteredDays.map(([day, { start, end }]) => {
        return { start, end, day };
      });
    },
  },
  methods: {
    onSelectTime(time, day, period) {
      const actualDay = this.days.find((data) => data.day === day);
      actualDay[period] = time;
    },
  },
};
</script>
