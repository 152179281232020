export default {
  filter: {
    filters: "Filtrar",
    sorters: "Ordenar",
    alphabetic: "Alfabètic",
    creation: "Creació",
    year: "Any",
    noBirth: "Sende data de naixement definida",
  },
  header: {
    theme: "Canviar el tema",
  },
  user: {
    title: "Usuari",
    add: "Afegeix un usuari",
    delete: "Eliminar usuari",
    type: "Tipus d'usuari",
    updatePassword: "Canviar contrasenya",
    newPassword: "Nova contrasenya",
    repeatPassword: "Repetir contrasenya",
    oldPassword: "Contrasenya actual",
    logout: "Tancat sessió",
  },
  players: {
    title: "Esportista",
    noPlayers: "No hi ha esportistes",
    noFiltered: "Amb els filtres actuals no hi ha esportistes",
    modal: "Afegir esportista",

    age: "Edat",
    parentalData: "Dades familiars",
    evaluation: "Avaluació",
    mental: "Mental",
    conditional: "Condició",
    game: "Joc",
  },
  coaches: {
    title: "Personal tècnic",
    noCoaches: "No hi ha entrenadors",
    noFiltered: "Amb els filtres actuals no hi ha entrenadors",
    modal: "Afegir personal tècnic",

    order: "Ordre",
    passon: "Transmetre",
    sessiondesign: "Disseny sessió",
    sessiondirection: "Direcció sessió",
    matchmanagement: "Direcció partits",
    groupmanagement: "Gestió grup",
  },
  teams: {
    title: "Equip",
    noTeams: "No hi ha equips",
    noFiltered: "Amb els filtres actuals no hi ha equips",

    teamData: "Dades de l'equip",
    workLine: "Línia de treball",
    matchTime: "Minuts per partit",

    modal: "Afegir equip",

    season: "Temporada",
    category: "Categoria",

    trainings: {
      title: "Dies d'entrenament",
      monday: "Dilluns",
      tuesday: "Dimarts",
      wednesday: "Dimecres",
      thursday: "Dijous",
      friday: "Divendres",
      start: "Inici",
      end: "Final",
    },
  },
  tasks: {
    title: "Tasca",
    noTasks: "No hi ha tasques",
    noFiltered: "Amb els filtres actuals no hi ha tasques",
    modal: "Crear una tasca",
    field: "Gràfic",
    selectField: "Selecciona el camp",

    taskType: "Tipus de tasca",
    game: "Joc",
    RT1: "RT1",
    RT2: "RT2",
    RT3: "RT3",
    round: "Rondo",
    conservation: "Conservació",
    position: "Joc de posició",
    situation: "Joc de situació",
    atacDefense: "Atac-defensa",
    match: "Partit condicionat",

    teaching: "Ensenyament",
    introduction: "Introducció",
    learning: "Aprenentatge",
    control: "Domini",

    specificity: "Especificitat",
    general: "General",
    directed: "Dirigida",
    special: "Especial",
    competitive: "Competitiva",

    content: "Contingut",
    aproximation: "Aproximació",
    creator: "Creador",

    sessionDate: "Data de la sessió",
    sessionNumber: "Número de la sessió",

    pass: "Superar",
    finish: "Finalitzar",
    recover: "Recuperar",
    intention: "Intenció",

    explanation: "Explicació",
    regulations: "Normativa",
    punctuation: "Puntuació",
    slogan: "Consignes",

    connectToCommunicate: "Connectar per comunicar",
    moveToFind: "Moure per trobar",
    chopToFinish: "Picar per finalitzar",
    putToFinish: "Posar (jugar) per finalitzar",
    occupyNotToMakePossible: "Ocupar per no possibilitar",
    pressToForce: "Pressionar per forçar",
    occupyToCover: "Ocupar per tapar",
    identifyToPrevent: "Identificar per impedir",
    beInLinePassing: "Estar en línia de passada",
    adjustTheAmplitude: "Ajustar l'amplitud",
    adjustTheDepth: "Ajustar la profunditat",
    passToKeep: "Passada per mantenir",
    passToOvercome: "Passada per superar",
    coverage: "Cobertura",
    swap: "Permuta",
    deletePassLine: "Eliminar línia de passada",
    controlToGive: "Control per donar",
    controlToOvercome: "Control per superar",
    controlToProtect: "Control per protegir",
    drivingToOvercome: "Conducció per superar",
    drivingToFix: "Conducció per fixar",
    wateredToOvercome: "Driblatge per superar",
    unmarkedToReceive: "Desmarcada per rebre",
    shotToPlace: "Xut per col·locar",
    powerfulShot: "Xut amb potència",
    finishedToScore: "Rematada per marcar",
    dialingToWatch: "Marcatge per vigilar",
    anticipateToAttack: "Anticipar per atacar",
    interceptToRefuse: "Interceptar per refusar",
    brakeInput: "Entrada per frenar",
    entryToRecover: "Entrada per recuperar",
    entryToRefuse: "Entrada per refusar",
    individualMarkingWithinTheArea: "Marcatge individual dins l'àrea",
    forceDisplacementChangesOfDirection:
      "Força desplaçament_Canvis de direcció",
    forceDisplacementStartsAndBrakes:
      "Força desplaçament_Arrencades i frenades",
    forceShiftRhythmChanges: "Força desplaçament_Canvis de ritme",
    strongStrugglePush: "Força lluita_Empènyer",
    strongStruggleUnbalance: "Força lluita_Desequilibrar",
    forceJumpImpulse: "Força salt_Impuls",
    forceJumpActionsInSuspension: "Força salt_Accions en suspensió",
    strongPerformanceOnBallPassada: "Força actuació sobre pilota_Passada",
    strongPerformanceOnBallHitting: "Força actuació sobre pilota_Colpeig",
    strongPerformanceOnBallReceptions: "Força actuació sobre pilota_Recepcions",
    strongActionOnBallRefusals: "Força actuació sobre pilota_Refusos",
    eoEoISeeYou: "Eo eo te veo",
    iDecide: "Jo decideixo",
    nowWeWillMark: "Ara marcarem",
    yourMark: "Té, marca tu",
    readToRecover: "Llegir per recuperar",
    IForceYouTo: "Jo et forço a…",
    youWontEvenTouchIt: "Ni la tocaràs",
    youWillNotScore: "No marcaràs",

    connectToCommunicate_slogan: [
      "Agafar per generar, deixar per ocupar",
      "Mateixa alçada amplitud, alçada diferent profunditat",
    ],
    moveToFind_slogan: [
      "Espacio voy, oposición doy",
      "Oposició lluny, passada a prop",
      "Oposició a prop, passada lluny",
      "Estic orientada, estic activada",
      "No orientada, toco de cara",
    ],
    chopToFinish_slogan: [
      "Interval",
      "Atacar, marca",
      "Arrossegar",
      "Entrar per rematar, quedar per protegir",
    ],
    putToFinish_slogan: ["Xutar per marcar", "Posar per finalitzar"],
    occupyNotToMakePossible_slogan: [
      "POP: Pilota, oponent, porteria",
      "Dins-Dins",
      "Fora-Fora",
      "Jugadora en acció provoca recuperació",
    ],
    pressToForce_slogan: [
      "En corba per forçar, recte per pressionar",
      "Saltar i condicionar",
    ],
    occupyToCover_slogan: ["Identificar, tocar, refusar", "Estar per llançar"],
    identifyToPrevent_slogan: ["Tocar per dificultar"],
    beInLinePassing_slogan: [
      "Cap oponent entre la pilota i jo",
      "Tenir els peus actius",
      "Gest per indicar on vull rebre la pilota",
    ],
    adjustTheAmplitude_slogan: [
      "Evitar que una defensa pugui marcar dues atacants alhora",
      "Donar línia de passada",
    ],
    adjustTheDepth_slogan: [
      "Separar-se cap a la porteria més propera",
      "Donar línia de passada",
    ],
    passToKeep_slogan: [
      "Amb avantatge per a la companya",
      "Al peu de la companya",
      "Contacte visual amb la receptora",
      "En diagonal",
      "Tensa i per terra",
    ],
    passToOvercome_slogan: [
      "Amb avantatge per a la companya",
      "Contacte visual amb la receptora",
      "Tensa",
      "En diagonal o en vertical",
      "Per terra o aèria",
      "Gest per ajudar la receptora",
    ],
    coverage_slogan: [
      "Estar per darrere de la companya que defensa l'oponent que té la pilota",
      "Ubicar-se al carril central",
      "Saltar a reduir si la companya és superada amb l'objectiu de frenar l'oponent",
    ],
    swap_slogan: [
      "Ocupar el lloc que ha abandonat la companya",
      "Desplaçament ràpid",
      "Tornar pel carril central",
    ],
    deletePassLine_slogan: [
      "Col·locar-se entre l'oponent que té la pilota i una oponent que la pot rebre",
      "Ser conscient de l'espai que he de protegir",
      "Trajectòries: recte o en corba",
    ],
    controlToGive_slogan: [
      "No parar la pilota",
      "Un peu mira la pilota i l'altre mira cap a on vull anar",
      "Utilitzar la cama allunyada",
      "Relaxar el peu per quedar-se la pilota",
      "Aixecar el cap abans de rebre la pilota",
      "Fintar per crear-se més espai",
      "Controlar amb la cama més allunyada de l'oponent",
      "Protegir-se amb els braços de l'oponent",
    ],
    controlToOvercome_slogan: [
      "Arrencar amb el control",
      "Un peu mira la pilota i l'altre mira cap a on vull anar",
      "Atacar la pilota",
      "Aixecar el cap abans de rebre la pilota",
      "Fintar per crear-se més espai",
      "Controlar amb la cama més allunyada de l'oponent",
      "Protegir-se amb els braços de l'oponent",
    ],
    controlToProtect_slogan: [
      "De cara a la meva porteria",
      "Treure el cul",
      "Fer servir la planta del peu",
      "Protegir-se amb els braços de l'oponent",
    ],
    drivingToOvercome_slogan: [
      "Cap a un espai lliure i en direcció a porteria",
      "Dominar la pilota",
      "Ràpida",
      "Utilitzar l'exterior",
      "Utilitzar la cama més allunyada de l'oponent",
      "Aixecar el cap",
    ],
    drivingToFix_slogan: [
      "Cap a una oponent",
      "Dominar la pilota",
      "Utilitzar l'exterior",
      "Aixecar el cap durant la conducció",
      "Identificar quan l'oponent està pendent de mi",
    ],
    wateredToOvercome_slogan: [
      "Fixar + Enganyar (treballar diferents tipus de driblatges+ Canviar la direcció i el ritme",
      "Creuar-se per davant de l'oponent un cop superada",
      "En direcció a porteria",
    ],
    unmarkedToReceive_slogan: [
      "Identificar l'espai que vull ocupar",
      "Canviar el ritme",
      "Contacte visual amb la posseïdora",
      "Gest per demanar la pilota",
      "Fintar per crear-se més espai",
      "Posar-se per davant de l'oponent",
    ],
    shotToPlace_slogan: [
      "Utilitzar l'interior",
      "Impactar la pilota, no acompanyar-la",
      "El peu de recolzament guia la direcció i l'alçada del xut",
      "Xut creuat",
      "Mirar la portera",
      "Xutar i seguir l'acció",
      "Utilitzar l'exterior",
    ],
    powerfulShot_slogan: [
      "Utilitzar l'empenya",
      "Impactar la pilota, no acompanyar-la",
      "El peu de recolzament guia la direcció i l'alçada del xut",
      "Posar el cos endavant",
      "Mirar la portera",
      "Xutar i seguir l'acció",
    ],
    finishedToScore_slogan: [
      "Atacar la pilota",
      "Utilitzar només 1 contacte",
      "Impactar la pilota, no acompanyar-la",
      "Impactar la pilota quan cau i no quan puja",
      "Mirar la portera",
    ],
    dialingToWatch_slogan: ["POP: Pilota, oponent, porteria"],
    anticipateToAttack_slogan: [
      "Peus actius",
      "Canvi de ritme per posar-se per davant de l'oponent",
      "Utilitzar parts grans del cos per quedar-se la pilota",
      "Arrencar amb el control",
    ],
    interceptToRefuse_slogan: [
      "Aeri o ras",
      "Fort",
      "El més lluny possible o a la línia de banda més propera",
      "A la zona on hi ha la companya per al contraatac",
    ],
    brakeInput_slogan: [
      "Reduir la distància amb l'oponent",
      "Col·locar el cos lateralment",
      "Portar l'oponent cap a la banda més propera",
      "Córrer endarrere amb l'oponent",
    ],
    entryToRecover_slogan: [
      "Reduir la distància amb l'oponent",
      "Col·locar el cos lateralment",
      "Portar l'oponent cap a la banda més propera",
      "Córrer endarrere amb l'oponent",
      "Posar el cos entre l'oponent i la pilota",
      "Entrada llançant-se a terra",
    ],
    entryToRefuse_slogan: [
      "Reduir la distància amb l'oponent",
      "Col·locar el cos lateralment",
      "Córrer enrere amb l'atacant (si és necessari)",
      "Fer l'entrada amb qualsevol dels dos peus",
      "Entrada llançant-se a terra",
    ],
    individualMarkingWithinTheArea_slogan: [
      "Ubicar-se entre l'oponent i la porteria",
      "Seguir l'oponent",
      "Tocar l'oponent",
    ],
    forceDisplacementChangesOfDirection_slogan: [
      "Canvis de direcció",
      "Arrencades i frenades",
      "Canvis de ritme i freqüència de suports",
    ],
  },
  sessions: {
    title: "Sessió",
    noSessions: "No hi ha sessions",
    noFiltered: "Amb els filtres actuals no hi ha sessions",
    modal: "Crea una sessió",

    microcycle: "Microcicle",
    session: "Núm. de sessió",
    material: "Material",
    asistence: "Assistència",
    date: "Data de la sessió",
    time: "Hora",
    addTask: "Afegir una tasca",
    instrucions: "Instrucions",

    serie: "Sèries",
    serieTime: "Duració (min)",
    serieRecuperation: "Descans (min)",
    totalTime: "Total (min)",
    selectTask: "Selecciona la tasca",
    pdf: "Generar PDF",
  },
  matches: {
    title: "Partits",
    noMatches: "No hi ha partits",
    noFiltered: "Amb els filtres actuals no hi ha partits",
    modal: "Crear un partit",

    rival: "Rival",
    competition: "Competició",
    turn: "Jornada",
    playingHome: "Jugant a casa",
    playingOut: "Jugant fora",
    hour: "Hora",
    result: "Resultat",

    goal: "Gol",
    change: "Canvi",
    yellowCard: "Targeta groga",
    redCard: "Targeta vermella",

    summoned: "Convocatòria",
    lineUp: "Alineació",
    minute: "Minut",
    events: "Esdeveniments",

    entring: "Entra",
    outgoing: "Surt",
    date: "Data del partit",

    league: "Lliga",
    friendly: "Amistós",
  },
  statistics: {
    goals: "Gols",
    matchMinutes: "Minuts jugats",
    attendance: "Assistència als entrenaments",
    noTeam: "Selecciona un equip per a veure'n les estadístiques",
  },
  trackings: {
    watch: "Observar",
    discard: "Descartar",
    follow: "Seguir",
    sign: "Fitxar",

    leg: "Cama dominant",
    position: "Posició",
    right: "Dreta",
    left: "Esquerra",
    comments: "Comentaris",
  },
  accounting: {
    scholarships: "Beques",
    quota: "Quota",
    enrollment: "Matrícula",

    october: "Octubre",
    november: "Novembre",
    december: "Desembre",
    january: "Gener",
    february: "Febrer",
    march: "Març",
    april: "Abril",
  },
};
