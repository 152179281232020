var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('FiltersAndSorters',{attrs:{"icon":"$team","initial":"creation","title":_vm.$ml.get('defaults.teams'),"sorters":_vm.sortersConfig},on:{"search":function (sort) { return _vm.search(sort); },"filters-reset":_vm.reset}},[_c('template',{slot:"filters"},[_c('v-text-field',{attrs:{"label":_vm.$ml.get('defaults.name'),"id":"filter-players-name","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1)],2),_c('v-container',[(_vm.teamsSearch.length)?_c('div',[_c('v-row',{staticClass:"d-flex justify-space-between text-h5 mb-2",class:{
          'separator-white': _vm.$vuetify.theme.dark,
          'separator-black': !_vm.$vuetify.theme.dark,
        }},[_c('v-col',[_c('span',{staticClass:"py-2 px-6 text-h6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("teams.category"))+" ")])]),_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"py-2 px-6 text-h6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("defaults.sport"))+" ")])]),_c('v-col',{staticClass:"d-none d-sm-block text-right"},[_c('span',{staticClass:"py-2 px-6 text-h6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("teams.season"))+" ")])])],1),_vm._l((_vm.teamsSearch),function(team){return _c('v-row',{key:team._id,staticClass:"my-1 text-h6 d-flex justify-space-between",class:{
          'team-white': _vm.$vuetify.theme.dark,
          'team-black': !_vm.$vuetify.theme.dark,
        },staticStyle:{"cursor":"pointer"},on:{"click":function () { return _vm.callOpenDetails(team._id); }}},[_c('v-col',{staticClass:"mx-6"},[_vm._v(_vm._s(team.name))]),_c('v-col',{staticClass:"ml-4 text-center"},[_vm._v(" "+_vm._s(team.sport ? _vm.$ml.get(("defaults." + (team.sport))) : "-")+" ")]),_c('v-col',{staticClass:"d-none d-sm-block mx-6 text-right"},[_vm._v(" "+_vm._s(team.season || "-")+" ")])],1)}),_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.pageLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],2):_c('v-col',[_c('p',{staticClass:"text-h4 text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$ml.get("teams.noTeams"))+" ")])])],1),(this.$auth.user.isAdmin)?_c('v-btn',{attrs:{"fab":"","large":"","fixed":"","bottom":"","right":"","dark":"","color":_vm.$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'},on:{"click":function($event){return _vm.openNewDetails()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }