import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import mutations from "./mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    players: [],
    coaches: [],
    teams: [],

    tasks: [],
    sessions: [],
    matches: [],

    trackings: [],

    alerts: [],
    alert: null,

    details: null,

    loading: 0,
  },
  getters: {
    players: (state) => state.players,
    coaches: (state) => state.coaches,
    teams: (state) => state.teams,

    tasks: (state) => state.tasks,
    sessions: (state) => state.sessions,
    matches: (state) => state.matches,

    trackings: (state) => state.trackings,

    alert: (state) => state.alert,
    details: (state) => state.details,
    loading: (state) => state.loading,
  },
  mutations,
  actions,
  modules: {},
});
