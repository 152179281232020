<template>
  <svg viewBox="0 0 40 52">
    <g transform="translate(0,52) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M169 518 c0 -2 -26 -113 -57 -248 -53 -226 -59 -245 -80 -248 -12 -2 -22 -8 -22 -13 0 -5 79 -9 190 -9 120 0 190 4 190 10 0 6 -11 10 -24 10 -23 0 -26 11 -86 250 -60 239 -63 250 -86 250 -13 0 -24 -1 -25 -2z m42 -132 c-16 -19 -41 -10 -41 14 0 24 21 31 39 14 11 -10 12 -16 2 -28z m5 -115 c-4 -6 -16 -11 -27 -11 -21 0 -27 35 -6 43 16 6 41 -18 33 -32z m-5 -135 c-16 -19 -41 -10 -41 14 0 24 21 31 39 14 11 -10 12 -16 2 -28z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Cone",
};
</script>
