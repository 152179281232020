export default {
  sport: "Sport",
  football: "Football",
  futsal: "Futsal",

  ////////////////////////////////////

  players: "Players",
  coaches: "Coaches",
  teams: "Teams",
  tasks: "Tasks",
  sessions: "Sessions",
  statistics: "Statistics",
  matches: "Matches",
  tracking: "Tracking",
  accounting: "Accounting",

  /////////////////////////////////

  name: "Name",
  fullName: "Full name",
  surename: "Surnames",
  birth: "Birth date",
  address: "Address",
  population: "Town",
  postalCode: "Postal code",
  phone: "Phone number",
  email: "E-mail",
  father: "Tutor 2",
  mother: "Tutor 1",
  documents: "Documents",
  personalData: "Personal details",
  contactData: "Contact details",
  size: "Size of clothes",
  reports: "Reports",
  password: "Password",
  admin: "Webmaster",
  date: "Date",
  program: "Program",
  fileName: "File name",

  /////////////////////

  create: "Create",
  add: "Add",
  update: "Update",
  delete: "Delete",
  search: "Search",
  reset: "Reset",

  /////////////////////

  red: "Red",
  orange: "Orange",
  green: "Green",
};
