<template>
  <v-card class="card">
    <v-container v-if="savedTracking">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6">
            <text-field
              id="tracking-name"
              label="defaults.name"
              v-model="tracking.name"
              :rules="[rules.required]"
            />
            <text-field
              id="tracking-surenames"
              label="defaults.surename"
              v-model="tracking.surenames"
              :rules="[rules.required]"
            />
            <v-menu
              v-model="birthMenu"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="player.birth"
                  :value="formatedBirth"
                  append-icon="mdi-calendar"
                  readonly
                  hide-details
                  filled
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  :label="$ml.get('defaults.birth')"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                id="player.birth.picker"
                v-model="formatedBirth"
                @input="birthMenu = false"
              />
            </v-menu>
            <v-autocomplete
              :label="$ml.get('tasks.intention')"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-4"
              item-text="text"
              item-value="id"
              :items="intentionList"
              v-model="tracking.intention"
              :rules="[rules.required]"
            />
          </v-col>

          <v-col cols="12" md="6">
            <text-field
              id="tracking-team"
              label="teams.season"
              v-model="tracking.season"
              :rules="[rules.required]"
            />
            <text-field
              id="tracking-team"
              label="matches.league"
              v-model="tracking.league"
              :rules="[rules.required]"
            />
            <text-field
              id="tracking-team"
              label="teams.title"
              v-model="tracking.actualTeam"
              :rules="[rules.required]"
            />
            <text-field
              id="tracking-team"
              label="trackings.position"
              v-model="tracking.position"
              :rules="[rules.required]"
            />
            <v-autocomplete
              id="tracking-team"
              :label="$ml.get('trackings.leg')"
              filled
              menu-props="offset-y"
              hide-details
              item-text="text"
              item-value="id"
              :items="legList"
              v-model="tracking.leg"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Title text="trackings.comments" />
            <v-textarea
              filled
              rows="3"
              hide-details
              class="mb-4"
              v-model="tracking.comments"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="saveTracking()"
        class="mr-4"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        color="red"
        @click="deleteDialog = true"
        v-if="$auth.user.isAdmin && trackingId"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-dialog v-model="deleteDialog" max-width="500" persistent>
        <DeleteConfirmation @no="deleteDialog = false" @yes="remove" />
      </v-dialog>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Title from "../../components/shared/Title.vue";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";
import TextField from "../../components/shared/text-field.vue";

import objDiff from "../../utils/objDiff";

import alerts from "../../constants/alerts";
import rules from "../../constants/rules";

export default {
  name: "TrackingDetail",
  components: { Title, DeleteConfirmation, TextField, CloseConfirmation },
  data: () => ({
    tracking: {},
    savedTracking: null,

    formatedBirth: "",
    birthMenu: false,
    deleteDialog: false,
    closeDialog: false,

    rules,
  }),
  methods: {
    ...mapActions(["update", "delete", "create", "getById"]),
    ...mapMutations(["setAlert", "openDetails"]),
    async getSavedTracking() {
      this.savedTracking = await this.getById({
        type: "trackings",
        id: this.trackingId,
      });
    },
    defineTracking() {
      this.tracking = { ...this.savedTracking };
      this.formatedBirth = this.savedTracking?.birth?.slice(0, 10);
    },
    saveTracking() {
      if (this.$refs.form.validate()) {
        if (!this.trackingId) {
          this.tracking.birth = new Date(this.formatedBirth);
          this.create({
            type: "trackings",
            createData: this.tracking,
          });
        } else {
          this.update({
            type: "trackings",
            id: this.trackingId,
            updateData: this.trackingToUpdate,
          });
        }
      } else this.setAlert(alerts.emptyError);
    },
    remove() {
      this.delete({
        type: "trackings",
        id: this.trackingId,
      });
    },
    closeDetails() {
      if (Object.keys(this.trackingToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["details"]),
    trackingId() {
      return this.details.id;
    },
    trackingToUpdate() {
      const diff = objDiff(this.tracking, this.savedTracking);

      if (this.savedTracking?.birth) {
        const savedBirth = this.savedTracking.birth.slice(0, 10);
        if (savedBirth !== this.formatedBirth)
          diff.birth = new Date(this.formatedBirth);
      } else if (this.formatedBirth) {
        diff.birth = new Date(this.formatedBirth);
      }

      return diff;
    },
    intentionList() {
      const intentions = ["watch", "discard", "follow", "sign"];
      return intentions.map((intention) => ({
        text: this.$ml.get(`trackings.${intention}`),
        id: intention,
      }));
    },
    legList() {
      const legs = ["right", "left"];
      return legs.map((leg) => ({
        text: this.$ml.get(`trackings.${leg}`),
        id: leg,
      }));
    },
  },
  created() {
    if (this.trackingId) this.getSavedTracking().then(this.defineTracking);
    else this.savedTracking = {};
  },
};
</script>

<style lang="sass" scoped>
.card
  height: 100%
</style>