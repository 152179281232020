var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('FiltersAndSorters',{attrs:{"icon":"mdi-eye","initial":"creation","title":_vm.$ml.get('defaults.tracking'),"sorters":_vm.sortersConfig},on:{"search":function (sort) { return _vm.search(sort); },"filters-reset":_vm.reset}},[_c('template',{slot:"filters"},[_c('v-text-field',{attrs:{"label":_vm.$ml.get('defaults.name'),"id":"filter-players-name","hide-details":"","clearable":""},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}}),_c('div',{staticClass:"ml-4"},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$ml.get('defaults.birth'),"prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.dateText),callback:function ($$v) {_vm.dateText=$$v},expression:"dateText"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.filters.birth),callback:function ($$v) {_vm.$set(_vm.filters, "birth", $$v)},expression:"filters.birth"}})],1)],1)],1)],2),_c('v-container',[(_vm.trackingsSearch.length)?_c('div',[_c('v-row',{staticClass:"d-flex justify-space-between text-h6 mb-2",class:{
          'separator-white': _vm.$vuetify.theme.dark,
          'separator-black': !_vm.$vuetify.theme.dark,
        }},[_c('v-col',[_c('span',{staticClass:"py-2 px-6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("defaults.name"))+" ")])]),_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"py-2 px-6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("filter.year"))+" ")])]),_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"py-2 px-6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("teams.title"))+" ")])]),_c('v-col',{staticClass:"text-right"},[_c('span',{staticClass:"py-2 px-6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("tasks.intention"))+" ")])])],1),_vm._l((_vm.trackingsSearch),function(tracking){return _c('v-row',{key:tracking._id,staticClass:"my-1 text-h6 d-flex justify-space-between",class:{
          'tracking-white': _vm.$vuetify.theme.dark,
          'tracking-black': !_vm.$vuetify.theme.dark,
        },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.callOpenDetails(tracking._id)}}},[_c('v-col',[_vm._v(_vm._s(tracking.name))]),_c('v-col',{staticClass:"text-center"},[_vm._v(_vm._s(tracking.birth))]),_c('v-col',{staticClass:"text-center"},[_vm._v(_vm._s(tracking.actualTeam))]),_c('v-col',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$ml.get(("trackings." + (tracking.intention))))+" ")])],1)}),_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.pageLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],2):_c('div',[_c('p',{staticClass:"text-h4 text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$ml.get("players.noPlayers"))+" ")])])]),_c('v-btn',{attrs:{"fab":"","large":"","fixed":"","bottom":"","right":"","dark":"","color":_vm.$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'},on:{"click":function($event){return _vm.openNewDetails()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }