import alerts from "./alertsENG";

import components from "./componentsENG";
import defaults from "./defaultENG";

export default {
  alerts,
  ...components,
  defaults,
};
