<template>
  <v-card>
    <v-container v-if="savedMatch">
      <v-form ref="form">
        <v-row class="mt-2">
          <v-col cols="12" md="4">
            <v-autocomplete
              :label="$ml.get('matches.competition')"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-1"
              item-text="text"
              item-value="id"
              :items="competitionsList"
              v-model="match.competition"
            />
            <div class="d-flex align-center mb-1">
              <text-field
                id="match.turn"
                label="matches.turn"
                type="number"
                v-model="match.turn"
                :rules="[rules.positive]"
              />
              <v-switch
                v-model="match.playingHome"
                inset
                class="ml-4"
                :label="
                  $ml.get(
                    `matches.playing${match.playingHome ? 'Home' : 'Out'}`
                  )
                "
              />
            </div>

            <v-autocomplete
              :label="$ml.get('defaults.sport')"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-4"
              item-text="text"
              item-value="id"
              :items="sportsList"
              v-model="match.sport"
              :rules="[rules.required]"
            />

            <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="match.date"
                  :label="$ml.get('defaults.date')"
                  :value="formatedDate"
                  append-icon="mdi-calendar"
                  readonly
                  hide-details
                  filled
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                id="match.date.picker"
                v-model="formatedDate"
                @input="dateMenu = false"
              />
            </v-menu>

            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                  v-model="time"
                  :label="$ml.get('matches.hour')"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  hide-details
                  class="mb-4"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                />
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="$refs.menu.save(time)"
              />
            </v-menu>

            <v-autocomplete
              :label="$ml.get('matches.summoned')"
              :disabled="!match.team"
              filled
              hide-details
              item-text="name"
              item-value="_id"
              multiple
              menu-props="offset-y"
              chips
              deletable-chips
              small-chips
              class="mb-4"
              :items="playersList"
              v-model="match.summoned"
            />

            <v-autocomplete
              :label="$ml.get('matches.lineUp')"
              :disabled="!match.summoned"
              filled
              hide-details
              item-text="name"
              item-value="_id"
              chips
              deletable-chips
              small-chips
              multiple
              menu-props="offset-y"
              class="mb-4"
              :items="summonedList"
              v-model="match.lineUp"
            />
          </v-col>
          <v-col md="8">
            <div
              class="d-flex align-center mb-4"
              :class="match.playingHome ? 'flex-row' : 'flex-row-reverse'"
            >
              <v-autocomplete
                :label="$ml.get('teams.title')"
                filled
                menu-props="offset-y"
                :class="match.playingHome ? 'mr-2' : 'ml-2'"
                hide-details
                item-text="name"
                item-value="_id"
                :items="teams"
                v-model="match.team"
                :rules="[rules.required]"
              />

              <span class="mx-2 text-h4">{{ result.wss }}</span>
              -
              <span class="mx-2 text-h4">{{ result.rival }}</span>

              <text-field
                id="match.rival"
                label="matches.rival"
                v-model="match.rival"
                :class="match.playingHome ? 'ml-2' : 'mr-2'"
                :rules="[rules.required]"
              />
            </div>
            <Title text="matches.events" />
            <div v-for="(event, index) in events" :key="index">
              <div
                v-if="event.type !== 'change'"
                class="d-flex justify-space-between align-center my-2"
              >
                <span>
                  <span>{{ event.minute }}' </span>
                  <span>
                    {{
                      event.player
                        ? getEventPlayerName(event.player)
                        : match.rival
                    }}
                  </span>
                </span>

                <v-icon v-if="event.type === 'goal'"> mdi-soccer </v-icon>
                <v-icon
                  v-else
                  :color="event.type === 'yellowCard' ? 'yellow' : 'red'"
                  style="transform: rotate(90deg)"
                >
                  mdi-card
                </v-icon>
              </div>

              <div
                v-else
                class="d-flex justify-space-between align-center my-2"
              >
                <div class="d-flex">
                  <span class="mr-2">{{ event.minute }}'</span>
                  <div class="d-flex flex-column">
                    <span>
                      <v-icon color="green" x-small>mdi-arrow-up-bold</v-icon>
                      {{ getEventPlayerName(event.newPlayer) }}
                    </span>
                    <span>
                      <v-icon color="red" x-small>mdi-arrow-down-bold</v-icon>
                      {{ getEventPlayerName(event.outPlayer) }}
                    </span>
                  </div>
                </div>
                <v-icon> mdi-autorenew </v-icon>
              </div>

              <v-divider></v-divider>
            </div>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  block
                  class="text-h5 mt-2"
                  v-on="on"
                  v-bind="attrs"
                  :disabled="!(match.team && match.rival)"
                >
                  +
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="() => (eventGoals = true)">
                  {{ $ml.get("matches.goal") }}
                </v-list-item>
                <v-list-item @click="() => (eventChanges = true)">
                  {{ $ml.get("matches.change") }}
                </v-list-item>
                <v-list-item @click="() => (eventYellowCards = true)">
                  {{ $ml.get("matches.yellowCard") }}
                </v-list-item>
                <v-list-item @click="() => (eventRedCards = true)">
                  {{ $ml.get("matches.redCard") }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="matchId">
          <v-col>
            <Documents
              type="matches"
              :id="matchId"
              :files="match.files || []"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-dialog v-model="eventGoals" max-width="500" persistent>
        <EventGoals
          :players="playersList"
          :rivalName="match.rival"
          @close="() => (eventGoals = false)"
          @eventGoal="(goal) => (match.goals = [...match.goals, goal])"
        />
      </v-dialog>
      <v-dialog v-model="eventChanges" max-width="500" persistent>
        <EventChanges
          :players="playersList"
          @close="() => (eventChanges = false)"
          @eventChange="
            (change) => (match.changes = [...match.changes, change])
          "
        />
      </v-dialog>
      <v-dialog v-model="eventYellowCards" max-width="500" persistent>
        <EventYellowCards
          :players="playersList"
          @close="() => (eventYellowCards = false)"
          @eventYellowCards="
            (yellowCard) =>
              (match.yellowCards = [...match.yellowCards, yellowCard])
          "
        />
      </v-dialog>
      <v-dialog v-model="eventRedCards" max-width="500" persistent>
        <EventRedCards
          :players="playersList"
          @close="() => (eventRedCards = false)"
          @eventRedCards="
            (redCard) => (match.redCards = [...match.redCards, redCard])
          "
        />
      </v-dialog>
      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="updateData"
        class="mr-4"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        color="red"
        @click="deleteDialog = true"
        v-if="$auth.user.isAdmin"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-dialog v-model="deleteDialog" max-width="500" persistent>
        <DeleteConfirmation @no="deleteDialog = false" @yes="remove" />
      </v-dialog>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import objDiff from "../../utils/objDiff";

import Title from "../../components/shared/Title.vue";
import TextField from "../../components/shared/text-field.vue";
import EventGoals from "../../components/Modals/EventGoals.vue";
import EventChanges from "../../components/Modals/EventChanges.vue";
import EventYellowCards from "../../components/Modals/EventYellowCards.vue";
import EventRedCards from "../../components/Modals/EventRedCards.vue";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";
import Documents from "../../components/Files/Documents.vue";

import alerts from "../../constants/alerts";
import rules from "../../constants/rules";
import { sports, competitions } from "../../constants/selectorsList";

export default {
  name: "MatchesDetail",
  components: {
    Title,
    TextField,
    EventGoals,
    EventChanges,
    EventYellowCards,
    EventRedCards,
    DeleteConfirmation,
    CloseConfirmation,
    Documents,
  },
  data: () => ({
    match: {},
    savedMatch: null,
    formatedDate: "",

    eventGoals: false,
    eventChanges: false,
    eventYellowCards: false,
    eventRedCards: false,

    time: "",
    timeMenu: false,
    dateMenu: false,
    deleteDialog: false,
    closeDialog: false,

    rules,
  }),
  methods: {
    ...mapActions(["update", "delete", "getById", "getList", "create"]),
    ...mapMutations(["setAlert", "openDetails"]),
    defineMatch() {
      this.match = { ...this.savedMatch };
      this.formatedDate = this.savedMatch?.date.slice(0, 10);
      this.time = this.savedMatch.hour;
    },
    async getSavedMatch() {
      this.savedMatch = await this.getById({
        type: "matches",
        id: this.matchId,
      });
    },
    updateData() {
      if (this.$refs.form.validate()) {
        if (!this.teamId) {
          this.match.date = new Date(this.formatedDate);
          this.create({
            type: "matches",
            createData: this.match,
          });
        } else {
          this.update({
            type: "matches",
            id: this.matchId,
            updateData: this.matchToUpdate,
          });
        }
      } else this.setAlert(alerts.emptyError);
    },
    remove() {
      this.delete({
        type: "matches",
        id: this.matchId,
      });
    },
    getEventPlayerName(id) {
      const player = this.players.find((player) => player._id === id);
      return player.name;
    },
    closeDetails() {
      if (Object.keys(this.matchToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["teams", "details", "players"]),
    matchId() {
      return this.details.id;
    },
    matchToUpdate() {
      const toUpdate = objDiff(this.match, this.savedMatch);

      if (this.savedMatch?.date) {
        const savedDate = this.savedMatch.date.slice(0, 10);
        if (savedDate !== this.formatedDate)
          toUpdate.date = new Date(this.formatedDate);
      }

      return toUpdate;
    },
    result() {
      if (!this.match.goals) return [0, 0];
      return this.match.goals.reduce(
        (result, goal) => {
          result[goal.player ? "wss" : "rival"]++;
          return result;
        },
        { wss: 0, rival: 0 }
      );
    },
    events() {
      const { goals, changes, yellowCards, redCards } = this.match;
      if (!(goals && changes && yellowCards && redCards)) return [];

      const goalsList = goals.map((goal) => ({ ...goal, type: "goal" }));
      const changesList = changes.map((change) => ({
        ...change,
        type: "change",
      }));
      const yellowCardsList = yellowCards.map((yellowCard) => ({
        ...yellowCard,
        type: "yellowCard",
      }));
      const redCardsList = redCards.map((redCard) => ({
        ...redCard,
        type: "redCard",
      }));

      const allEvents = [
        ...goalsList,
        ...changesList,
        ...yellowCardsList,
        ...redCardsList,
      ];

      return allEvents.sort((eventA, eventB) => eventA.minute - eventB.minute);
    },
    competitionsList() {
      return competitions.map((competition) => ({
        text: this.$ml.get(`matches.${competition}`),
        id: competition,
      }));
    },
    sportsList() {
      return sports.map((sport) => ({
        text: this.$ml.get(`defaults.${sport}`),
        id: sport,
      }));
    },
    playersList() {
      if (!this.match.team) return [];
      return this.players.filter((player) =>
        player.teams.some((team) => team === this.match.team)
      );
    },
    summonedList() {
      if (!this.match.summoned?.length) return [];
      return this.playersList.filter((player) =>
        this.match.summoned.some((playerId) => player._id === playerId)
      );
    },
  },
  watch: {
    time() {
      this.match.hour = this.time;
    },
  },
  created() {
    if (!this.players.length) {
      const filter = { teams: this.$auth.user.teams };
      this.getList({ type: "players", filter });
    }

    if (this.matchId) this.getSavedMatch().then(this.defineMatch);
    else {
      this.savedMatch = {};
      this.match = {
        goals: [],
        changes: [],
        yellowCards: [],
        redCards: [],
      };
    }
  },
};
</script>