<template>
  <svg viewBox="0 0 38 36">
    <g transform="translate(0,36) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M73 191 l-63 -49 31 -6 c41 -8 257 -8 298 0 l32 7 -67 48 c-60 44 -72 49 -118 48 -45 0 -58 -6 -113 -48z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "tinyCone",
};
</script>
