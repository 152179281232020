<template>
  <section class="pdf-container pa-4" v-if="sessionName">
    <div ref="html2pdf" class="pdf">
      <div class="pdf__title">
        <img
          src="../../assets/escut-black.png"
          alt="Escut"
          width="80"
          height="80"
        />
        <span>
          <b>Women's Soccer School</b>
          Barcelona
        </span>
      </div>
      <div class="pdf__header">
        <div class="header__left">
          <p>
            <b>{{ $ml.get("teams.title") }}</b>
            :
            {{ session.teamName }}
          </p>

          <p>
            <b>{{ $ml.get("sessions.title") }}</b>
            :
            {{ sessionName }}
          </p>
        </div>
        <div class="header__right">
          <p>
            <b>{{ $ml.get("sessions.microcycle") }}</b>
            :
            {{ session.microcycle }}
          </p>
          <p>
            <b>{{ $ml.get("sessions.session") }}</b>
            :
            {{ session.session }}
          </p>
          <p>
            <b>{{ $ml.get("sessions.asistence") }}</b>
            :
            {{ session.assistance && session.assistance.length }}
            {{ $ml.get("defaults.players") }}
          </p>
        </div>
      </div>

      <div class="pdf__date-time">
        <p>
          <span>
            <b>{{ $ml.get("defaults.date") }}</b>
            :
            {{ `${dateDay}   ${formatedDate}` }}
          </span>

          <span>
            <b>{{ $ml.get("sessions.time") }}</b>
            :
            {{ session.time }}h
          </span>
        </p>
      </div>

      <div class="pdf__material" v-if="session.material">
        <div class="title__background">
          <span class="title__text">
            {{ $ml.get("sessions.material") }}
          </span>
        </div>
        <p class="material__text">{{ session.material }}</p>
      </div>

      <div
        class="pdf__task"
        v-for="(task, index) in session.tasks"
        :key="task._id"
      >
        <div class="title__background">
          <span class="title__text">
            {{ $ml.get("tasks.title") }} #{{ index + 1 }}
          </span>
        </div>

        <div class="task__content">
          <div class="content__graf">
            <p class="graf__serie">
              <span class="mr-2">
                <b>{{ $ml.get("sessions.serie") }}</b>
                :
                {{ task.series }}
              </span>
              <span>
                <b>{{ $ml.get("sessions.serieTime") }}</b>
                :
                {{ task.serieTime }}
              </span>
              <br />
              <span class="mr-2">
                <b>{{ $ml.get("sessions.serieRecuperation") }}</b>
                :
                {{ task.serieRecuperation }}
              </span>
              <span>
                <b>{{ $ml.get("sessions.totalTime") }}</b>
                :
                {{ getTotalTime(task) }}
              </span>
            </p>
            <ShowField :task="task" />
            <p class="graf__name">
              {{ getTaskName(task.task) }}
            </p>
          </div>

          <div class="content__text">
            <div
              v-if="task.task && task.task.regulation"
              class="text__regulations"
            >
              <b>{{ $ml.get("tasks.regulations") }}</b>
              <p>{{ task.task && task.task.regulation }}</p>
            </div>
            <div
              v-if="task.instrucions || (task.task && task.task.explanation)"
              class="text__explanation"
            >
              <b>{{ $ml.get("tasks.explanation") }}</b>
              <p>
                {{ task.instrucions || (task.task && task.task.explanation) }}
              </p>
            </div>

            <div
              v-if="task.task && task.task.punctuation"
              class="text__punctuation"
            >
              <b>{{ $ml.get("tasks.punctuation") }}</b>
              <p>{{ task.task && task.task.punctuation }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";

import ShowField from "../TaskField/ShowField.vue";

export default {
  components: { ShowField },
  props: { pdf: Boolean, session: Object },
  methods: {
    generatePDF() {
      const options = {
        filename: this.sessionName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
        margin: 40,
        pageBreak: { mode: ["avoid-all", "css", "legacy"] },
      };

      html2pdf().set(options).from(this.$refs.html2pdf).toPdf().save();
    },
    getTaskName(task) {
      if (task?.intention) {
        const intentionTrans = task.intention
          ? this.$ml.get(`tasks.${task.intention}`)
          : " _ ";
        const specificityTrans = task.specificity
          ? this.$ml.get(`tasks.${task.specificity}`)
          : " _ ";
        const taskTypeTrans = task.taskType
          ? this.$ml.get(`tasks.${task.taskType}`)
          : " _ ";
        const contentTrans = task.content
          ? this.$ml.get(`tasks.${task.content}`)
          : " _ ";

        return `${intentionTrans} - ${specificityTrans} - ${taskTypeTrans} - ${contentTrans} - ${
          task.players || "_"
        }`;
      }

      return "";
    },
    getTotalTime(task) {
      const { series, serieTime, serieRecuperation } = task;

      return series * (+serieTime + +serieRecuperation);
    },
  },
  computed: {
    ...mapGetters(["details"]),
    sessionName() {
      return `${this.session.teamProgram} - ${this.session.teamName} - ${this.session.session} (${this.formatedDate})`;
    },
    formatedDate() {
      return this.session.date
        ? new Date(this.session.date).toLocaleString("es-ES").slice(0, 10)
        : "XX-XX-XX";
    },
    dateDay() {
      if (!this.session.date) return "";
      const dayNumber = new Date(this.session.date).getDay();

      const days = ["Dl", "Dm", "Dc", "Dj", "Dv", "Ds", "Dg"];

      return days[dayNumber];
    },
  },
  watch: {
    pdf() {
      this.generatePDF();
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-container {
  background-color: white;
}

.pdf {
  font-family: "Roboto", sans-serif;
  color: black;
  max-width: 80%;
  margin-left: 10%;

  .pdf__title {
    text-transform: uppercase;
    font-size: 20px;
    padding: 20px;
    margin: 0;

    display: flex;
    align-items: center;

    border-bottom: 3px solid black;
  }

  .pdf__header {
    border-bottom: 2px solid black;
    display: flex;
    padding: 0 20px;
    margin-top: 20px;
    margin-bottom: 20px;

    .header__left {
      width: 50%;
    }
  }

  .pdf__date-time {
    border-bottom: 2px solid black;
    padding: 10px 20px;
  }

  .pdf__material {
    border-bottom: 2px solid black;

    .material__text {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 20px;
    }
  }

  .pdf__task {
    .task__content {
      margin-top: 16px;
      display: flex;

      .content__graf {
        max-width: 300px;
        margin-right: 16px;

        .graf__name {
          text-align: center;
          margin: 0;
          max-width: 250px;
        }

        .graf__serie {
          text-align: center;
          margin-bottom: 0;
        }
      }

      .content__text {
        .text__regulations,
        .text__explanation,
        .text__punctuation {
          margin-bottom: 16px;
        }
      }
    }
  }
}
.title__background {
  margin-top: 16px;
}

.title__text {
  background-color: black;
  color: white;
  padding: 8px 16px;
}

line {
  stroke-width: 1;
  stroke: black;
}
</style>
