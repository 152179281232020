import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import Cone from "../svg/cone";
import Goal from "../svg/goal";
import TinyCone from "../svg/tinyCone";
import Pitch from "../svg/pitch";
import Fence from "../svg/fence";

import Player from "../svg/player";
import Coach from "../svg/coach";
import Team from "../svg/team";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        WSSblue: "#000439",
        WSSpink: "#EF2AC1",
        primary: "#000000",
      },
      dark: {
        WSSblue: "#000439",
        WSSpink: "#EF2AC1",
        primary: "#ffffff",
      },
    },
  },
  icons: {
    values: {
      cone: { component: Cone },
      tinyCone: { component: TinyCone },
      goal: { component: Goal },
      pitch: { component: Pitch },
      fence: { component: Fence },
      player: { component: Player },
      coach: { component: Coach },
      team: { component: Team },
    },
  },
});
