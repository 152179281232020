<template>
  <section>
    <FiltersAndSorters
      icon="$player"
      initial="creation"
      :title="$ml.get('defaults.players')"
      :sorters="sortersConfig"
      @search="(sort) => search(sort)"
      @filters-reset="reset"
    >
      <template slot="filters">
        <v-text-field
          :label="$ml.get('defaults.name')"
          id="filter-players-name"
          v-model="filters.name"
          hide-details
          clearable
        />
        <div class="mx-4">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateText"
                :label="$ml.get('defaults.birth')"
                prepend-inner-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="filters.birth" range></v-date-picker>
          </v-menu>
        </div>
        <v-autocomplete
          :label="$ml.get('defaults.teams')"
          :items="teams"
          v-model="filters.team"
          item-text="name"
          item-value="_id"
          hide-details
          multiple
          chips
          small-chips
          deletable-chips
          :disabled="!$auth.user.isAdmin"
        />
      </template>
    </FiltersAndSorters>
    <v-container>
      <v-row>
        <v-col
          class="d-flex flex-wrap justify-center"
          v-if="playersSearch.length"
        >
          <PlayerListItem
            v-for="player in playersSearch"
            :key="player._id"
            :player="player"
          />
        </v-col>
        <v-col v-else>
          <p class="text-h4 text-center mt-4">
            {{ $ml.get("players.noPlayers") }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination v-model="page" :length="pageLength"></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <v-btn
      fab
      large
      fixed
      bottom
      right
      dark
      :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
      @click="openNewDetails()"
      v-if="this.$auth.user.isAdmin"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import PlayerListItem from "../../components/PlayerListItem";
import FiltersAndSorters from "../../components/FiltersAndSorters/FiltersAndSorters.vue";

const sortersConfig = [
  {
    text: "alphabetic",
    icon: "mdi-sort-alphabetical-ascending",
    invertedIcon: "mdi-sort-alphabetical-descending",
    translation: "filter.alphabetic",
  },
  {
    text: "birth",
    icon: "mdi-cake-variant",
    translation: "defaults.birth",
  },
  {
    text: "mainteam",
    icon: "mdi-account-group",
    translation: "teams.title",
  },
  {
    text: "creation",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "filter.creation",
  },
];

const defaultFilter = (teams) => {
  return {
    name: "",
    birth: [],
    team: teams || [],
    sorter: { filter: "creation", state: true },
  };
};

export default {
  name: "Players",
  components: { PlayerListItem, FiltersAndSorters },
  data: () => ({
    playersSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    dialog: false,
  }),
  methods: {
    ...mapActions(["getSearch", "getList"]),
    ...mapMutations(["openDetails"]),
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (sort) filter.sorter = sort;
      this.getSearch({ type: "players", filter }).then(({ page, total }) => {
        this.playersSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter(this.$auth.user.teams);
      this.search();
    },
    openNewDetails() {
      this.openDetails({ type: "players", id: null });
    },
  },
  computed: {
    ...mapGetters(["players", "teams", "details"]),
    pageLength() {
      return Math.ceil(this.total / 24);
    },
    birthList() {
      const years = new Set();
      this.players?.forEach((player) => {
        if (!player.birth) return;
        years.add(player.birth);
      });

      return Array.from(years);
    },
    dateText() {
      if (this.filters.birth.length !== 2) return "";
      else return `${this.filters.birth[0]} | ${this.filters.birth[1]}`;
    },
  },
  watch: {
    page() {
      this.search();
    },
    details() {
      if (!this.details) this.search();
    },
  },
  created() {
    if (!this.$auth.user.isAdmin) this.filters.team = this.$auth.user.teams;

    const filter = { teams: this.$auth.user.teams };
    if (!this.players.length) this.getList({ type: "players", filter });
    if (!this.teams.length) this.getList({ type: "teams", filter });

    this.search();
  },
};
</script>