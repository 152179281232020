export default {
  created: "Creado",
  deleted: "Eliminado",
  updated: "Modificado",

  correctly: "correctamente",

  error: "Se ha producido un error",
  empty: "Rellena toda la información",
  userNotFound: "No se ha encontrado el usuario",
  defaultError: "Ha surgido un error inesperado",
  wrongPassword: "La contraseña es incorrecta",
  noUpdateData: "No se ha modificado ningún dato",

  deleteconfirmation: "¿Estas seguro que lo quieres eliminar?",
  closeconfirmation: "Tienes cambios sin guardar ¿Seguro que quieres salir?",
  matchPassword: "Las contraseñas no coinciden",
  invalidExtension: "La extensión del archivo no es la correcta",
};
