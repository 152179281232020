<template>
  <v-card>
    <v-container v-if="savedTask">
      <v-form ref="form" :disabled="isDisabled">
        <v-row class="mt-2">
          <v-col cols="12" sm="6">
            <text-field
              id="task.name"
              :label="$ml.get('defaults.name')"
              :value="taskName"
              readonly
              :rules="[rules.required]"
            />
            <v-autocomplete
              filled
              hide-details
              class="mb-4"
              :items="intentionList"
              item-text="text"
              item-value="value"
              :label="$ml.get('tasks.intention')"
              v-model="task.intention"
              :rules="[rules.required]"
              menu-props="offset-y"
            />
            <v-autocomplete
              filled
              hide-details
              class="mb-4"
              :items="specificityList"
              item-text="text"
              item-value="value"
              :label="$ml.get('tasks.specificity')"
              v-model="task.specificity"
              :rules="[rules.required]"
              menu-props="offset-y"
            />
            <v-autocomplete
              filled
              hide-details
              class="mb-4"
              :items="taskTypeList"
              item-text="text"
              item-value="value"
              :label="$ml.get('tasks.taskType')"
              v-model="task.taskType"
              :rules="[rules.required]"
              menu-props="offset-y"
            />
            <text-field
              id="task.players"
              label="defaults.players"
              v-model="task.players"
              :rules="[rules.required]"
            />
            <v-autocomplete
              filled
              hide-details
              class="mb-4"
              :items="teachingList"
              item-text="text"
              item-value="value"
              :label="$ml.get('tasks.teaching')"
              v-model="task.teaching"
              :rules="[rules.required]"
              menu-props="offset-y"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              filled
              hide-details
              class="mb-4"
              :items="contentList"
              item-text="text"
              item-value="value"
              :label="$ml.get('tasks.content')"
              :rules="[rules.required]"
              v-model="task.content"
              menu-props="offset-y"
            />
            <v-autocomplete
              filled
              hide-details
              multiple
              chips
              deletable-chips
              small-chips
              :items="contentSloganList"
              class="mb-4"
              item-text="text"
              item-value="id"
              :label="$ml.get('tasks.slogan')"
              v-model="task.contentSlogan"
            />
            <v-textarea
              :rules="[rules.required]"
              filled
              rows="3"
              hide-details
              auto-grow
              class="mb-4"
              :label="$ml.get('tasks.regulations')"
              v-model="task.regulation"
            />
            <v-textarea
              filled
              rows="2"
              hide-details
              auto-grow
              class="mb-4"
              :label="$ml.get('tasks.explanation')"
              v-model="task.explanation"
            />
            <v-textarea
              filled
              rows="2"
              hide-details
              auto-grow
              class="mb-4"
              :label="$ml.get('tasks.punctuation')"
              v-model="task.punctuation"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col @click="() => (fieldClicked = true)">
            <TaskField :fieldData="task.taskField" @update="onFieldUpdate" />
          </v-col>
        </v-row>
        <v-row v-if="taskId">
          <v-col>
            <Documents
              type="tasks"
              :id="taskId"
              :files="task.files || []"
              :disabled="isDisabled"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="saveTask()"
        v-if="!isDisabled"
        class="mr-4"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        color="red"
        @click="deleteDialog = true"
        v-if="
          taskId &&
          ($auth.user.isAdmin || $auth.user.email === savedTask.creator)
        "
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="clone = true"
        style="margin-left: 72px"
        v-if="taskId"
      >
        <v-icon>mdi-clipboard-multiple-outline</v-icon>
      </v-btn>

      <v-dialog v-model="deleteDialog" max-width="500" persistent>
        <DeleteConfirmation @no="deleteDialog = false" @yes="remove" />
      </v-dialog>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import objDiff from "../../utils/objDiff";

import TaskField from "../../components/TaskField/TaskField.vue";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";
import TextField from "../../components/shared/text-field.vue";
import Documents from "../../components/Files/Documents.vue";

import {
  specificity,
  taskType,
  teaching,
  content,
  intention,
} from "../../components/TaskField/constants";

import alerts from "../../constants/alerts";
import rules from "../../constants/rules";

export default {
  name: "TaskDetail",
  components: {
    TaskField,
    DeleteConfirmation,
    CloseConfirmation,
    TextField,
    Documents,
  },
  data: () => ({
    task: {},
    savedTask: null,

    fieldClicked: false,

    deleteDialog: false,
    closeDialog: false,
    clone: false,

    rules,
  }),
  methods: {
    ...mapActions(["update", "delete", "getById", "create"]),
    ...mapMutations(["setAlert", "openDetails"]),
    async getSavedTask() {
      this.savedTask = await this.getById({
        type: "tasks",
        id: this.taskId,
      });
    },
    defineTask() {
      this.task = { ...this.savedTask };
    },
    saveTask() {
      if (this.$refs.form.validate()) {
        if (!this.taskId) {
          if (this.clone) {
            this.task._id = undefined;
            this.task.__v = undefined;
            this.task.files = [];
          }
          this.task.creator = this.$auth.user.email;
          this.create({
            type: "tasks",
            createData: this.task,
          });
        } else {
          this.update({
            type: "tasks",
            id: this.taskId,
            updateData: this.taskToUpdate,
          });
        }
      } else this.setAlert(alerts.emptyError);
    },
    remove() {
      this.delete({
        type: "tasks",
        id: this.taskId,
      });
    },
    onFieldUpdate(taskField) {
      this.task.taskField = taskField;
    },
    getList(list) {
      return list.map((id) => ({
        text: this.$ml.get(`tasks.${id}`),
        value: id,
      }));
    },
    closeDetails() {
      if (Object.keys(this.taskToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["tasks", "details"]),
    taskId() {
      return this.clone ? null : this.details.id;
    },
    taskToUpdate() {
      const diff = objDiff(this.task, this.savedTask);

      if (!this.fieldClicked) delete diff.taskField;

      return diff;
    },
    specificityList() {
      return this.getList(specificity);
    },
    taskTypeList() {
      return this.getList(taskType);
    },
    teachingList() {
      return this.getList(teaching);
    },
    contentList() {
      return this.getList(content);
    },
    contentSloganList() {
      if (!this.task.content) return [];
      const slogans = this.$ml.get(`tasks.${this.task.content}_slogan`);
      return slogans
        ? slogans.map((slogan, index) => ({ text: slogan, id: index }))
        : [];
    },
    intentionList() {
      return this.getList(intention);
    },
    taskName() {
      const { intention, specificity, taskType, players } = this.task;

      const intentionTrans = intention
        ? this.$ml.get(`tasks.${intention}`)
        : "_";
      const specificityTrans = specificity
        ? this.$ml.get(`tasks.${specificity}`)
        : "_";
      const taskTypeTrans = taskType ? this.$ml.get(`tasks.${taskType}`) : "_";

      return `${intentionTrans} - ${specificityTrans} - ${taskTypeTrans} - ${
        players || "_"
      }`;
    },
    isDisabled() {
      if (!this.taskId || this.$auth.user.isAdmin) return false;
      return this.$auth.user.email !== this.task.creator;
    },
  },
  created() {
    if (this.taskId) this.getSavedTask().then(this.defineTask);
    else this.savedTask = {};
  },
};
</script>