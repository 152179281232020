var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"autocomplete":"off","disabled":_vm.disabled}},[_c('div',{staticClass:"d-flex flex-wrap justify-space-between"},_vm._l((_vm.days),function(ref){
var day = ref[0];
return _c('div',{key:day,staticClass:"d-flex flex-column align-center mb-1",staticStyle:{"width":"80px"}},[_vm._v(" "+_vm._s(_vm.$ml.get(("teams.trainings." + day)))+" "),_c('v-switch',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.daysMenu[day].active),callback:function ($$v) {_vm.$set(_vm.daysMenu[day], "active", $$v)},expression:"daysMenu[day].active"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$ml.get('teams.trainings.start'),"hide-details":"","disabled":!_vm.daysMenu[day].active,"id":(day + "-start")},model:{value:(_vm.daysMenu[day].start),callback:function ($$v) {_vm.$set(_vm.daysMenu[day], "start", $$v)},expression:"daysMenu[day].start"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"change":function (time) { return (_vm.daysMenu[day].start = time); }}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$ml.get('teams.trainings.end'),"hide-details":"","disabled":!_vm.daysMenu[day].active,"id":(day + "-end")},model:{value:(_vm.daysMenu[day].end),callback:function ($$v) {_vm.$set(_vm.daysMenu[day], "end", $$v)},expression:"daysMenu[day].end"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"change":function (time) { return (_vm.daysMenu[day].end = time); }}})],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }