<template>
  <section class="d-flex mx-2 my-4">
    <div
      class="mr-2"
      @click="callOpenDetails('coaches', coach._id)"
      style="cursor: pointer"
    >
      <Avatar
        :src="coach.avatar"
        :size="140"
        :alt="`${coach.name} ${coach.surename}`"
      />
    </div>
    <div class="d-flex flex-column justify-space-between">
      <div class="text-h6 text-uppercase text-truncate">
        <p
          class="coach__name ma-0"
          style="cursor: pointer"
          @click="callOpenDetails('coaches', coach._id)"
        >
          {{ coach.name }}
        </p>
        <v-divider color="black" />
        <div>
          <span
            v-if="coach.mainTeam"
            style="cursor: pointer"
            @click="callOpenDetails('teams', coach.mainTeam._id)"
          >
            {{ coach.mainTeam.name }}
          </span>
          <span v-else>-</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";

import Avatar from "./Files/Avatar.vue";

export default {
  name: "CoachItemList",
  props: { coach: Object },
  components: { Avatar },
  methods: {
    ...mapMutations(["openDetails"]),
    callOpenDetails(type, id) {
      this.openDetails({ type, id });
    },
  },
};
</script>

<style lang="sass" scoped>
.coach__name
  width: 200px
</style>
