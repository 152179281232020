export default {
  sport: "Esport",
  football: "Futbol",
  futsal: "Futsal",

  players: "Esportistes",
  coaches: "Personal tècnic",
  teams: "Equips",
  tasks: "Tasques",
  sessions: "Sessions",
  statistics: "Estadístiques",
  matches: "Partits",
  tracking: "Seguiment",
  accounting: "Comptabilitat",

  //////////////////////////

  name: "Nom",
  fullName: "Nom complet",
  surename: "Cognoms",
  birth: "Data de naixement",
  address: "Adreça",
  population: "Població",
  postalCode: "Codi postal",
  phone: "Telèfon",
  email: "Correu electrònic",
  father: "Tutor 2",
  mother: "Tutor 1",
  documents: "Documents",
  personalData: "Dades personals",
  contactData: "Dades de contacte",
  size: "Talla de roba",
  reports: "Informes",
  password: "Contrasenya",
  admin: "Administrador",
  date: "Data",
  program: "Programa",
  fileName: "Nom de l'arxiu",

  ///////////////////////

  create: "Crea",
  add: "Afegir",
  update: "Actualitza",
  delete: "Eliminar",
  search: "Buscar",
  reset: "Reiniciar",

  /////////////////////
  red: "Vermell",
  orange: "Taronja",
  green: "Verd",
};
