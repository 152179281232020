<template>
  <svg viewBox="0 0 70.99 96.24">
    <g>
      <path d="M45.56,88a8.26,8.26,0,1,1-8.26-8.27A8.25,8.25,0,0,1,45.56,88Z" />
      <path
        d="M59,47a3.74,3.74,0,0,0,5.28,0c2.69-2.7,4.4-7.11,5.36-10.34.58-2,1.87-6.84,1.12-9.22-.48-1.54-1-3.3-16.93-9.51a3.44,3.44,0,0,0-.76-.21v-.05H30.82v0a3.61,3.61,0,0,0-1.17.14c-1.21.36-5.54,2.31-10.7,12.18-4.77,9.12-7,13.34-7.06,13.39a3.73,3.73,0,0,0,1.54,5,3.72,3.72,0,0,0,5-1.53s2.3-4.29,7.09-13.45c2.88-5.53,5.15-7.62,6.07-8.3A39.6,39.6,0,0,1,26.46,43L9.1,67.18a5.4,5.4,0,0,0-.55,1L.4,87.56A5.06,5.06,0,0,0,3.11,94.2a5.24,5.24,0,0,0,2,.4,5.08,5.08,0,0,0,4.68-3.11l7.92-18.86L35.49,47.84l1.68.36L34.89,63.74l-7.86,3a5.07,5.07,0,0,0,1.82,9.8,5.25,5.25,0,0,0,1.81-.33L41.3,72.15a5.08,5.08,0,0,0,3.2-4l3.18-21.77c0-.12,0-.23,0-.36l4-20.89a125.82,125.82,0,0,1,11.79,5.24c-.41,2.89-2.19,9.13-4.45,11.4A3.73,3.73,0,0,0,59,47Z"
      />
      <path d="M49.8,8.18A8.2,8.2,0,1,1,41.6,0,8.21,8.21,0,0,1,49.8,8.18Z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PlayerIcon",
};
</script>
