<template>
  <section>
    <FiltersAndSorters
      icon="mdi-traffic-cone"
      initial="creation"
      :title="$ml.get('defaults.tasks')"
      :sorters="sortersConfig"
      @search="(sort) => search(sort)"
      @filters-reset="reset"
    >
      <template slot="filters">
        <div class="d-flex justify-space-between">
          <v-autocomplete
            :label="$ml.get('tasks.content')"
            :items="content"
            v-model="filters.content"
            item-text="name"
            item-value="id"
            hide-details
            dense
            multiple
            chips
            small-chips
            deletable-chips
          />
          <v-autocomplete
            :label="$ml.get('tasks.taskType')"
            :items="taskType"
            v-model="filters.taskType"
            item-text="name"
            item-value="id"
            hide-details
            dense
            multiple
            chips
            small-chips
            deletable-chips
            class="mx-2"
          />
          <v-autocomplete
            :label="$ml.get('tasks.specificity')"
            :items="specificity"
            v-model="filters.specificity"
            item-text="name"
            item-value="id"
            hide-details
            dense
            multiple
            chips
            small-chips
            deletable-chips
          />
          <v-autocomplete
            :label="$ml.get('defaults.sport')"
            :items="sports"
            v-model="filters.sport"
            item-text="name"
            item-value="id"
            hide-details
            dense
            multiple
            chips
            small-chips
            deletable-chips
            class="mx-2"
          />
          <v-autocomplete
            :label="$ml.get('tasks.creator')"
            :items="creators"
            v-model="filters.creator"
            item-text="name"
            item-value="id"
            hide-details
            dense
            multiple
            chips
            small-chips
            deletable-chips
          />
        </div>
      </template>
    </FiltersAndSorters>

    <v-container fluid>
      <v-row>
        <v-col
          class="d-flex flex-wrap justify-center"
          v-if="tasksSearch.length"
        >
          <div v-for="task in tasksSearch" :key="task._id" class="ma-2">
            <div @click="callOpenDetails(task._id)">
              <ShowField :task="task" />
              <p class="text-center" style="max-width: 276px">
                {{ getTaskName(task.name) }}
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else>
          <p class="text-h4 text-center mt-4">
            {{ $ml.get("tasks.noTasks") }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination v-model="page" :length="pageLength"></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <v-btn
      fab
      large
      fixed
      bottom
      right
      dark
      :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
      @click="openNewDetails()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import FiltersAndSorters from "../../components/FiltersAndSorters/FiltersAndSorters.vue";
import ShowField from "../../components/TaskField/ShowField.vue";

import {
  content,
  taskType,
  specificity,
} from "../../components/TaskField/constants";

import { sports } from "../../constants/selectorsList";

const defaultFilter = () => ({
  content: [],
  taskType: [],
  specificity: [],
  sport: [],
  creator: [],
  sorter: { filter: "creation", state: true },
});

const sortersConfig = [
  {
    text: "creation",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "filter.creation",
  },
];

export default {
  name: "Tasks",
  components: { FiltersAndSorters, ShowField },
  data: () => ({
    tasksSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    dialog: false,
  }),
  created() {
    if (!this.tasks.length) this.getList({ type: "tasks" });

    this.search();
  },
  methods: {
    ...mapMutations(["openDetails"]),
    ...mapActions(["getSearch", "getList"]),
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (sort) filter.sorter = sort;
      this.getSearch({ type: "tasks", filter }).then(({ page, total }) => {
        this.tasksSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter();
      this.search();
    },
    callOpenDetails(id) {
      this.openDetails({ type: "tasks", id });
    },
    openNewDetails() {
      this.openDetails({ type: "tasks", id: null });
    },
    getTaskName(name) {
      return name.reduce((name, item, i) => {
        if (i === 4) return (name += item);
        return (name += item ? this.$ml.get(`tasks.${item}`) : " _ ") + " - ";
      }, "");
    },
  },
  computed: {
    ...mapGetters(["tasks", "details"]),
    pageLength() {
      return Math.ceil(this.total / 24);
    },
    content() {
      return content.map((item) => ({
        id: item,
        name: this.$ml.get(`tasks.${item}`),
      }));
    },
    taskType() {
      return taskType.map((item) => ({
        id: item,
        name: this.$ml.get(`tasks.${item}`),
      }));
    },
    specificity() {
      return specificity.map((item) => ({
        id: item,
        name: this.$ml.get(`tasks.${item}`),
      }));
    },
    sports() {
      return sports.map((item) => ({
        id: item,
        name: this.$ml.get(`defaults.${item}`),
      }));
    },
    creators() {
      const creators = new Set();
      this.tasks.map((task) => creators.add(task.creator));
      return [...creators].map((creator) => ({
        id: creator,
        name: creator.split("@")[0],
      }));
    },
  },
  watch: {
    page() {
      this.search();
    },
    details() {
      if (!this.details) this.search();
    },
  },
};
</script>
