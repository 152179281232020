<template>
  <v-app-bar app absolute color="WSSblue">
    <v-container fluid>
      <v-row>
        <v-col cols="2">
          <router-link to="/">
            <img
              src="../../assets/WSS-escut.png"
              alt="Logo"
              width="50px"
              height="50"
            />
          </router-link>
        </v-col>
        <v-col class="d-flex align-center justify-center" cols="8">
          <div
            v-for="icon in menuIcons"
            :key="icon.icon"
            class="d-none d-md-flex"
          >
            <v-hover v-slot="{ hover }">
              <router-link :to="`/${icon.url}`">
                <v-tooltip bottom color="WSSblue">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        hover || route.url === icon.url ? 'WSSpink' : 'white'
                      "
                      class="icon mx-3"
                      :style="{
                        fill:
                          hover || route.url === icon.url ? '#EF2AC1' : 'white',
                      }"
                      v-bind="attrs"
                      v-on="on"
                      :size="icon.icon.includes('$') ? 32 : 40"
                    >
                      {{ icon.icon }}
                    </v-icon>
                  </template>
                  <b class="WSSpink--text">
                    {{ $ml.get(`defaults.${icon.url}`) }}
                  </b>
                </v-tooltip>
              </router-link>
            </v-hover>
          </div>
          <div class="d-flex d-md-none white--text mr-4">
            <v-menu offset-y color="WSSblue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  <v-icon
                    :style="{
                      fill: $vuetify.theme.dark ? 'white' : 'black',
                    }"
                  >
                    {{ route.icon }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list color="WSSblue">
                <v-list-item v-for="menuItem in menuIcons" :key="menuItem.url">
                  <v-hover v-slot="{ hover }">
                    <router-link :to="`/${menuItem.url}`">
                      <v-icon
                        class="mr-2"
                        :color="
                          hover || route.url === menuItem.url
                            ? 'WSSpink'
                            : 'white'
                        "
                        :style="{
                          fill:
                            hover || route.url === menuItem.url
                              ? '#EF2AC1'
                              : 'white',
                        }"
                      >
                        {{ menuItem.icon }}
                      </v-icon>
                      <b
                        :class="`${
                          hover || route.url === menuItem.url
                            ? 'WSSpink'
                            : 'white'
                        }--text`"
                      >
                        {{ $ml.get(`defaults.${menuItem.url}`) }}
                      </b>
                    </router-link>
                  </v-hover>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col class="d-flex justify-end" cols="2">
          <v-tooltip bottom color="WSSblue">
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  @click="onUpdateTheme"
                  v-bind="attrs"
                  v-on="on"
                  large
                  dark
                  :color="hover ? 'WSSpink' : ''"
                >
                  mdi-brightness-6
                </v-icon>
              </v-hover>
            </template>
            <b class="WSSpink--text">{{ $ml.get("header.theme") }}</b>
          </v-tooltip>

          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  :color="hover ? 'WSSpink' : 'white'"
                  large
                  class="mx-2"
                >
                  mdi-translate
                </v-icon>
              </v-hover>
            </template>

            <v-list color="WSSblue">
              <p
                v-for="lang in langs"
                :key="lang.id"
                class="mx-4"
                @click="() => onUpdateLang(lang.id)"
              >
                <v-hover v-slot="{ hover }">
                  <b
                    :class="
                      hover || $ml.current === lang.id
                        ? 'WSSpink--text'
                        : 'white--text'
                    "
                  >
                    {{ lang.text }}
                  </b>
                </v-hover>
              </p>
            </v-list>
          </v-menu>

          <v-tooltip bottom color="WSSblue">
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  :color="hover ? 'WSSpink' : 'white'"
                  large
                  @click="onLogout"
                >
                  mdi-logout
                </v-icon>
              </v-hover>
            </template>
            <b class="WSSpink--text">{{ $ml.get("user.logout") }}</b>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
const icons = [
  { icon: "$player", url: "players" },
  { icon: "$coach", url: "coaches" },
  { icon: "$team", url: "teams" },
  { icon: "mdi-traffic-cone", url: "tasks" },
  { icon: "mdi-strategy", url: "sessions" },
  { icon: "mdi-scoreboard-outline", url: "matches" },
  { icon: "mdi-chart-line", url: "statistics" },
  { icon: "mdi-eye", url: "tracking" },
];

const adminIcons = [
  { icon: "$player", url: "players" },
  { icon: "$coach", url: "coaches" },
  { icon: "$team", url: "teams" },
  { icon: "mdi-traffic-cone", url: "tasks" },
  { icon: "mdi-strategy", url: "sessions" },
  { icon: "mdi-scoreboard-outline", url: "matches" },
  { icon: "mdi-chart-line", url: "statistics" },
  { icon: "mdi-eye", url: "tracking" },
  { icon: "mdi-cash", url: "accounting" },
];

export default {
  name: "Header",
  props: { loaded: Boolean },
  data: () => ({
    theme: "light",
    lang: "CAT",
    langMenu: false,
  }),
  computed: {
    menuIcons() {
      if (this.$props.loaded) {
        if (this.$auth.user.isAdmin) return adminIcons;
        return icons;
      }
      return [];
    },
    route() {
      const actualRoute = this.$route.fullPath.split("/")[1];
      const route = this.menuIcons.find(
        (menuItem) => menuItem.url === actualRoute
      );
      return route || { url: "/", icon: "mdi-home" };
    },
    langs() {
      return [
        { text: "Català", id: "CAT" },
        { text: "Castellano", id: "ESP" },
        { text: "English", id: "ENG" },
      ];
    },
  },
  methods: {
    onLogout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    onUpdateTheme() {
      this.theme = this.theme === "light" ? "dark" : "light";
    },
    onUpdateLang(lang) {
      this.lang = lang;
    },
  },
  watch: {
    theme() {
      this.$vuetify.theme.light = this.theme === "light";
      this.$vuetify.theme.dark = this.theme === "dark";
      localStorage.setItem("theme", this.theme);
    },
    lang() {
      this.$ml.change(this.lang);
      sessionStorage.setItem(this.lang);
    },
  },
  mounted() {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) this.theme = savedTheme;
  },
};
</script>
