var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 pb-4 d-flex justify-space-between",class:{
    'title__text-black': !_vm.$vuetify.theme.dark,
    'title__text-white': _vm.$vuetify.theme.dark,
    'text-h4': _vm.icon && !_vm.$vuetify.breakpoint.xs,
    'text-h5': !_vm.icon && !_vm.$vuetify.breakpoint.md,
    'text-subtitle-1': _vm.icon && _vm.$vuetify.breakpoint.xs,
    'text-subtitle-1': !_vm.icon && _vm.$vuetify.breakpoint.md,
  }},[_c('span',{staticClass:"py-2 px-6",class:{
      'title__background-black white--text': !_vm.$vuetify.theme.dark,
      'title__background-white black--text': _vm.$vuetify.theme.dark,
    }},[_vm._v(" "+_vm._s(_vm.text.split(".").length > 1 ? _vm.$ml.get(_vm.text) : _vm.text)+" ")]),(_vm.icon)?_c('v-icon',{staticClass:"pr-4",attrs:{"size":"30"},on:{"click":function($event){return _vm.$emit('icon')}}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }