<template>
  <svg viewBox="0 0 113.07 89.19">
    <path
      d="M107.91,48V22.44A15.74,15.74,0,0,0,92.19,6.72H20.12A15.73,15.73,0,0,0,4.41,22.44V83.28a2.13,2.13,0,0,0,2.13,2.14H15.1a2.14,2.14,0,0,0,2.14-2.14V72.46h9.41v.91a2.14,2.14,0,1,0,4.27,0v-.91h9.41v.91a2.14,2.14,0,0,0,4.28,0v-.91H54v.91a2.14,2.14,0,0,0,4.28,0v-.91h9.41v.91a2.14,2.14,0,1,0,4.27,0v-.91h9.41v.91a2.14,2.14,0,0,0,4.28,0v-.91h9.41V83.28a2.14,2.14,0,0,0,2.14,2.14h8.55a2.14,2.14,0,0,0,2.14-2.14V65.85c0-.11,0-.22,0-.33V48.32C107.9,48.21,107.91,48.1,107.91,48ZM26.65,68.19H17.24V58.78h9.41Zm0-13.69H17.24V45.09h9.41Zm0-13.69H17.24V31.4h9.41Zm0-13.68H17.24V22.44a2.88,2.88,0,0,1,2.88-2.89h6.53ZM40.33,68.19H30.92V58.78h9.41Zm0-13.69H30.92V45.09h9.41Zm0-13.69H30.92V31.4h9.41Zm0-13.68H30.92V19.55h9.41ZM54,68.19H44.61V58.78H54ZM54,54.5H44.61V45.09H54Zm0-13.69H44.61V31.4H54Zm0-13.68H44.61V19.55H54ZM67.71,68.19H58.3V58.78h9.41Zm0-13.69H58.3V45.09h9.41Zm0-13.69H58.3V31.4h9.41Zm0-13.68H58.3V19.55h9.41ZM81.39,68.19H72V58.78h9.41Zm0-13.69H72V45.09h9.41Zm0-13.69H72V31.4h9.41Zm0-13.68H72V19.55h9.41ZM95.08,68.19H85.67V58.78h9.41Zm0-13.69H85.67V45.09h9.41Zm0-13.69H85.67V31.4h9.41Zm0-13.68H85.67V19.55h6.52a2.89,2.89,0,0,1,2.89,2.89Zm8.55,54H99.36V22.44a7.18,7.18,0,0,0-7.17-7.17H20.12A7.17,7.17,0,0,0,13,22.44v58.7H8.68V22.44A11.47,11.47,0,0,1,20.12,11H31.26v0H87.34v0h4.85a11.46,11.46,0,0,1,11.44,11.45Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Goal",
};
</script>
