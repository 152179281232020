<template>
  <v-card class="card pa-6" outlined>
    <Title text="sessions.addTask" icon="mdi-close" @icon="$emit('close')" />
    <v-container fluid>
      <v-form ref="form">
        <v-row>
          <v-col cols="5">
            <div class="mr-4">
              <div class="d-flex mb-2">
                <text-field
                  id="task.series"
                  label="sessions.serie"
                  type="number"
                  v-model="series"
                  :rules="[rules.positive]"
                  class="mr-2"
                />
                <text-field
                  id="task.serieTime"
                  label="sessions.serieTime"
                  type="number"
                  v-model="serieTime"
                  :rules="[rules.positive]"
                  class="mr-2"
                />
                <text-field
                  id="task.serieRecuperation"
                  label="sessions.serieRecuperation"
                  type="number"
                  v-model="serieRecuperation"
                  class="mr-2"
                />
                <text-field
                  id="task.totalTime"
                  label="sessions.totalTime"
                  :value="totalTime || ''"
                  readonly
                />
              </div>
              <text-field
                id="task.name"
                label="sessions.selectTask"
                :value="taskName"
                :rules="[rules.required]"
                readonly
              />
            </div>
          </v-col>
          <v-col cols="6">
            <v-textarea
              id="task.instrucions"
              :label="$ml.get('defaults.teams')"
              filled
              hide-details
              rows="4"
              v-model="instrucions"
            />
          </v-col>
          <v-col cols="1">
            <div class="d-flex justify-center">
              <v-btn large @click="addTask">
                {{ $ml.get("defaults.add") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <FiltersAndSorters
              initial="creation"
              :sorters="sortersConfig"
              @search="(sort) => search(sort)"
              @filters-reset="reset"
            >
              <template slot="filters">
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                    :label="$ml.get('tasks.content')"
                    :items="content"
                    v-model="filters.content"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    multiple
                    chips
                    small-chips
                    deletable-chips
                  />
                  <v-autocomplete
                    :label="$ml.get('tasks.taskType')"
                    :items="taskType"
                    v-model="filters.taskType"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    class="mx-2"
                  />
                  <v-autocomplete
                    :label="$ml.get('tasks.specificity')"
                    :items="specificity"
                    v-model="filters.specificity"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    multiple
                    chips
                    small-chips
                    deletable-chips
                  />
                  <v-autocomplete
                    :label="$ml.get('defaults.sport')"
                    :items="sports"
                    v-model="filters.sport"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    class="mx-2"
                  />
                  <v-autocomplete
                    :label="$ml.get('tasks.creator')"
                    :items="creators"
                    v-model="filters.creator"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    multiple
                    chips
                    small-chips
                    deletable-chips
                  />
                </div>
              </template>
            </FiltersAndSorters>
            <div
              class="d-flex flex-wrap justify-center"
              v-if="tasksSearch.length"
            >
              <div v-for="task in tasksSearch" :key="task._id" class="ma-2">
                <div
                  @click="selectedTask = task"
                  class="pa-1"
                  :class="
                    selectedTask && selectedTask._id === task._id
                      ? 'selected'
                      : ''
                  "
                >
                  <ShowField :task="task" />
                  <p class="text-center" style="max-width: 276px">
                    {{ getTaskName(task.name) }}
                  </p>
                </div>
              </div>
            </div>
            <v-pagination v-model="page" :length="pageLength"></v-pagination>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import alerts from "../../constants/alerts";

import rules from "../../constants/rules";

import Title from "../shared/Title.vue";
import FiltersAndSorters from "../FiltersAndSorters/FiltersAndSorters.vue";
import ShowField from "../TaskField/ShowField.vue";
import TextField from "../shared/text-field.vue";

import {
  content,
  taskType,
  specificity,
} from "../../components/TaskField/constants";

import { sports } from "../../constants/selectorsList";

const defaultFilter = () => ({
  content: [],
  taskType: [],
  specificity: [],
  sport: [],
  creator: [],
  sorter: { filter: "creation", state: true },
});

const sortersConfig = [
  {
    text: "creation",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "filter.creation",
  },
];

export default {
  components: { Title, TextField, FiltersAndSorters, ShowField },
  props: { update: Boolean },
  name: "SessionTask",
  data: () => ({
    selectedTask: null,
    instrucions: "",
    series: "",
    serieTime: "",
    serieRecuperation: "",
    tasksSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    rules,
  }),
  created() {
    if (!this.tasks.length) this.getList({ type: "tasks" });

    this.search();
  },
  computed: {
    ...mapGetters(["tasks"]),
    totalTime() {
      return (+this.serieTime + +this.serieRecuperation) * this.series;
    },
    taskName() {
      if (!this.selectedTask) return "";
      return this.getTaskName(this.selectedTask.name);
    },
    pageLength() {
      return Math.ceil(this.total / 24);
    },
    content() {
      return content.map((item) => ({
        id: item,
        name: this.$ml.get(`tasks.${item}`),
      }));
    },
    taskType() {
      return taskType.map((item) => ({
        id: item,
        name: this.$ml.get(`tasks.${item}`),
      }));
    },
    specificity() {
      return specificity.map((item) => ({
        id: item,
        name: this.$ml.get(`tasks.${item}`),
      }));
    },
    sports() {
      return sports.map((item) => ({
        id: item,
        name: this.$ml.get(`defaults.${item}`),
      }));
    },
    creators() {
      const creators = new Set();
      this.tasks.map((task) => creators.add(task.creator));
      return [...creators].map((creator) => ({
        id: creator,
        name: creator.split("@")[0],
      }));
    },
  },
  methods: {
    ...mapActions(["getSearch", "getList"]),
    ...mapMutations(["setAlert"]),
    addTask() {
      if (!this.$refs.form.validate()) return this.setAlert(alerts.emptyError);
      if (!this.selectedTask) return this.setAlert(alerts.emptyError);

      const task = {
        series: this.series,
        serieTime: this.serieTime,
        serieRecuperation: this.serieRecuperation,
        instrucions: this.instrucions,
        task: this.selectedTask._id,
        taskField: this.selectedTask.taskField,
      };

      this.$emit("task", task);
      this.$refs.form.reset();
      this.selectedTask = null;
      this.$emit("close");
    },
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (sort) filter.sorter = sort;
      this.getSearch({ type: "tasks", filter }).then(({ page, total }) => {
        this.tasksSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter();
      this.search();
    },
    getTaskName(name) {
      return name.reduce((name, item, i) => {
        if (i === 4) return (name += item);
        return (name += item ? this.$ml.get(`tasks.${item}`) : " _ ") + " - ";
      }, "");
    },
  },
  watch: {
    update() {
      this.filteredTasks = [...this.tasks];
      this.selectedTask = null;
      this.instrucions = "";
      this.series = "";
      this.serieTime = "";
      this.serieRecuperation = "";
    },
    page() {
      this.search();
    },
  },
};
</script>

<style lang="sass" scoped>
.selected
  background-color: lightblue
  opacity: 0.9
</style>
