import axios from "axios";

import alerts from "../constants/alerts";

const backend = "https://api.barcelonawss.com/api/";
//const backend = "http://localhost:3000/api/";

const actions = {
  /* User Data */
  async getUserData({ commit }, email) {
    try {
      commit("loading", 1);
      const { data } = await axios.get(`${backend}users?email=${email}`);
      commit("loading", -1);
      return data;
    } catch (error) {
      commit("loading", -1);
      return error;
    }
  },

  /* Default */
  async getAll({ commit }, { type, filter }) {
    try {
      commit("loading", 1);
      const result = await axios.post(`${backend}${type}/getAll`, filter);

      commit("loading", -1);
      commit("getAll", { type, data: result.data });
      return result.data;
    } catch (error) {
      commit("loading", -1);
      return error;
    }
  },

  getList({ state, dispatch }, { type, filter }) {
    if (!state[type].length) dispatch("getAll", { type, filter });
  },

  async getSearch({ commit }, { type, filter }) {
    try {
      commit("loading", 1);
      const result = await axios.post(`${backend}${type}/search`, filter);

      commit("loading", -1);
      return result.data;
    } catch (error) {
      commit("loading", -1);
      return error;
    }
  },

  async getById({ commit }, { type, id }) {
    try {
      commit("loading", 1);
      const result = await axios.get(`${backend}${type}/${id}`);

      commit("loading", -1);
      return result.data;
    } catch (error) {
      commit("loading", -1);
      return error;
    }
  },

  async getStatistics({ commit }, id) {
    try {
      commit("loading", 1);
      const result = await axios.get(`${backend}teams/statistics/${id}`);

      commit("loading", -1);
      return result.data;
    } catch (error) {
      commit("loading", -1);
      return error;
    }
  },

  async create({ commit }, { type, createData }) {
    try {
      commit("loading", 1);
      const { data } = await axios.post(`${backend}${type}`, createData);
      commit("loading", -1);
      commit("openDetails", null);
      commit("setAlert", alerts.created);
      return data;
    } catch (error) {
      commit("loading", -1);
      commit("setAlert", alerts.default);
      return error;
    }
  },

  async update({ commit, dispatch }, { type, id, updateData, filter }) {
    try {
      if (!Object.values(updateData).length)
        return commit("setAlert", alerts.noUpdateData);

      commit("loading", 1);
      const { data } = await axios.put(`${backend}${type}/${id}`, updateData);
      commit("loading", -1);
      commit("openDetails", null);
      dispatch("getAll", { type, filter });
      commit("setAlert", alerts.updated);
      return data;
    } catch (error) {
      commit("loading", -1);
      commit("setAlert", alerts.default);
      throw new Error(error);
    }
  },

  async delete({ commit }, { type, id }) {
    try {
      commit("loading", 1);
      const { data } = await axios.delete(`${backend}${type}/${id}`);
      commit("loading", -1);
      commit("openDetails", null);
      commit("setAlert", alerts.deleted);
      return data;
    } catch (error) {
      commit("loading", -1);
      commit("setAlert", alerts.default);
      return error;
    }
  },

  //Files
  async sendFile({ commit }, { file, fileName, type, id }) {
    const formData = new FormData();
    formData.append("file", file, fileName);
    try {
      commit("loading", 1);
      const { data } = await axios.post(
        `${backend}files?type=${type}&id=${id}`,
        formData
      );
      commit("loading", -1);
      return data;
    } catch (error) {
      commit("loading", -1);
      return error;
    }
  },

  async deleteFile({ commit }, fileId) {
    try {
      commit("loading", 1);
      await axios.delete(`${backend}files/${fileId}`);
      commit("loading", -1);
    } catch (error) {
      commit("loading", -1);
      commit("setAlert", alerts.default);
      return error;
    }
  },
};

export default actions;
