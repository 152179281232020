import alerts from "./alertsCAT";

import components from "./componentsCAT";
import defaults from "./defaultCAT";

export default {
  alerts,
  ...components,
  defaults,
};
