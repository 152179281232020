export const sports = ["football", "futsal"];

export const programs = ["Futbol", "Academy", "WSPro", "Mums", "Híbrid"];
export const teamProgram = ["Academy", "Futbol", "Futsal", "Mums", "WSPro"];

export const sizes = [
  "Youth S",
  "Youth M",
  "Youth L",
  "Youth XL",
  "S",
  "M",
  "L",
  "XL",
];

export const semaphores = ["red", "orange", "green"];
export const playerSemaphores = ["Mental", "Conditional", "Game"];
export const coachSemaphores = [
  "Order",
  "PassOn",
  "SessionDesign",
  "SessionDirection",
  "MatchManagement",
  "GroupManagement",
];

export const competitions = ["league", "friendly"];
