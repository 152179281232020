<template>
  <section>
    <FiltersAndSorters
      icon="$coach"
      initial="creation"
      :title="$ml.get('coaches.title')"
      :sorters="sortersConfig"
      @search="(sort) => search(sort)"
      @filters-reset="reset"
    >
      <template slot="filters">
        <v-text-field
          :label="$ml.get('defaults.name')"
          id="filter-coaches-name"
          v-model="filters.name"
          class="mr-4"
          hide-details
          dense
          clearable
          :disabled="!$auth.user.isAdmin"
        />
        <v-autocomplete
          :label="$ml.get('defaults.teams')"
          :items="teams"
          v-model="filters.team"
          item-text="name"
          item-value="_id"
          hide-details
          dense
          multiple
          chips
          small-chips
          deletable-chips
          :disabled="!$auth.user.isAdmin"
        />
      </template>
    </FiltersAndSorters>
    <v-container>
      <v-row>
        <v-col
          class="d-flex flex-wrap justify-center"
          v-if="coachesSearch.length"
        >
          <CoachesItemList
            v-for="coach in coachesSearch"
            :key="coach._id"
            :coach="coach"
          />
        </v-col>

        <v-col v-else>
          <p class="text-h4 text-center mt-4">
            {{ $ml.get("coaches.noCoaches") }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination v-model="page" :length="pageLength"></v-pagination>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      fab
      large
      fixed
      bottom
      right
      dark
      :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
      @click="openNewDetails()"
      v-if="this.$auth.user.isAdmin"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import FiltersAndSorters from "../../components/FiltersAndSorters/FiltersAndSorters.vue";
import CoachesItemList from "../../components/CoachesItemList";

const sortersConfig = [
  {
    text: "alphabetic",
    icon: "mdi-sort-alphabetical-ascending",
    invertedIcon: "mdi-sort-alphabetical-descending",
    translation: "filter.alphabetic",
  },
  {
    text: "mainteam",
    icon: "mdi-account-group",
    translation: "teams.title",
  },
  {
    text: "creation",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "filter.creation",
  },
];

const defaultFilter = () => ({
  name: "",
  team: [],
  sorter: { filter: "creation", state: true },
});

export default {
  name: "Coaches",
  components: { FiltersAndSorters, CoachesItemList },
  data: () => ({
    coachesSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    dialog: false,
  }),
  created() {
    if (!this.teams.length && this.$auth.user.isAdmin) {
      const filter = { teams: this.$auth.user.teams };
      this.getList({ type: "teams", filter });
    }

    this.search();
  },
  methods: {
    ...mapActions(["getSearch", "getList"]),
    ...mapMutations(["openDetails"]),
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (sort) filter.sorter = sort;

      if (!this.$auth.user.isAdmin) filter.id = this.$auth.user.coach;
      this.getSearch({ type: "coaches", filter }).then(({ page, total }) => {
        this.coachesSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter();
      this.search();
    },
    openNewDetails() {
      this.openDetails({ type: "coaches", id: null });
    },
  },
  computed: {
    ...mapGetters(["teams", "details"]),
    pageLength() {
      return Math.ceil(this.total / 24);
    },
  },
  watch: {
    page() {
      this.search();
    },
    details() {
      if (!this.details) this.search();
    },
  },
};
</script>
