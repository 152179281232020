<template>
  <v-card class="card pa-6" outlined v-if="alert">
    <div class="d-flex justify-center">
      <v-icon size="150" :color="alert.type === 'error' ? 'red' : 'green'">
        mdi-{{ alert.type === "error" ? "alert-circle" : "check-circle" }}
      </v-icon>
    </div>

    <p class="text-center text-h4">{{ getText }}</p>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "AlertModal",
  methods: {
    ...mapMutations(["setAlert"]),
  },
  computed: {
    ...mapGetters(["alert"]),
    getText() {
      return this.alert.text.reduce((str, text) => {
        return `${str} ${this.$ml.get(`alerts.${text}`)}`;
      }, "");
    },
  },
};
</script>
