<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "barChart",
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.$props.chartData, {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              precision: 0,
              beginAtZero: true,
            },
          },
        ],
      },
    });
  },
};
</script>