<template>
  <v-card class="card pa-6" outlined>
    <Title text="matches.change" icon="mdi-close" @icon="$emit('close')" />
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-form ref="form">
            <v-autocomplete
              :label="$ml.get('matches.outgoing')"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-4"
              item-text="name"
              item-value="_id"
              :items="players"
              v-model="change.outPlayer"
              append-outer-icon="mdi-arrow-down-bold"
              :rules="[rules.required]"
            />
            <v-autocomplete
              :label="$ml.get('matches.entring')"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-4"
              item-text="name"
              item-value="_id"
              :items="players"
              v-model="change.newPlayer"
              append-outer-icon="mdi-arrow-up-bold"
              :rules="[rules.required]"
            />

            <v-text-field
              id="change.minute"
              hide-details
              :label="$ml.get('matches.minute')"
              type="number"
              filled
              v-model="change.minute"
              :rules="[rules.positive]"
              append-outer-icon="mdi-timer-outline"
            />
          </v-form>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mb-2">
        <v-btn large @click="addTask">
          {{ $ml.get("defaults.add") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import alerts from "../../constants/alerts";

import rules from "../../constants/rules";

import Title from "../shared/Title.vue";

export default {
  components: { Title },
  props: { update: Boolean, players: Array },
  name: "EventChanges",
  data: () => ({
    change: {},
    rules,
  }),
  methods: {
    ...mapMutations(["setAlert"]),
    addTask() {
      if (!this.$refs.form.validate()) return this.setAlert(alerts.emptyError);

      this.$emit("eventChange", { ...this.change });
      this.$refs.form.reset();
      this.$emit("close");
    },
  },
  watch: {
    update() {
      this.change = {};
    },
  },
};
</script>
