import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";

import english from "./english";
import spanish from "./spanish";
import catalan from "./catalan";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: "CAT",
  languages: [
    new MLanguage("CAT").create(catalan),
    new MLanguage("ENG").create(english),
    new MLanguage("ESP").create(spanish),
  ],
});
