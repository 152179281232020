<template>
  <section :class="`filter__border-${$vuetify.theme.dark ? 'white' : 'black'}`">
    <v-container>
      <v-row class="mx-6">
        <v-col
          cols="12"
          class="d-md-block d-flex justify-space-between flex-row"
          v-if="title || icon"
        >
          <div>
            <v-icon
              size="80"
              color="primary"
              class="icon mx-4"
              :style="{ fill: $vuetify.theme.dark ? 'white' : 'black' }"
            >
              {{ icon }}
            </v-icon>
            <span class="text-h5 text-center">{{ title }}</span>
          </div>
          <div v-if="!$vuetify.breakpoint.mdAndUp" class="d-flex flex-column">
            <span class="text-h6 text-end">
              {{ $ml.get("filter.sorters") }}
            </span>
            <div class="d-flex">
              <div v-for="sorter in sorters" :key="sorter.text" class="ml-1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        size="40"
                        :color="
                          hover || sorter.text === sorted.filter
                            ? 'WSSpink'
                            : ''
                        "
                        @click="() => onUpdateSorter(sorter.id)"
                      >
                        {{ getIcon(sorter) }}
                      </v-icon>
                    </v-hover>
                  </template>
                  <span>
                    {{ $ml.get(sorter.translation) }}
                    <v-icon v-if="sorted.filter === sorter.text">
                      mdi-arrow-{{ sorted.state ? "up" : "down" }}
                    </v-icon>
                  </span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          class="d-flex"
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'justify-space-between'
              : 'justify-end'
          "
        >
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="d-flex flex-column mt-2"
          >
            <span class="text-h6">{{ $ml.get("filter.sorters") }}</span>
            <div class="d-flex">
              <div v-for="sorter in sorters" :key="sorter.id" class="ml-1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        size="40"
                        :color="
                          hover || sorter.text === sorted.filter
                            ? 'WSSpink'
                            : ''
                        "
                        @click="() => onUpdateSorter(sorter)"
                      >
                        {{ getIcon(sorter) }}
                      </v-icon>
                    </v-hover>
                  </template>
                  <span>
                    {{ $ml.get(sorter.translation) }}
                    <v-icon v-if="sorted.filter === sorter.text">
                      mdi-arrow-{{ sorted.state ? "down" : "up" }}
                    </v-icon>

                    <v-icon v-else> mdi-arrow-down </v-icon>
                  </span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div class="d-flex flex-md-row flex-column align-self-end">
            <div class="d-flex d-md-block align-end flex-column mt-2 ml-2">
              <span class="text-h6">{{ $ml.get("filter.filters") }}</span>
              <div class="d-flex justify-end">
                <slot name="filters" />
              </div>
            </div>

            <div class="d-block d-md-flex flex-column align-self-end mt-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="32"
                      :color="hover ? 'WSSpink' : ''"
                      class="mr-2 mb-1"
                      @click="() => reset()"
                    >
                      mdi-restore
                    </v-icon>
                  </v-hover>
                </template>
                <span>
                  {{ $ml.get("defaults.reset") }}
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="35"
                      :color="hover ? 'WSSpink' : ''"
                      @click="() => search()"
                    >
                      mdi-magnify
                    </v-icon>
                  </v-hover>
                </template>
                <span>
                  {{ $ml.get("defaults.search") }}
                </span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "FiltersAndSorters",
  props: {
    icon: String,
    title: String,
    sorters: Array,
    initial: String,
  },
  data: () => ({
    sorted: {
      filter: "",
      state: true,
    },
  }),
  created() {
    this.sorted.filter = this.$props.initial;
  },
  methods: {
    reset() {
      this.sorted = {
        filter: this.$props.initial,
        state: true,
      };
      this.$emit("filters-reset");
    },
    search() {
      this.$emit("search", this.sorted);
    },
    onUpdateSorter(filter) {
      if (this.sorted.filter === filter.text)
        this.sorted.state = !this.sorted.state;
      else this.sorted = { filter: filter.text, state: true };
    },
    getIcon(sorter) {
      const { filter, state } = this.sorted;
      if (sorter.text === filter && state !== true && sorter.invertedIcon)
        return sorter.invertedIcon;
      return sorter.icon;
    },
  },
};
</script>

<style lang="sass" scoped>
.filter__border-black
  border-bottom: solid black 2px

.filter__border-white
  border-bottom: solid white 2px
</style>
