<template>
  <section>
    <FiltersAndSorters
      icon="mdi-strategy"
      initial="creation"
      :title="$ml.get('sessions.title')"
      :sorters="sortersConfig"
      @search="(sort) => search(sort)"
      @filters-reset="reset"
    >
      <template slot="filters">
        <v-autocomplete
          :label="$ml.get('defaults.teams')"
          :items="teams"
          v-model="filters.team"
          item-text="name"
          item-value="_id"
          hide-details
          multiple
          chips
          small-chips
          deletable-chips
        />
        <div class="mx-4">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateText"
                :label="$ml.get('defaults.date')"
                prepend-inner-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="filters.date" range></v-date-picker>
          </v-menu>
        </div>
        <v-autocomplete
          :label="$ml.get('tasks.creator')"
          :items="creators"
          v-model="filters.creator"
          item-text="name"
          item-value="id"
          hide-details
          multiple
          chips
          small-chips
          deletable-chips
        />
      </template>
    </FiltersAndSorters>

    <v-container fluid>
      <v-row>
        <v-col
          class="d-flex flex-wrap justify-center"
          v-if="sessionsSearch.length"
        >
          <div v-for="session in sessionsSearch" :key="session._id">
            <div class="mx-4">
              <ShowField
                v-if="session.tasks.length"
                :task="session.tasks[session.actualTask || 0]"
                @click.native="callOpenDetails(session._id)"
              />
              <ShowField
                v-else
                :task="defaultTask"
                @click.native="callOpenDetails(session._id)"
              />
              <div class="d-flex justify-center">
                <v-btn-toggle
                  v-model="session.actualTask"
                  :value="session.actualTask || 0"
                  rounded
                  v-if="session.tasks.length"
                >
                  <v-btn
                    v-for="(task, index) in session.tasks"
                    :key="task._id"
                    small
                  >
                    {{ index + 1 }}
                  </v-btn>
                </v-btn-toggle>
                <v-btn-toggle rounded v-else>
                  <v-btn small disabled> {{ $ml.get("tasks.noTasks") }} </v-btn>
                </v-btn-toggle>
              </div>
              <div class="text-center mt-2" style="max-width: 280px">
                <p class="mb-0">
                  {{ session.name[0] }} - {{ session.name[1] }} -
                  {{ session.name[2] }}
                </p>
                <p class="my-0">{{ session.date }}</p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col v-else>
          <p class="text-h4 text-center mt-4">
            {{ $ml.get("sessions.noSessions") }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination v-model="page" :length="pageLength"></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <v-btn
      fab
      large
      fixed
      bottom
      right
      dark
      :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
      @click="openNewDetails()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import FiltersAndSorters from "../../components/FiltersAndSorters/FiltersAndSorters.vue";
import ShowField from "../../components/TaskField/ShowField.vue";

const sortersConfig = [
  {
    text: "session",
    icon: "mdi-sort-numeric-ascending",
    invertedIcon: "mdi-sort-numeric-descending",
    translation: "sessions.session",
  },
  {
    text: "creation",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "filter.creation",
  },
];

const defaultTask = {
  taskField: {
    field: "white",
    texts: [],
    players: [],
    lines: [],
    squares: [],
    icons: {
      circles: [],
      balls: [],
      cones: [],
      tinyCones: [],
      goals: [],
      picks: [],
      fences: [],
    },
  },
};

const defaultFilter = () => ({
  team: [],
  date: [],
  creator: [],
  sorter: { filter: "creation", state: true },
});

export default {
  name: "Sessions",
  components: { FiltersAndSorters, ShowField },
  data: () => ({
    sessionsSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    defaultTask,
    dialog: false,
  }),
  methods: {
    ...mapActions(["getSearch", "getList"]),
    ...mapMutations(["openDetails"]),
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (sort) filter.sorter = sort;
      this.getSearch({ type: "sessions", filter }).then(({ page, total }) => {
        this.sessionsSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter();
      this.search();
    },
    callOpenDetails(id) {
      this.openDetails({ type: "sessions", id });
    },
    openNewDetails() {
      this.openDetails({ type: "sessions", id: null });
    },
  },
  computed: {
    ...mapGetters(["sessions", "teams", "details"]),
    pageLength() {
      return Math.ceil(this.total / 24);
    },
    creators() {
      const creators = new Set();
      this.sessions.map((sessions) => creators.add(sessions.creator));
      return [...creators].map((creator) => ({
        id: creator,
        name: creator.split("@")[0],
      }));
    },
    dateText() {
      if (this.filters.date.length !== 2) return "";
      else return `${this.filters.date[0]} | ${this.filters.date[1]}`;
    },
  },
  watch: {
    page() {
      this.search();
    },
    details() {
      if (!this.details) this.search();
    },
  },
  created() {
    if (!this.teams.length) {
      const filter = { teams: this.$auth.user.teams };
      this.getList({ type: "teams", filter });
    }
    if (!this.sessions.length) this.getList({ type: "sessions" });

    this.search();
  },
};
</script>
