<template>
  <v-card class="card pa-6" outlined>
    <div class="d-flex justify-center">
      <v-icon size="150" color="yellow"> mdi-alert </v-icon>
    </div>

    <p class="text-center text-h4">
      {{ $ml.get("alerts.deleteconfirmation") }}
    </p>
    <div class="d-flex justify-space-around">
      <v-btn @click="$emit('no')">NO</v-btn>
      <v-btn @click="$emit('yes')">YES</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DeleteConfirmation",
};
</script>
