<template>
  <svg viewBox="0 0 83.75 83.75">
    <g>
      <path
        d="M8.51,36.81a1.66,1.66,0,0,0-1.16.47,1.64,1.64,0,0,0,0,2.32,1.67,1.67,0,0,0,1.16.48,1.64,1.64,0,1,0,0-3.27Z"
      />
      <path
        d="M28.46,17.5a7.72,7.72,0,0,0,7.71-7.7V7.7a7.71,7.71,0,0,0-15.41,0V9.8A7.71,7.71,0,0,0,28.46,17.5ZM24,7.7a4.44,4.44,0,0,1,8.87,0V9.8A4.44,4.44,0,0,1,24,9.8V7.7Z"
      />
      <path
        d="M55.29,17.5A7.71,7.71,0,0,0,63,9.8V7.7a7.7,7.7,0,0,0-15.4,0V9.8A7.71,7.71,0,0,0,55.29,17.5ZM50.86,7.7a4.43,4.43,0,1,1,8.86,0V9.8a4.43,4.43,0,1,1-8.86,0Z"
      />
      <path
        d="M75,28.29a7.65,7.65,0,0,0,1.43-4.46V21.74a7.71,7.71,0,1,0-15.41,0v2.09a7.65,7.65,0,0,0,1.43,4.46,9.47,9.47,0,0,0-7.14,4.14,9.47,9.47,0,0,0-7.14-4.14,7.65,7.65,0,0,0,1.43-4.46V21.74a7.71,7.71,0,1,0-15.41,0v2.09a7.65,7.65,0,0,0,1.43,4.46,9.49,9.49,0,0,0-7.14,4.14,9.47,9.47,0,0,0-7.14-4.14,7.65,7.65,0,0,0,1.43-4.46V21.74a7.71,7.71,0,1,0-15.41,0v2.09a7.72,7.72,0,0,0,1.43,4.46A9.5,9.5,0,0,0,0,37.75V49.18a8.53,8.53,0,0,0,6.87,8.35V82.12a1.63,1.63,0,0,0,1.64,1.63H21.59a1.63,1.63,0,0,0,1.64-1.63V57.53a8.5,8.5,0,0,0,5.23-3.34,8.53,8.53,0,0,0,5.24,3.34V82.12a1.63,1.63,0,0,0,1.63,1.63H48.42a1.64,1.64,0,0,0,1.64-1.63V57.53a8.53,8.53,0,0,0,5.23-3.34,8.5,8.5,0,0,0,5.23,3.34V82.12a1.64,1.64,0,0,0,1.64,1.63H75.25a1.63,1.63,0,0,0,1.63-1.63V73.77a1.64,1.64,0,0,0-3.27,0v6.71H70.34V60.69a1.64,1.64,0,1,0-3.27,0V80.48H63.8V54.1h9.81v6.59a1.64,1.64,0,0,0,3.27,0V57.53a8.53,8.53,0,0,0,6.87-8.35V37.75A9.49,9.49,0,0,0,75,28.29ZM64.27,21.74a4.44,4.44,0,1,1,8.87,0v2.09a4.44,4.44,0,0,1-8.87,0Zm-26.83,0a4.44,4.44,0,1,1,8.87,0v2.09a4.44,4.44,0,0,1-8.87,0V21.74Zm-26.82,0a4.43,4.43,0,1,1,8.86,0v2.09a4.43,4.43,0,1,1-8.86,0ZM20,80.48H16.69V60.69a1.64,1.64,0,0,0-3.28,0V80.48H10.14V54.1H20Zm3.27-26.33V38.44a1.64,1.64,0,0,0-3.27,0V50.82H10.14V44.33a1.64,1.64,0,1,0-3.27,0v9.82a5.25,5.25,0,0,1-3.6-5V37.75a6.23,6.23,0,0,1,6.22-6.22H20.61a6.23,6.23,0,0,1,6.22,6.22V49.18A5.25,5.25,0,0,1,23.23,54.15ZM46.78,80.48H43.51V60.69a1.64,1.64,0,1,0-3.27,0V80.48H37V54.1h9.81Zm3.28-26.33V38.44a1.64,1.64,0,0,0-3.28,0V50.82H37V38.44a1.64,1.64,0,0,0-3.27,0V54.15a5.25,5.25,0,0,1-3.6-5V37.75a6.22,6.22,0,0,1,6.21-6.22H47.44a6.22,6.22,0,0,1,6.21,6.22V49.18A5.25,5.25,0,0,1,50.06,54.15Zm30.42-5a5.25,5.25,0,0,1-3.6,5V38.44a1.64,1.64,0,0,0-3.27,0V50.82H63.8V38.44a1.64,1.64,0,0,0-3.28,0V54.15a5.25,5.25,0,0,1-3.59-5V37.75a6.22,6.22,0,0,1,6.21-6.22H74.26a6.23,6.23,0,0,1,6.22,6.22Z"
      />
      <path
        d="M75.25,65.65a1.67,1.67,0,0,0-1.16.48,1.69,1.69,0,0,0-.48,1.16,1.64,1.64,0,0,0,.48,1.15,1.63,1.63,0,0,0,1.16.48,1.64,1.64,0,0,0,1.63-1.63,1.64,1.64,0,0,0-1.63-1.64Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Team",
};
</script>
