export default {
  sport: "Deporte",
  football: "Fútbol",
  futsal: "Futsal",

  /////////////////////////////

  players: "Deportistas",
  coaches: "Personal técnico",
  teams: "Equipos",
  tasks: "Tareas",
  sessions: "Sesiones",
  statistics: "Estadísticas",
  matches: "Partidos",
  tracking: "Seguimiento",
  accounting: "Contabilidad",

  ////////////////////////////

  name: "Nombre",
  fullName: "Nombre completo",
  surename: "Apellidos",
  birth: "Fecha de nacimiento",
  address: "Dirección",
  population: "Población",
  postalCode: "Código postal",
  phone: "Teléfono",
  email: "Correo electrónico",
  father: "Tutor 2",
  mother: "Tutor 1",
  documents: "Documentos",
  personalData: "Datos personales",
  contactData: "Datos de contacto",
  size: "Talla de ropa",
  reports: "Informes",
  password: "Contraseña",
  admin: "Administrador",
  date: "Fecha",
  program: "Programa",
  fileName: "Nombre del archivo",

  //////////////////////////

  create: "Crear",
  add: "Añadir",
  update: "Actualizar",
  delete: "Eliminar",
  search: "Buscar",
  reset: "Reiniciar",

  /////////////////////

  red: "Rojo",
  orange: "Naranja",
  green: "Verde",
};
