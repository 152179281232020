<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="sass" scoped>
.home
  background: url("../assets/home.jpeg") no-repeat center center fixed
  background-size: cover
  -webkit-background-size: cover
  -moz-background-size: cover
  -o-background-size: cover
  height: 100%
</style>
