<template>
  <v-app class="app">
    <Header :loaded="loaded" />
    <v-main v-if="loaded">
      <router-view />
    </v-main>

    <v-overlay :value="loading !== 0 || $auth.loading || !loaded" z-index="300">
      <v-progress-circular size="64" indeterminate color="WSSpink" />
    </v-overlay>

    <v-dialog
      v-model="dialog"
      width="700"
      @click:outside="() => setAlert(null)"
    >
      <AlertModal />
    </v-dialog>

    <v-dialog v-model="detailsDialog" scrollable fullscreen>
      <div v-if="details" class="details">
        <PlayerDetail v-if="details.type === 'players'" />
        <CoachDetail v-else-if="details.type === 'coaches'" />
        <TeamDetail v-else-if="details.type === 'teams'" />
        <TaskDetail v-else-if="details.type === 'tasks'" />
        <SessionDetail v-else-if="details.type === 'sessions'" />
        <MatchDetail v-else-if="details.type === 'matches'" />
        <TrackingDetail v-else-if="details.type === 'trackings'" />
        <AccountingDetail v-else-if="details.type === 'accounting'" />
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";

import { getInstance } from "./auth";

import Header from "./components/Header/Header.vue";
import AlertModal from "./components/Modals/AlertModal";
import PlayerDetail from "./views/Players/PlayerDetail.vue";
import CoachDetail from "./views/Coaches/CoachDetail.vue";
import TeamDetail from "./views/Teams/TeamDetail.vue";
import TaskDetail from "./views/Tasks/TaskDetail.vue";
import SessionDetail from "./views/Sessions/SessionDetail.vue";
import MatchDetail from "./views/Matches/MatchDetail.vue";
import TrackingDetail from "./views/Tracking/TrackingDetail.vue";
import AccountingDetail from "./views/Accounting/AccountingDetail.vue";

export default {
  name: "App",
  components: {
    Header,
    AlertModal,
    PlayerDetail,
    CoachDetail,
    TeamDetail,
    TaskDetail,
    SessionDetail,
    MatchDetail,
    TrackingDetail,
    AccountingDetail,
  },
  data: () => ({
    dialog: false,
    token: null,
    loaded: false,

    detailsDialog: false,
  }),
  computed: {
    ...mapGetters(["coaches", "alert", "loading", "details"]),
  },
  methods: {
    ...mapActions(["getUserData"]),
    ...mapMutations(["setAlert", "openDetails"]),
  },
  watch: {
    alert() {
      this.dialog = true;
    },
    details() {
      this.detailsDialog = !!this.details;
    },
    async token() {
      axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;

      try {
        const result = await this.getUserData(this.$auth.user.email);

        if (result) {
          this.$auth.user.isAdmin = result.isAdmin;
          this.$auth.user.coach = result.coach;
          this.$auth.user.teams = result.teams;
        }
        this.loaded = true;
      } catch (err) {
        this.loaded = false;
      }
    },
  },
  mounted() {
    const instance = getInstance();
    instance.$watch("loading", async (loading) => {
      if (!loading && instance.isAuthenticated) {
        const token = await instance.getTokenSilently();
        this.token = token;
      }
    });
  },
};
</script>

<style lang="sass">
a
  text-decoration: none

.theme--light.v-icon:focus::after
  opacity: 0

.theme--dark.v-icon:focus::after
  opacity: 0

.details
  min-height: 100%
  .v-card:first-child
    min-height: 100%
</style>
