<template>
  <svg viewBox="0 0 68.17 75.94">
    <defs>
      <clipPath id="clip-path" transform="translate(0 0)">
        <rect class="cls-1" width="68.16" height="75.98" />
      </clipPath>
    </defs>
    <g>
      <g class="cls-2">
        <path
          d="M68.17,57.1a9.12,9.12,0,0,0-6.76-8.8L49,45a3.86,3.86,0,0,0-1.69-2.81l-2-1.34a1.24,1.24,0,0,0-.76-.21V39.5a17,17,0,0,0,6.57-13.42V21.92l.52.1a1.16,1.16,0,0,0,1.19-.37,3.2,3.2,0,0,0,.92-2.14,3.48,3.48,0,0,0-1.56-2.76l-.82-9a5.18,5.18,0,0,0-3.9-4.55l-12.1-3a5.33,5.33,0,0,0-2.51,0l-12.1,3a5.17,5.17,0,0,0-3.89,4.55l-.82,9a3.48,3.48,0,0,0-1.56,2.76,3.2,3.2,0,0,0,.92,2.14,1.23,1.23,0,0,0,1.19.37l.51-.1v4.16A17,17,0,0,0,23.66,39.5v1.12a1.25,1.25,0,0,0-.77.21l-2,1.34A3.84,3.84,0,0,0,19.18,45L6.76,48.3A9.12,9.12,0,0,0,0,57.1v5.57a5.2,5.2,0,0,0,1.31,3.44v4.65a5.19,5.19,0,0,0,5.18,5.18h55.2a5.18,5.18,0,0,0,5.17-5.18V66.11a5.15,5.15,0,0,0,1.31-3.44ZM44.76,43.44l1.17.78A1.4,1.4,0,0,1,46.41,46l-4.8,9.6A24.74,24.74,0,0,0,36.25,52ZM18.27,19.23l.09-1.07A14.5,14.5,0,0,1,21.42,17a47.78,47.78,0,0,1,12.67-1.5A47.89,47.89,0,0,1,46.76,17a14.15,14.15,0,0,1,3.05,1.18l.1,1.07a110.75,110.75,0,0,0-15.82-.95,110.51,110.51,0,0,0-15.82.95ZM19.29,8a2.72,2.72,0,0,1,2-2.38l12.1-3a2.94,2.94,0,0,1,1.32,0l12.09,3a2.71,2.71,0,0,1,2,2.38l.67,7.37c-.63-.25-1.34-.49-2.11-.71A49.39,49.39,0,0,0,35.32,13V6.37a1.23,1.23,0,1,0-2.46,0V13a49.33,49.33,0,0,0-12.12,1.58c-.78.22-1.48.46-2.12.71Zm.26,18.13V21.54a115.55,115.55,0,0,1,14.54-.8,115.55,115.55,0,0,1,14.54.8v4.54a14.54,14.54,0,0,1-29.08,0Zm14.54,17a17,17,0,0,0,8-2v1.55l-8,8-8-8V41.1a17,17,0,0,0,8,2ZM22.24,44.22l1.18-.78L31.93,52a24.74,24.74,0,0,0-5.36,3.65L21.77,46a1.4,1.4,0,0,1,.47-1.78ZM3.78,70.76V67.65l8.05,2.69v3.13H6.49a2.71,2.71,0,0,1-2.71-2.71Zm57.91,2.71H56.35V70.34l8.05-2.69v3.11a2.71,2.71,0,0,1-2.71,2.71Z"
          transform="translate(0 0)"
        />
      </g>
      <ellipse cx="33.72" cy="32.72" rx="3.87" ry="5.01" />
      <path
        d="M29.58,21.37a3,3,0,0,1-5.88,0,3,3,0,0,1,5.88,0Z"
        transform="translate(0 0)"
      />
      <path
        d="M44.28,21.37a3,3,0,0,1-5.88,0,3,3,0,0,1,5.88,0Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PlayerIcon",
};
</script>

<style>
.cls-1 {
  fill: none;
}
.cls-2 {
  clip-path: url(#clip-path);
}
</style>
