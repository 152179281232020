<template>
  <v-file-input
    @change="(file) => $emit('change', file)"
    filled
    hide-details
    class="mb-4"
    append-icon="$file"
    prepend-icon=""
    enctype="multipart/form-data"
    name="file"
  />
</template>

<script>
export default {
  name: "FileUpload",
};
</script>