export default {
  created: "Created",
  deleted: "Deleted",
  updated: "Modified",

  correctly: "correctly",

  error: "An error occurred",
  empty: "Fill all details",
  userNotFound: "User not found",
  defaultError: "An unexpected error has occurred",
  wrongPassword: "Wrong password",
  noUpdateData: "No details modified",

  deleteconfirmation: "Are you sure you want to delete it?",
  closeconfirmation: "You have unsaved changes Are you sure to quit?",
  matchPassword: "Passwords didn't match",
  invalidExtension: "The file extension not correct",
};
