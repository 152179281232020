<template>
  <v-container>
    <v-row>
      <v-col>
        <Title text="defaults.statistics" />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" cols="12">
        <v-autocomplete
          filled
          hide-details
          clearable
          v-model="teamId"
          :label="$ml.get('teams.title')"
          :items="teams"
          item-text="name"
          item-value="_id"
          menu-props="offset-y"
          class="mb-4"
        />
      </v-col>
      <v-col md="9" cols="12" v-if="selectedTeam">
        <BarChart :chartData="goalsData" />
        <BarChart :chartData="sessionsData" />
        <BarChart :chartData="matchesData" />
        <BarChart :chartData="minuteData" />
      </v-col>
      <v-col v-else md="9" cols="12">
        <p class="text-h4 text-center">
          {{ $ml.get("statistics.noTeam") }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Title from "../../components/shared/Title.vue";

import BarChart from "../../components/Statistics/BarChart.vue";

export default {
  name: "Statistics",
  components: { Title, BarChart },
  data: () => ({
    teamId: null,
    selectedTeam: null,
    matches: [],
    sessions: [],
  }),
  watch: {
    teamId() {
      if (this.teamId) {
        this.selectedTeam = null;
        this.getTeam();
      }
    },
  },
  methods: {
    ...mapActions(["getStatistics", "getList"]),
    async getTeam() {
      const { team, sessions, matches } = await this.getStatistics(this.teamId);
      this.selectedTeam = team;
      this.sessions = sessions;
      this.matches = matches;
    },
  },
  computed: {
    ...mapGetters(["teams", "players"]),
    teamPlayers() {
      return this.players
        .filter((player) =>
          this.selectedTeam.players.some((playerId) => playerId === player._id)
        )
        .map((player) => player.name);
    },
    playersObj() {
      return this.selectedTeam.players.reduce(
        (acc, curr) => ((acc[curr] = 0), acc),
        {}
      );
    },
    playersGoals() {
      const playersGoals = this.matches.reduce(
        (players, match) => {
          match.goals.forEach(({ player }) => player && players[player]++);

          return players;
        },
        { ...this.playersObj }
      );

      return Object.values(playersGoals);
    },
    playersSessions() {
      const playersGoals = this.sessions.reduce(
        (players, session) => {
          session.assistance.forEach((player) => players[player]++);

          return players;
        },
        { ...this.playersObj }
      );

      return Object.values(playersGoals);
    },
    playersMatches() {
      const playersMatches = this.matches.reduce(
        (players, match) => {
          match.summoned.forEach((playerId) => players[playerId]++);

          return players;
        },
        { ...this.playersObj }
      );

      return Object.values(playersMatches);
    },
    playersMinutes() {
      const playersMatches = this.matches.reduce(
        (players, match) => {
          const sortedChanges = match.changes.sort(
            (changeA, changeB) => changeA.minute - changeB.minute
          );

          let actualMinute = 0;
          let matchPlayers = [...match.lineUp];
          sortedChanges.forEach((change) => {
            matchPlayers.forEach(
              (player) => (players[player] += change.minute - actualMinute)
            );

            actualMinute = change.minute;
            matchPlayers = matchPlayers.filter(
              (playerId) => playerId !== change.outPlayer
            );
            matchPlayers.push(change.newPlayer);
          });

          matchPlayers.forEach(
            (player) =>
              (players[player] += this.selectedTeam.matchTime - actualMinute)
          );

          return players;
        },
        { ...this.playersObj }
      );

      return Object.values(playersMatches);
    },
    goalsData() {
      return {
        labels: this.teamPlayers,
        datasets: [
          {
            label: this.$ml.get("statistics.goals"),
            data: this.playersGoals,
            backgroundColor: "#EF2AC1",
            maxBarThickness: 50,
          },
        ],
      };
    },
    sessionsData() {
      return {
        labels: this.teamPlayers,
        datasets: [
          {
            label: this.$ml.get("statistics.attendance"),
            data: this.playersSessions,
            backgroundColor: "#EF2AC1",
            maxBarThickness: 50,
          },
        ],
      };
    },
    matchesData() {
      return {
        labels: this.teamPlayers,
        datasets: [
          {
            label: this.$ml.get("defaults.matches"),
            data: this.playersMatches,
            backgroundColor: "#EF2AC1",
            maxBarThickness: 50,
          },
        ],
      };
    },
    minuteData() {
      return {
        labels: this.teamPlayers,
        datasets: [
          {
            label: this.$ml.get("statistics.matchMinutes"),
            data: this.playersMinutes,
            backgroundColor: "#EF2AC1",
            maxBarThickness: 50,
          },
        ],
      };
    },
  },
  created() {
    const filter = { teams: this.$auth.user.teams };
    if (!this.players.length) this.getList({ type: "players", filter });
    if (!this.teams.length) this.getList({ type: "teams", filter });
  },
};
</script>
