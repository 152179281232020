import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";

import Players from "../views/Players/Players.vue";
import Coaches from "../views/Coaches/Coaches.vue";
import Teams from "../views/Teams/Teams.vue";
import Tasks from "../views/Tasks/Tasks.vue";
import Sessions from "../views/Sessions/Sessions.vue";
import Matches from "../views/Matches/Matches.vue";
import Statistics from "../views/Statistics/Statistics.vue";
import Tracking from "../views/Tracking/Tracking.vue";

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/players",
    name: "Players",
    component: Players,
  },
  {
    path: "/coaches",
    name: "Coaches",
    component: Coaches,
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
  },
  {
    path: "/matches",
    name: "Matches",
    component: Matches,
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
  },
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
  },
  {
    path: "/accounting",
    name: "Accounting",
    component: Players,
  },
];

const secureRoutes = routes.map((route) => ({
  ...route,
  beforeEnter: authGuard,
}));

const router = new VueRouter({
  routes: secureRoutes,
});

export default router;
