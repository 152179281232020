<template>
  <svg viewBox="0 0 84.88 126.37">
    <path
      d="M53.29,119.12H33A1.3,1.3,0,0,1,32,117l4.6-5.34,4.22-4.87V7.64A2.19,2.19,0,0,1,43,5.47a2.17,2.17,0,0,1,2.17,2.17v98.87l4.45,5.1L54.26,117A1.31,1.31,0,0,1,53.29,119.12Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Pitch",
};
</script>
