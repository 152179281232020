export default {
  created: "Creat",
  deleted: "Eliminat",
  updated: "Modificat",

  correctly: "correctament",

  error: "S'ha produït un error",
  empty: "Omple tota la informació",
  userNotFound: "No s'ha trobat l'usuari",
  defaultError: "Ha sorgit un error inesperat",
  wrongPassword: "La contrasenya és incorrecta",
  noUpdateData: "No s'ha modificat cap dada",

  deleteconfirmation: "Estas segur que ho vols eliminar?",
  closeconfirmation: "Tens canvis sense guardar Segur que vols sortir?",
  matchPassword: "Les contrasenyes no coincideixen",
  invalidExtension: "L'extensió d'arxiu no és la correcta",
};
