var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playerIcon",style:({
    width: (_vm.size + "px"),
    height: ((_vm.size / 2) + "px"),
    'border-top-left-radius': ((_vm.size + 2) + "px"),
    'border-top-right-radius': ((_vm.size + 2) + "px"),
    border: ("solid " + _vm.borderColor + " " + (_vm.size > 30 ? 2 : 1) + "px"),
  })},[_c('div',{staticClass:"playerIcon__color",style:({
      'background-color': _vm.color,
      border: ("solid " + _vm.borderColor + " " + (_vm.size > 30 ? 2 : 1) + "px"),
      width: ((_vm.size / 2) + "px"),
      height: ((_vm.size / 2) + "px"),
      'margin-left': ((_vm.size / 5 + _vm.size / 50) + "px"),
      'margin-top': ("-" + (_vm.size > 30 ? 2 : 1) + "px"),
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }