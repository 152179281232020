<template>
  <v-card class="card pa-6" outlined>
    <Title text="matches.yellowCard" icon="mdi-close" @icon="$emit('close')" />
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-form ref="form">
            <v-autocomplete
              :label="$ml.get('players.title')"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-4"
              item-text="name"
              item-value="_id"
              :items="players"
              v-model="yellowCard.player"
              :rules="[rules.required]"
            />

            <text-field
              id="yellowCard.minute"
              label="matches.minute"
              type="number"
              v-model="yellowCard.minute"
              :rules="[rules.positive]"
            />
          </v-form>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mb-2">
        <v-btn large @click="addTask">
          {{ $ml.get("defaults.add") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import alerts from "../../constants/alerts";

import rules from "../../constants/rules";

import Title from "../shared/Title.vue";
import TextField from "../shared/text-field.vue";

export default {
  components: { Title, TextField },
  props: { update: Boolean, players: Array },
  name: "EventsYellowCard",
  data: () => ({
    yellowCard: {},
    rules,
  }),
  methods: {
    ...mapMutations(["setAlert"]),
    addTask() {
      if (!this.$refs.form.validate()) return this.setAlert(alerts.emptyError);

      this.$emit("eventYellowCards", { ...this.yellowCard });
      this.$refs.form.reset();
      this.$emit("close");
    },
  },
  watch: {
    update() {
      this.yellowCard = {};
    },
  },
};
</script>
