import Vue from "vue";

const mutations = {
  loading: (state, number) => (state.loading += number),

  //Alert
  setAlert: (state, alertData) => (state.alert = alertData),

  openDetails: (state, detailsData) => (state.details = detailsData),
  getAll: (state, { type, data }) => (state[type] = data),

  addFile: (state, { file, type, id, isAvatar }) => {
    const actual = state[type].find(({ _id }) => _id === id);
    if (!isAvatar) actual.files.push(file);
    else Vue.set(actual, "avatar", file);
  },
};

export default mutations;
