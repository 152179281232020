export default {
  created: {
    type: "success",
    text: ["created", "correctly"],
  },
  updated: {
    type: "success",
    text: ["updated", "correctly"],
  },
  deleted: {
    type: "success",
    text: ["deleted", "correctly"],
  },

  emptyError: {
    type: "error",
    text: ["empty"],
  },
  userNotFound: {
    type: "error",
    text: ["userNotFound"],
  },
  wrongPassword: {
    type: "error",
    text: ["wrongPassword"],
  },
  default: {
    type: "error",
    text: ["defaultError"],
  },
  noUpdateData: {
    type: "error",
    text: ["noUpdateData"],
  },
  matchPassword: {
    type: "error",
    text: ["matchPassword"],
  },
  invalidExtension: {
    type: "error",
    text: ["invalidExtension"],
  },
};
