var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","absolute":"","color":"WSSblue"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/WSS-escut.png"),"alt":"Logo","width":"50px","height":"50"}})])],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"8"}},[_vm._l((_vm.menuIcons),function(icon){return _c('div',{key:icon.icon,staticClass:"d-none d-md-flex"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{attrs:{"to":("/" + (icon.url))}},[_c('v-tooltip',{attrs:{"bottom":"","color":"WSSblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon mx-3",style:({
                      fill:
                        hover || _vm.route.url === icon.url ? '#EF2AC1' : 'white',
                    }),attrs:{"color":hover || _vm.route.url === icon.url ? 'WSSpink' : 'white',"size":icon.icon.includes('$') ? 32 : 40}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(icon.icon)+" ")])]}}],null,true)},[_c('b',{staticClass:"WSSpink--text"},[_vm._v(" "+_vm._s(_vm.$ml.get(("defaults." + (icon.url))))+" ")])])],1)]}}],null,true)})],1)}),_c('div',{staticClass:"d-flex d-md-none white--text mr-4"},[_c('v-menu',{attrs:{"offset-y":"","color":"WSSblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',{style:({
                    fill: _vm.$vuetify.theme.dark ? 'white' : 'black',
                  })},[_vm._v(" "+_vm._s(_vm.route.icon)+" ")])],1)]}}])},[_c('v-list',{attrs:{"color":"WSSblue"}},_vm._l((_vm.menuIcons),function(menuItem){return _c('v-list-item',{key:menuItem.url},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('router-link',{attrs:{"to":("/" + (menuItem.url))}},[_c('v-icon',{staticClass:"mr-2",style:({
                        fill:
                          hover || _vm.route.url === menuItem.url
                            ? '#EF2AC1'
                            : 'white',
                      }),attrs:{"color":hover || _vm.route.url === menuItem.url
                          ? 'WSSpink'
                          : 'white'}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")]),_c('b',{class:((hover || _vm.route.url === menuItem.url
                          ? 'WSSpink'
                          : 'white') + "--text")},[_vm._v(" "+_vm._s(_vm.$ml.get(("defaults." + (menuItem.url))))+" ")])],1)]}}],null,true)})],1)}),1)],1)],1)],2),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"WSSblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","dark":"","color":hover ? 'WSSpink' : ''},on:{"click":_vm.onUpdateTheme}},'v-icon',attrs,false),on),[_vm._v(" mdi-brightness-6 ")])]}}],null,true)})]}}])},[_c('b',{staticClass:"WSSpink--text"},[_vm._v(_vm._s(_vm.$ml.get("header.theme")))])]),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":hover ? 'WSSpink' : 'white',"large":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-translate ")])]}}],null,true)})]}}])},[_c('v-list',{attrs:{"color":"WSSblue"}},_vm._l((_vm.langs),function(lang){return _c('p',{key:lang.id,staticClass:"mx-4",on:{"click":function () { return _vm.onUpdateLang(lang.id); }}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('b',{class:hover || _vm.$ml.current === lang.id
                      ? 'WSSpink--text'
                      : 'white--text'},[_vm._v(" "+_vm._s(lang.text)+" ")])]}}],null,true)})],1)}),0)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"WSSblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":hover ? 'WSSpink' : 'white',"large":""},on:{"click":_vm.onLogout}},'v-icon',attrs,false),on),[_vm._v(" mdi-logout ")])]}}],null,true)})]}}])},[_c('b',{staticClass:"WSSpink--text"},[_vm._v(_vm._s(_vm.$ml.get("user.logout")))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }