<template>
  <section v-if="fieldData" class="container">
    <div class="field">
      <Player
        v-for="(player, index) in fieldData.players"
        :key="`Player ${index}`"
        :color="player.color"
        :size="player.size / 4 || 10"
        class="icon"
        :style="playersStyle[index]"
      />

      <div
        v-for="[type, iconsList] in Object.entries(fieldData.icons)"
        :key="type"
      >
        <v-icon
          v-for="(icon, index) in iconsList"
          :key="`${type} ${index}`"
          class="icon"
          :style="iconsStyle[type][index]"
          :color="icon.color || 'black'"
          :size="icon.size / 4 || 10"
        >
          {{ icons[type] }}
        </v-icon>
      </div>

      <span
        v-for="(item, index) in fieldData.texts"
        :key="`Text ${index}`"
        class="text"
        :style="textsStyle[index]"
      >
        {{ item.text }}
      </span>

      <div
        v-for="(styles, index) in linesStyle"
        :key="`Line ${index}`"
        :style="styles"
        class="line"
      />

      <div
        v-for="(styles, index) in squaresStyle"
        :key="`Square ${index}`"
        class="square"
        :style="styles"
        style="opacity: 0.9"
      />

      <img
        v-if="fieldData.field !== 'white'"
        :src="taskFieldBackground"
        height="150"
        contain
      />

      <div v-else style="width: 210px; height: 150px" />
    </div>
  </section>
</template>

<script>
import Player from "./Player.vue";

import football from "../../assets/futbol.jpeg";
import futsal from "../../assets/futsal.jpeg";
import half_football from "../../assets/half_futbol.jpeg";
import half_futsal from "../../assets/half_futsal.jpeg";

const fieldBackground = { football, futsal, half_football, half_futsal };

const icons = {
  balls: "mdi-soccer",
  tinyCones: "$tinyCone",
  cones: "$cone",
  goals: "$goal",
  picks: "$pitch",
  fences: "$fence",
  circles: "mdi-circle-outline",
  texts: "mdi-text-recognition",
};

export default {
  name: "ShowField",
  components: { Player },
  props: { task: Object },
  methods: {
    getDefaultStyles(item) {
      let transform = "";

      if (item.angle) transform = `rotate(${item.angle}deg)`;

      return {
        left: `${item.x}%`,
        top: `${item.y}%`,
        transform: transform,
        "-webkit-transform": transform /* WebKit */,
        "-moz-transform": transform /* Mozilla */,
        "-o-transform": transform /* Opera */,
        "-ms-transform": transform /* IE */,
      };
    },
  },
  computed: {
    fieldData() {
      return this.task.taskField;
    },
    icons() {
      return icons;
    },
    playersStyle() {
      return this.fieldData.players.map((player) =>
        this.getDefaultStyles(player)
      );
    },
    iconsStyle() {
      const entries = Object.entries(this.fieldData.icons);
      return entries.reduce((acc, [key, value]) => {
        const icons = value.map((icon) => ({
          ...this.getDefaultStyles(icon),
          fill: icon.color || "black",
        }));

        return { ...acc, [key]: icons };
      }, {});
    },
    textsStyle() {
      return this.fieldData.texts.map((text) => ({
        ...this.getDefaultStyles(text),
        "font-size": `${text.size / 4 || 4.5}px`,
        color: text.color || "black",
      }));
    },
    linesStyle() {
      return this.fieldData.lines.map((line) => {
        const transform = `rotate(${line.angle}rad)`;

        return {
          left: `${line.x}%`,
          top: `${line.y}%`,
          width: `${line.length / 4}px`,
          transform: transform,
          "-webkit-transform": transform /* WebKit */,
          "-moz-transform": transform /* Mozilla */,
          "-o-transform": transform /* Opera */,
          "-ms-transform": transform /* IE */,
          "border-top": `1px ${line.dashed ? "dashed" : "solid"} ${
            line.color || "black"
          }`,
        };
      });
    },
    squaresStyle() {
      return this.fieldData.squares?.map((square) => {
        const color =
          !square.color || square.color === "black" ? "white" : square.color;

        return {
          left: `${square.left}%`,
          top: `${square.top}%`,
          right: `${square.right}%`,
          bottom: `${square.bottom}%`,
          "background-color": color,
          border: `1px solid ${square.borderColor || "black"}`,
        };
      });
    },
    taskFieldBackground() {
      return fieldBackground[this.fieldData.field];
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  width: 250px
  display: flex
  justify-content: center

.field
  border: solid 2px black
  position: relative
  background-color: white
  height: 170px
  width: 226px
  overflow: hidden
  padding: 12px

.text
  position: absolute
  color: black
  z-index: 6

.icon
  position: absolute
  z-index: 5

.line
  position: absolute
  z-index: 4

.square
  position: absolute
  z-index: 3
</style>
