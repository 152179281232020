<template>
  <v-card>
    <v-container v-if="savedPlayer">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="4">
            <Avatar
              :src="fileUrl || (player.avatar && player.avatar.route)"
              class="mb-4"
            />
            <FileUpload @change="onSelectAvatar" />
            <text-field
              id="player.email"
              label="defaults.email"
              v-model="player.email"
              icon="mdi-email"
            />
            <text-field
              id="player.phone"
              label="defaults.phone"
              v-model="player.phone"
              icon="mdi-phone"
            />
            <text-field
              id="player.instagram"
              label="Instagram"
              v-model="player.instagram"
              icon="mdi-instagram"
            />
            <text-field
              id="player.twitter"
              label="Twitter"
              v-model="player.twitter"
              icon="mdi-twitter"
            />
            <text-field
              id="player.tiktok"
              label="TikTok"
              v-model="player.tiktok"
              icon="mdi-music-note-eighth"
              class="mb-1"
            />
            <Title text="defaults.teams" />
            <v-autocomplete
              :label="$ml.get('defaults.teams')"
              v-model="player.teams"
              :items="teams"
              multiple
              hide-details
              filled
              chips
              deletable-chips
              menu-props="offset-y"
              item-text="name"
              item-value="_id"
              class="mb-2"
            />
            <v-autocomplete
              label="Equip principal"
              v-model="player.mainTeam"
              :items="playerTeams"
              hide-details
              filled
              clearable
              menu-props="offset-y"
              item-text="name"
              item-value="_id"
              class="mb-2"
            />
          </v-col>
          <v-col cols="12" md="4">
            <Title text="defaults.personalData" />
            <text-field
              id="player.name"
              label="defaults.name"
              :rules="[rules.required]"
              v-model="player.name"
            />
            <text-field
              id="player.surename"
              label="defaults.surename"
              :rules="[rules.required]"
              v-model="player.surenames"
            />
            <v-menu
              v-model="birthMenu"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="player.birth"
                  :value="formatedBirth"
                  append-icon="mdi-calendar"
                  readonly
                  hide-details
                  filled
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  :label="$ml.get('defaults.birth')"
                />
              </template>
              <v-date-picker
                id="player.birth.picker"
                v-model="formatedBirth"
                @input="birthMenu = false"
              />
            </v-menu>
            <text-field
              id="player.address"
              label="defaults.address"
              v-model="player.address"
            />
            <text-field
              id="player.population"
              label="defaults.population"
              v-model="player.population"
            />
            <text-field
              id="player.postalCode"
              label="defaults.postalCode"
              v-model="player.postalCode"
            />
            <text-field id="player.DNI" label="DNI" v-model="player.DNI" />
            <text-field
              id="player.CatSalut"
              label="CatSalut"
              v-model="player.CATsalut"
            />
            <v-autocomplete
              :label="$ml.get('defaults.size')"
              filled
              hide-details
              menu-props="offset-y"
              class="mb-4"
              :items="sizes"
              v-model="player.size"
            />
            <v-autocomplete
              :label="$ml.get('defaults.program')"
              filled
              hide-details
              menu-props="offset-y"
              :items="programs"
              v-model="player.program"
            />
          </v-col>
          <v-col cols="12" md="4">
            <Title text="players.parentalData" class="mb-2" />
            <text-field
              id="player.motherName"
              :label="`${$ml.get('defaults.fullName')} (${$ml.get(
                'defaults.mother'
              )})`"
              v-model="player.motherName"
            />
            <text-field
              id="player.motherEmail"
              :label="`${$ml.get('defaults.email')} (${$ml.get(
                'defaults.mother'
              )})`"
              v-model="player.motherEmail"
            />
            <text-field
              id="player.motherPhone"
              :label="`${$ml.get('defaults.phone')} (${$ml.get(
                'defaults.mother'
              )})`"
              v-model="player.motherPhone"
            />
            <text-field
              id="player.fatherName"
              :label="`${$ml.get('defaults.fullName')} (${$ml.get(
                'defaults.father'
              )})`"
              v-model="player.fatherName"
            />
            <text-field
              id="player.fatherEmail"
              :label="`${$ml.get('defaults.email')} (${$ml.get(
                'defaults.father'
              )})`"
              v-model="player.fatherEmail"
            />
            <text-field
              id="player.fatherPhone"
              :label="`${$ml.get('defaults.phone')} (${$ml.get(
                'defaults.father'
              )})`"
              v-model="player.fatherPhone"
            />
            <Title text="players.evaluation" />
            <div
              class="
                d-flex
                justify-space-around
                mb-4
                mt-4
                flex-row flex-md-column flex-lg-row
              "
            >
              <v-menu
                offset-y
                v-for="(semaphore, index) in playerSemaphores"
                :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="semaphores[player[`semaphore${semaphore}`]]"
                    v-on="on"
                    v-bind="attrs"
                    min-width="96"
                    class="mb-2"
                  >
                    {{ $ml.get(`players.${semaphore.toLowerCase()}`) }}
                  </v-btn>
                </template>

                <v-list color="amber lighten-1">
                  <v-list-item class="d-flex justify-center">
                    <v-btn
                      color="white"
                      fab
                      small
                      @click="$set(player, `semaphore${semaphore}`, null)"
                    />
                  </v-list-item>
                  <v-list-item
                    v-for="(color, index) in semaphores"
                    :key="color"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      :color="color"
                      fab
                      small
                      @click="$set(player, `semaphore${semaphore}`, index)"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-textarea
              id="player.lesions"
              filled
              rows="1"
              label="Lesions"
              v-model="player.lesions"
              auto-grow
              hide-details
              class="mb-4"
            />
            <v-textarea
              id="player.diseases"
              filled
              rows="1"
              label="Malalties"
              v-model="player.diseases"
              auto-grow
              hide-details
            />
          </v-col>
        </v-row>
        <v-row v-if="playerId">
          <v-col>
            <Documents
              type="players"
              :id="playerId"
              :files="player.files || []"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="savePlayer()"
        class="mr-4"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        color="red"
        @click="deleteDialog = true"
        v-if="$auth.user.isAdmin && playerId"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-dialog v-model="deleteDialog" max-width="500" persistent>
        <DeleteConfirmation @no="deleteDialog = false" @yes="remove" />
      </v-dialog>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import Title from "../../components/shared/Title.vue";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";
import TextField from "../../components/shared/text-field.vue";
import FileUpload from "../../components/Files/FileUpload.vue";
import Avatar from "../../components/Files/Avatar.vue";
import Documents from "../../components/Files/Documents.vue";

import alerts from "../../constants/alerts";
import rules from "../../constants/rules";
import {
  programs,
  sizes,
  semaphores,
  playerSemaphores,
} from "../../constants/selectorsList";

import objDiff from "../../utils/objDiff";

const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

export default {
  name: "PlayerDetail",
  components: {
    Title,
    DeleteConfirmation,
    CloseConfirmation,
    TextField,
    FileUpload,
    Avatar,
    Documents,
  },
  data: () => ({
    player: {},
    file: null,
    fileUrl: "",

    formatedBirth: "",
    savedPlayer: null,

    birthMenu: false,
    deleteDialog: false,
    closeDialog: false,

    semaphores,
    playerSemaphores,
    sizes,
    programs,
    rules,
  }),
  methods: {
    ...mapActions([
      "getById",
      "update",
      "delete",
      "sendFile",
      "getList",
      "create",
    ]),
    ...mapMutations(["setAlert", "addFile", "openDetails"]),
    async getSavedPlayer() {
      this.savedPlayer = await this.getById({
        type: "players",
        id: this.playerId,
      });
    },
    definePlayer() {
      this.player = { ...this.savedPlayer };
      this.formatedBirth = this.savedPlayer?.birth?.slice(0, 10);
    },
    onSelectAvatar(file) {
      const isValidType = allowedTypes.some((type) => type === file.type);
      if (isValidType) {
        this.fileUrl = URL.createObjectURL(file);
        this.file = file;
      } else {
        this.setAlert(alerts.invalidExtension);
      }
    },
    async savePlayer() {
      if (this.$refs.form.validate()) {
        const player = { ...this.playerToUpdate };
        let file;
        if (this.file) {
          const fileExtension = this.file.name.split(".").pop();
          file = await this.sendFile({
            file: this.file,
            fileName: `avatar.${fileExtension}`,
            type: "players",
            id: this.playerId,
          });
          player.avatar = file._id;
        }

        let playerId = this.playerId;
        if (!playerId) {
          const playerToCreate = {
            name: this.player.name,
            surenames: this.player.surenames,
          };

          const player = await this.create({
            type: "players",
            createData: playerToCreate,
          });

          playerId = player._id;
        }

        this.update({
          type: "players",
          id: playerId,
          updateData: player,
          filter: { teams: this.$auth.user.teams },
        });
      } else this.setAlert(alerts.emptyError);
    },
    remove() {
      this.delete({
        type: "players",
        id: this.playerId,
      });
    },
    closeDetails() {
      if (Object.keys(this.playerToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["teams", "details"]),
    playerId() {
      return this.details.id;
    },
    playerToUpdate() {
      const playerDiff = objDiff(this.player, this.savedPlayer);

      if (this.savedPlayer?.birth) {
        const savedBirth = this.savedPlayer.birth.slice(0, 10);
        if (savedBirth !== this.formatedBirth)
          playerDiff.birth = new Date(this.formatedBirth);
      } else if (this.formatedBirth) {
        playerDiff.birth = new Date(this.formatedBirth);
      }

      if (playerDiff.teams) {
        playerDiff.teamsToAdd = playerDiff.teams.filter(
          (teamId) =>
            !this.savedPlayer.teams.find((savedId) => teamId === savedId)
        );

        playerDiff.teamsToRemove = this.savedPlayer.teams.filter(
          (savedId) => !playerDiff.teams.find((teamId) => teamId === savedId)
        );

        if (
          !(playerDiff.teamsToAdd.length || playerDiff.teamsToRemove.length)
        ) {
          delete playerDiff.teams;
          delete playerDiff.teamsToAdd;
          delete playerDiff.teamsToRemove;
        }
      }

      return playerDiff;
    },
    playerTeams() {
      const playerTeams = this.player.teams;
      if (!playerTeams?.length) return [];
      return this.teams.filter((team) =>
        playerTeams.some((playerTeam) => playerTeam === team._id)
      );
    },
  },
  created() {
    if (!this.teams.length) {
      const filter = { teams: this.$auth.user.teams };
      this.getList({ type: "teams", filter });
    }

    if (this.playerId) this.getSavedPlayer().then(this.definePlayer);
    else this.savedPlayer = { teams: [] };
  },
};
</script>
