<template>
  <v-card class="card pa-6" outlined>
    <Title text="matches.goal" icon="mdi-close" @icon="$emit('close')" />
    <v-container fluid>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6" class="d-flex justify-center">
            <v-switch
              v-model="wssGoal"
              inset
              :label="wssGoal ? 'WSS' : rivalName"
            />
          </v-col>
          <v-col cols="12" md="6">
            <text-field
              id="goal.minute"
              label="matches.minute"
              type="number"
              v-model="goal.minute"
              :rules="[rules.positive]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :label="$ml.get('players.title')"
              :disabled="!wssGoal"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-4"
              item-text="name"
              item-value="_id"
              :items="players"
              v-model="goal.player"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row class="d-flex justify-center mb-2">
        <v-btn large @click="addGoal">
          {{ $ml.get("defaults.add") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import alerts from "../../constants/alerts";

import rules from "../../constants/rules";

import Title from "../shared/Title.vue";
import TextField from "../shared/text-field.vue";

export default {
  components: { Title, TextField },
  props: { update: Boolean, players: Array, rivalName: String },
  name: "EventGoals",
  data: () => ({
    goal: {},
    wssGoal: true,
    rules,
  }),
  methods: {
    ...mapMutations(["setAlert"]),
    addGoal() {
      if (!this.$refs.form.validate()) return this.setAlert(alerts.emptyError);
      if (this.wssGoal && !this.goal.player)
        return this.setAlert(alerts.emptyError);

      this.$emit("eventGoal", { ...this.goal });
      this.$refs.form.reset();
      this.$emit("close");
    },
  },
  watch: {
    update() {
      this.goal = {};
    },
    wssGoal() {
      this.goal.player = undefined;
    },
  },
};
</script>
