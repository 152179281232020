<template>
  <v-card>
    <v-container v-if="savedTeam">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6">
            <Title text="teams.teamData" />

            <text-field
              id="team.name"
              label="defaults.name"
              v-model="team.name"
              :rules="[rules.required]"
            />
            <text-field
              id="team.season"
              label="teams.season"
              v-model="team.season"
              :rules="[rules.required]"
            />
            <v-autocomplete
              filled
              hide-details
              :items="sportList"
              item-text="text"
              item-value="value"
              :label="$ml.get('defaults.sport')"
              v-model="team.sport"
              class="mb-2"
              menu-props="offset-y"
            />
            <v-autocomplete
              filled
              :items="teamProgram"
              :label="$ml.get('defaults.program')"
              hide-details
              v-model="team.program"
              class="mb-2"
              menu-props="offset-y"
            />
            <text-field
              id="team.matchTime"
              type="number"
              label="teams.matchTime"
              v-model="team.matchTime"
              :rules="[rules.positive]"
            />
            <Title text="teams.trainings.title" />
            <Trainings
              :trainings="team.trainings"
              @update="(trainings) => (team.trainings = trainings)"
            />

            <v-btn block class="mt-4" :href="team.workLine" target="_blank">
              {{ $ml.get("teams.workLine") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <Title text="defaults.players" />
            <v-autocomplete
              v-model="team.players"
              :items="players"
              multiple
              hide-details
              filled
              chips
              deletable-chips
              menu-props="offset-y"
              item-text="name"
              item-value="_id"
              class="mb-4"
            />

            <Title text="coaches.title" class="mb-1" />
            <v-autocomplete
              v-model="team.coaches"
              :items="coaches"
              multiple
              hide-details
              filled
              chips
              deletable-chips
              menu-props="offset-y"
              item-text="name"
              item-value="_id"
              class="mb-4"
            />
          </v-col>
        </v-row>
        <v-row v-if="teamId">
          <v-col>
            <Documents type="teams" :id="teamId" :files="team.files || []" />
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="saveTeam()"
        class="mr-4"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        color="red"
        @click="deleteDialog = true"
        v-if="$auth.user.isAdmin && teamId"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-dialog v-model="deleteDialog" max-width="500" persistent>
        <DeleteConfirmation @no="deleteDialog = false" @yes="remove" />
      </v-dialog>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import Trainings from "../../components/shared/Trainings.vue";
import Title from "../../components/shared/Title.vue";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";
import TextField from "../../components/shared/text-field.vue";
import Documents from "../../components/Files/Documents.vue";

import alerts from "../../constants/alerts";
import rules from "../../constants/rules";
import { sports, teamProgram } from "../../constants/selectorsList";

import objDiff from "../../utils/objDiff";

export default {
  components: {
    Trainings,
    Title,
    DeleteConfirmation,
    TextField,
    Documents,
    CloseConfirmation,
  },
  name: "TeamDetail",
  data: () => ({
    team: {},
    savedTeam: null,

    time: "",
    timeMenu: false,

    deleteDialog: false,
    closeDialog: false,

    rules,
    teamProgram,
  }),
  methods: {
    ...mapActions(["getById", "update", "delete", "getList", "create"]),
    ...mapMutations(["setAlert", "openDetails"]),
    async getSavedTeam() {
      this.savedTeam = await this.getById({
        type: "teams",
        id: this.teamId,
      });
    },
    defineTeam() {
      this.team = { ...this.savedTeam };
      this.time = this.savedTeam.partTime;
    },
    async saveTeam() {
      if (this.$refs.form.validate()) {
        let teamId = this.teamId;
        if (!teamId) {
          const teamToCreate = {
            name: this.team.name,
            surenames: this.team.surenames,
          };

          const team = await this.create({
            type: "teams",
            createData: teamToCreate,
          });

          teamId = team._id;
        }

        this.update({
          type: "teams",
          id: teamId,
          updateData: this.teamToUpdate,
        });
      } else this.setAlert(alerts.emptyError);
    },
    remove() {
      this.delete({
        type: "teams",
        id: this.teamId,
      });
    },
    closeDetails() {
      if (Object.keys(this.teamToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["players", "coaches", "details"]),
    teamId() {
      return this.details.id;
    },
    teamToUpdate() {
      const teamDiff = objDiff(this.team, this.savedTeam);

      if (teamDiff.players) {
        teamDiff.playersToAdd = teamDiff.players.filter(
          (playerId) =>
            !this.savedTeam.players.find((savedId) => playerId === savedId)
        );

        teamDiff.playersToRemove = this.savedTeam.players.filter(
          (savedId) =>
            !teamDiff.players.find((playerId) => playerId === savedId)
        );

        if (
          !(teamDiff.playersToAdd.length || teamDiff.playersToRemove.length)
        ) {
          delete teamDiff.players;
          delete teamDiff.playersToAdd;
          delete teamDiff.playersToRemove;
        }
      }

      if (teamDiff.coaches) {
        teamDiff.coachesToAdd = teamDiff.coaches.filter(
          (coachId) =>
            !this.savedTeam.coaches.find((savedId) => coachId === savedId)
        );

        teamDiff.coachesToRemove = this.savedTeam.coaches.filter(
          (savedId) =>
            !teamDiff.coaches.find((playerId) => playerId === savedId)
        );

        if (
          !(teamDiff.coachesToAdd.length || teamDiff.coachesToRemove.length)
        ) {
          delete teamDiff.coaches;
          delete teamDiff.coachesToAdd;
          delete teamDiff.coachesToRemove;
        }
      }

      return teamDiff;
    },
    sportList() {
      return sports.map((sport) => ({
        text: this.$ml.get(`defaults.${sport}`),
        value: sport,
      }));
    },
  },
  watch: {
    time() {
      this.team.partTime = this.time;
    },
  },
  created() {
    const filter = { teams: this.$auth.user.teams };
    if (!this.players.length) this.getList({ type: "players", filter });
    if (!this.coaches.length) this.getList({ type: "coaches", filter });

    if (this.teamId) this.getSavedTeam().then(this.defineTeam);
    else this.savedTeam = this.savedTeam = { players: [], coaches: [] };
  },
};
</script>
