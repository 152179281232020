<template>
  <svg
    width="684.000000pt"
    height="476.000000pt"
    viewBox="0 0 684.000000 476.000000"
  >
    <g
      transform="translate(0.000000,476.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M5385 4749 c-156 -24 -238 -60 -571 -252 -305 -175 -1069 -601 -1879 -1046 -625 -344 -665 -368 -757 -448 -191 -164 -280 -359 -281 -613 0 -255 55 -395 356 -899 219 -367 334 -603 383 -786 29 -107 10 -229 -46 -297 -39 -48 -70 -49 -183 -9 -45 16 -210 59 -367 95 -157 37 -638 155 -1070 262 -563 139 -793 193 -815 188 -115 -25 -175 -138 -136 -257 14 -42 85 -110 138 -132 35 -15 1976 -501 2155 -540 70 -15 81 -15 138 -1 41 11 80 30 118 59 32 24 86 64 122 89 238 167 340 379 300 625 -29 185 -104 350 -395 873 -280 505 -335 637 -335 810 0 108 21 163 89 230 60 59 26 39 1641 958 827 470 932 528 1242 683 194 97 247 120 270 115 77 -15 320 -188 457 -326 355 -354 647 -935 571 -1135 -15 -40 -56 -82 -102 -106 -68 -35 -128 -27 -719 96 -1368 285 -1556 322 -1611 310 -48 -11 -105 -60 -123 -108 -22 -57 -9 -110 41 -161 65 -67 155 -100 404 -146 63 -12 304 -62 535 -112 1084 -231 1161 -244 1287 -214 298 70 508 252 573 496 21 79 19 228 -4 325 -52 214 -231 539 -445 805 -112 142 -311 347 -394 408 -177 131 -395 191 -587 161z"
      />
    </g>
  </svg>
  <!--
  <svg viewBox="0 0 40 52">
    <g transform="translate(0,52) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M169 518 c0 -2 -26 -113 -57 -248 -53 -226 -59 -245 -80 -248 -12 -2 -22 -8 -22 -13 0 -5 79 -9 190 -9 120 0 190 4 190 10 0 6 -11 10 -24 10 -23 0 -26 11 -86 250 -60 239 -63 250 -86 250 -13 0 -24 -1 -25 -2z m42 -132 c-16 -19 -41 -10 -41 14 0 24 21 31 39 14 11 -10 12 -16 2 -28z m5 -115 c-4 -6 -16 -11 -27 -11 -21 0 -27 35 -6 43 16 6 41 -18 33 -32z m-5 -135 c-16 -19 -41 -10 -41 14 0 24 21 31 39 14 11 -10 12 -16 2 -28z"
      />
    </g>
  </svg>
  -->
</template>

<script>
export default {
  name: "Fence",
};
</script>
