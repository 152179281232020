<template>
  <v-card>
    <section>
      <v-container v-if="savedPlayer">
        <v-row>
          <v-col cols="12" md="4">
            <Avatar :src="player.avatar && player.avatar.route" class="mb-4" />
            <text-field
              id="player.name"
              :label="$ml.get('defaults.name')"
              readonly
              v-model="savedPlayer.name"
            />
            <text-field
              id="player.surename"
              :label="$ml.get('defaults.surename')"
              readonly
              v-model="savedPlayer.surenames"
            />
            <text-field
              id="player.email"
              :label="$ml.get('defaults.email')"
              readonly
              v-model="savedPlayer.email"
              append-icon="mdi-email"
            />
            <text-field
              id="player.phone"
              :label="$ml.get('defaults.phone')"
              readonly
              v-model="savedPlayer.phone"
              append-icon="mdi-phone"
            />
          </v-col>

          <v-col cols="12" md="8">
            <v-form ref="form">
              <Title text="accounting.scholarships" />
              <div class="d-flex justify-space-around">
                <v-checkbox v-model="player.scholarshipsWss" label="WSS" />

                <v-checkbox
                  v-model="player.scholarshipsCouncil"
                  label="Ajuntament"
                />
              </div>
              <Title text="accounting.quota" />
              <div class="d-flex flex-wrap justify-space-between">
                <v-checkbox
                  v-model="player.octoberQuota"
                  :label="$ml.get('accounting.october')"
                />
                <v-checkbox
                  v-model="player.novemberQuota"
                  :label="$ml.get('accounting.november')"
                />
                <v-checkbox
                  v-model="player.decemberQuota"
                  :label="$ml.get('accounting.december')"
                />
                <v-checkbox
                  v-model="player.januaryQuota"
                  :label="$ml.get('accounting.january')"
                />
                <v-checkbox
                  v-model="player.februaryQuota"
                  :label="$ml.get('accounting.february')"
                />
                <v-checkbox
                  v-model="player.marchQuota"
                  :label="$ml.get('accounting.march')"
                />
                <v-checkbox
                  v-model="player.aprilQuota"
                  :label="$ml.get('accounting.april')"
                />
              </div>

              <Title text="Altres" />
              <div class="d-flex justify-center">
                <v-checkbox
                  v-model="player.enrollment"
                  :label="$ml.get('accounting.enrollment')"
                />
              </div>
              <Title text="trackings.comments" />
              <v-textarea filled hide-details v-model="player.comments" />
            </v-form>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="savePlayer()"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>

      <v-btn
        fab
        large
        fixed
        bottom
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="openPlayer()"
      >
        <v-icon style="fill: white">$player</v-icon>
      </v-btn>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </section>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Title from "../../components/shared/Title.vue";
import TextField from "../../components/shared/text-field.vue";
import Avatar from "../../components/Files/Avatar.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";

import alerts from "../../constants/alerts";

import objDiff from "../../utils/objDiff";

export default {
  name: "AccountingDetail",
  components: { Title, TextField, Avatar, CloseConfirmation },
  data: () => ({
    player: {},
    savedPlayer: null,
    closeDialog: false,
  }),
  methods: {
    ...mapActions(["update", "getById"]),
    ...mapMutations(["setAlert", "openDetails"]),
    async getSavedPlayer() {
      this.savedPlayer = await this.getById({
        type: "players",
        id: this.playerId,
      });
    },
    savePlayer() {
      if (this.$refs.form.validate()) {
        this.update({
          type: "players",
          id: this.playerId,
          updateData: this.playerToUpdate,
        });
      } else this.setAlert(alerts.emptyError);
    },
    openPlayer() {
      this.openDetails({ type: "players", id: this.playerId });
    },
    closeDetails() {
      if (Object.keys(this.playerToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["details"]),
    playerId() {
      return this.details.id;
    },
    playerToUpdate() {
      return objDiff(this.player, this.savedPlayer);
    },
  },
  created() {
    this.getSavedPlayer().then((player) => (this.player = { ...player }));
  },
};
</script>
