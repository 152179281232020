<template>
  <section>
    <Title text="defaults.documents" />
    <div class="d-flex justify-space-between">
      <div :style="{ width: '48%' }">
        <FileUpload @change="(newFile) => (file = newFile)" />
        <text-field
          id="file.name"
          :label="$ml.get('defaults.fileName')"
          v-model="fileName"
        />
        <v-btn block @click="onAddFile" :disabled="disabled">
          {{ $ml.get("defaults.create") }}
        </v-btn>
      </div>
      <v-card class="ml-4" :style="{ width: '48%' }">
        <v-list-item v-for="file in files" :key="file._id">
          <v-list-item-content>
            <v-list-item-title>
              <div class="d-flex justify-space-between">
                <a
                  :href="`https://api.barcelonawss.com/${file.route}`"
                  target="_blank"
                  class="ma-0 mt-2"
                >
                  {{ file.route.split("/").pop() }}
                </a>
                <v-btn
                  icon
                  color="red"
                  @click="() => onDeleteFile(file._id)"
                  :disabled="disabled"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import Title from "../shared/Title.vue";
import FileUpload from "./FileUpload.vue";
import TextField from "../../components/shared/text-field.vue";
import alerts from "../../constants/alerts";

export default {
  name: "Documents",
  components: { Title, FileUpload, TextField },
  props: { type: String, id: String, files: Array, disabled: Boolean },
  data: () => ({
    file: null,
    fileName: "",
  }),
  methods: {
    ...mapActions(["sendFile", "deleteFile", "update"]),
    ...mapMutations(["setAlert", "addFile"]),
    async onAddFile() {
      if (!this.file) return this.setAlert(alerts.invalidExtension);
      let fileName;
      if (this.fileName) {
        const fileExtension = this.file.name.split(".").pop();
        fileName = `${this.fileName}.${fileExtension}`;
      } else fileName = this.file.name;

      const file = await this.sendFile({
        file: this.file,
        fileName: fileName,
        type: this.$props.type,
        id: this.$props.id,
      });

      await this.update({
        type: this.$props.type,
        id: this.$props.id,
        updateData: { $addToSet: { files: file._id } },
      });

      this.setAlert(alerts.created);
    },
    async onDeleteFile(fileId) {
      await this.deleteFile(fileId);
      await this.update({
        type: this.$props.type,
        id: this.$props.id,
        updateData: { $pull: { files: fileId } },
      });
      this.setAlert(alerts.deleted);
    },
  },
};
</script>
