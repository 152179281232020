export const specificity = ["general", "directed", "special", "competitive"];

export const taskType = [
  "game",
  "RT1",
  "RT2",
  "RT3",
  "round",
  "conservation",
  "position",
  "situation",
  "atacDefense",
  "match",
];

export const teaching = ["introduction", "learning", "control"];

export const content = [
  "connectToCommunicate",
  "moveToFind",
  "chopToFinish",
  "putToFinish",
  "occupyNotToMakePossible",
  "pressToForce",
  "occupyToCover",
  "identifyToPrevent",
  "beInLinePassing",
  "adjustTheAmplitude",
  "adjustTheDepth",
  "passToKeep",
  "passToOvercome",
  "coverage",
  "swap",
  "deletePassLine",
  "controlToGive",
  "controlToOvercome",
  "controlToProtect",
  "drivingToOvercome",
  "drivingToFix",
  "wateredToOvercome",
  "unmarkedToReceive",
  "shotToPlace",
  "powerfulShot",
  "finishedToScore",
  "dialingToWatch",
  "anticipateToAttack",
  "interceptToRefuse",
  "brakeInput",
  "entryToRecover",
  "entryToRefuse",
  "individualMarkingWithinTheArea",
  "forceDisplacementChangesOfDirection",
  "forceDisplacementStartsAndBrakes",
  "forceShiftRhythmChanges",
  "strongStrugglePush",
  "strongStruggleUnbalance",
  "forceJumpImpulse",
  "forceJumpActionsInSuspension",
  "strongPerformanceOnBallPassada",
  "strongPerformanceOnBallHitting",
  "strongPerformanceOnBallReceptions",
  "strongActionOnBallRefusals",
  "eoEoISeeYou",
  "iDecide",
  "nowWeWillMark",
  "yourMark",
  "readToRecover",
  "IForceYouTo",
  "youWontEvenTouchIt",
  "youWillNotScore",
];

export const intention = ["pass", "finish", "recover"];
