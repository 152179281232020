<template>
  <v-card>
    <v-container v-if="savedSession">
      <v-form ref="form" :disabled="isDisabled">
        <v-row>
          <v-col cols="12" md="4">
            <Title text="sessions.title" class="mb-2" />
            <text-field
              id="session.name"
              label="defaults.name"
              readonly
              :value="sessionName"
              :rules="[rules.required]"
            />
            <v-autocomplete
              :label="$ml.get('teams.title')"
              filled
              menu-props="offset-y"
              hide-details
              class="mb-4"
              item-text="name"
              item-value="_id"
              :items="teams"
              v-model="session.team"
              @change="() => (session.assistance = [])"
            />
            <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="session.date"
                  :value="formatedDate"
                  append-icon="mdi-calendar"
                  readonly
                  hide-details
                  filled
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  :label="$ml.get('sessions.date')"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                id="session.date.picker"
                v-model="formatedDate"
                @input="dateMenu = false"
              />
            </v-menu>
            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                  v-model="time"
                  :label="$ml.get('sessions.time')"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  hide-details
                  class="mb-4"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                />
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="$refs.menu.save(time)"
              />
            </v-menu>
            <div class="d-flex">
              <text-field
                id="session.microcycle"
                label="sessions.microcycle"
                type="number"
                v-model="session.microcycle"
                :rules="[rules.positive]"
                class="mr-2"
              />
              <text-field
                id="session.session"
                label="sessions.session"
                type="number"
                v-model="session.session"
                :rules="[rules.positive]"
                class="ml-2"
              />
            </div>
            <v-textarea
              :label="$ml.get('sessions.material')"
              filled
              hide-details
              class="mb-4"
              v-model="session.material"
            />

            <v-autocomplete
              :label="$ml.get('sessions.asistence')"
              :disabled="!session.team"
              filled
              hide-details
              item-text="name"
              item-value="_id"
              multiple
              menu-props="offset-y"
              class="mb-4"
              :items="teamPlayers"
              v-model="session.assistance"
            />
          </v-col>
          <v-col cols="12" md="8">
            <Title text="defaults.tasks" />
            <div
              v-for="(task, index) in session.tasks"
              :key="index"
              class="d-flex justify-space-between"
            >
              <div class="d-sm-flex d-block">
                <ShowField :task="task" />
                <div class="ml-4 mt-4">
                  <p class="ma-0">
                    <span class="mr-4">
                      <b>{{ $ml.get("sessions.serie") }}:</b>
                      {{ task.series }}
                    </span>
                    <span>
                      <b>{{ $ml.get("sessions.totalTime") }}:</b>
                      {{
                        getTaskTime(
                          task.serieTime,
                          task.serieRecuperation,
                          task.series
                        )
                      }}min
                    </span>
                  </p>

                  <p class="ma-0 mb-4">
                    <span class="mr-4">
                      <b>{{ $ml.get("sessions.serieTime") }}:</b>
                      {{ task.serieTime }}min
                    </span>
                    <span v-if="task.serieRecuperation">
                      <b>{{ $ml.get("sessions.serieRecuperation") }}:</b>
                      {{ task.serieRecuperation }}min
                    </span>
                  </p>
                  <p
                    v-if="task.task && task.task.explanation"
                    :style="{ 'max-height': '65px', overflow: 'hidden' }"
                  >
                    <b>{{ $ml.get("tasks.explanation") }}:</b>
                    {{ task.task.explanation }}
                  </p>
                  <p
                    v-if="task.instrucions"
                    :style="{ 'max-height': '35px', overflow: 'hidden' }"
                  >
                    <b>{{ $ml.get("defaults.teams") }}:</b>
                    {{ task.instrucions }}
                  </p>
                </div>
              </div>
              <v-btn icon @click="deleteTask(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-btn
              block
              large
              class="text-h4"
              :disabled="isDisabled"
              @click="() => (taskDialog = true)"
            >
              +
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="sessionId">
          <v-col>
            <Documents
              type="sessions"
              :id="sessionId"
              :files="session.files || []"
              :disabled="isDisabled"
            />
          </v-col>
        </v-row>
        <v-row class="mb-6" v-if="sessionId">
          <v-col class="mb-6">
            <SessionPDF :pdf="pdf" :session="session" />
          </v-col>
        </v-row>
      </v-form>
      <v-dialog v-model="taskDialog" width="95%" persistent>
        <SessionTask
          :update="taskDialog"
          @close="() => (taskDialog = false)"
          @task="addTask"
        />
      </v-dialog>
      <v-btn
        fab
        large
        fixed
        top
        left
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="closeDetails()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        right
        dark
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="saveData()"
        v-if="!isDisabled"
        class="mr-4"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        color="red"
        @click="deleteDialog = true"
        v-if="$auth.user.isAdmin || session.creator === $auth.user.email"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        fab
        large
        fixed
        bottom
        left
        :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
        @click="clone = true"
        style="margin-left: 72px"
        v-if="sessionId"
      >
        <v-icon>mdi-clipboard-multiple-outline</v-icon>
      </v-btn>
      <v-tooltip :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            fab
            large
            fixed
            bottom
            dark
            right
            :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
            @click="pdf = !pdf"
            style="margin-right: 95px"
            v-if="sessionId"
          >
            <v-icon>mdi-file-download</v-icon>
          </v-btn>
        </template>

        <b>{{ $ml.get("sessions.pdf") }}</b>
      </v-tooltip>

      <v-dialog v-model="deleteDialog" max-width="500" persistent>
        <DeleteConfirmation @no="deleteDialog = false" @yes="remove" />
      </v-dialog>

      <v-dialog v-model="closeDialog" max-width="500" persistent>
        <CloseConfirmation @no="closeDialog = false" @yes="openDetails(null)" />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import objDiff from "../../utils/objDiff";

import ShowField from "../../components/TaskField/ShowField.vue";
import Title from "../../components/shared/Title.vue";
import SessionTask from "../../components/Modals/SessionTask.vue";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation.vue";
import CloseConfirmation from "../../components/Modals/CloseConfirmation.vue";
import TextField from "../../components/shared/text-field.vue";
import SessionPDF from "../../components/SessionPDF/SessionPDF.vue";
import Documents from "../../components/Files/Documents.vue";

import alerts from "../../constants/alerts";
import rules from "../../constants/rules";

export default {
  name: "SessionDetail",
  components: {
    ShowField,
    Title,
    SessionTask,
    DeleteConfirmation,
    CloseConfirmation,
    TextField,
    SessionPDF,
    Documents,
  },
  data: () => ({
    session: { tasks: [] },
    savedSession: null,

    formatedDate: "",

    time: "",
    timeMenu: false,
    dateMenu: false,

    taskDialog: false,
    deleteDialog: false,
    closeDialog: false,
    clone: false,

    pdf: false,

    rules,
  }),
  methods: {
    ...mapActions(["update", "delete", "getById", "getList", "create"]),
    ...mapMutations(["setAlert", "openDetails"]),
    async getSavedSession() {
      const session = await this.getById({
        type: "sessions",
        id: this.sessionId,
      });

      this.savedSession = {
        ...session,
        tasks: session.tasks.map((task) => ({
          ...task,
          taskField: task.task.taskField,
        })),
      };
    },
    defineSession() {
      this.session = { ...this.savedSession };
      this.formatedDate = this.savedSession.date?.slice(0, 10);
      this.time = this.session.time;
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.session.date = new Date(this.session.date);

        if (!this.sessionId) {
          if (this.clone) {
            this.session._id = undefined;
            this.session.__v = undefined;
            this.session.files = [];
          }
          this.session.creator = this.$auth.user.email;
          this.create({
            type: "sessions",
            createData: this.session,
          });
        } else {
          this.update({
            type: "sessions",
            id: this.sessionId,
            updateData: this.sessionToUpdate,
          });
        }
      } else this.setAlert(alerts.emptyError);
    },
    async remove() {
      await this.delete({
        type: "sessions",
        id: this.sessionId,
      });
    },
    addTask(task) {
      this.session.tasks = [...this.session.tasks, task];
    },
    deleteTask(taskIndex) {
      if (this.isDisabled) return;
      this.session.tasks = this.session.tasks.filter(
        (task, index) => index !== taskIndex
      );
    },
    getTaskTime(serieTime, serieRecuperation, series) {
      return (+serieTime + +serieRecuperation) * series;
    },
    closeDetails() {
      if (Object.keys(this.sessionToUpdate).length) this.closeDialog = true;
      else this.openDetails(null);
    },
  },
  computed: {
    ...mapGetters(["sessions", "details", "teams", "players"]),
    sessionId() {
      return this.clone ? null : this.details.id;
    },
    sessionToUpdate() {
      const sessionDiff = objDiff(this.session, this.savedSession);

      if (this.savedSession?.date) {
        const savedDate = this.savedSession.date.slice(0, 10);
        if (savedDate !== this.formatedDate)
          sessionDiff.date = new Date(this.formatedDate);
      }

      return sessionDiff;
    },
    sessionName() {
      const program = this.session.teamProgram || "_";
      const teamName = this.session.teamName || "_";
      const session = this.session.session || "_";
      const date = this.session.date
        ? new Date(this.session.date).toLocaleString("es-ES").slice(0, 10)
        : "xx/xx/xxxx";

      return `${program} - ${teamName} - ${session} (${date})`;
    },
    isDisabled() {
      if (!this.sessionId || this.$auth.user.isAdmin) return false;
      return this.$auth.user.email !== this.session.creator;
    },
    teamPlayers() {
      return this.players.filter((player) =>
        player.teams.some((team) => team === this.session.team)
      );
    },
  },
  created() {
    const filter = { teams: this.$auth.user.teams };
    if (!this.players.length) this.getList({ type: "players", filter });
    if (!this.teams.length) this.getList({ type: "teams", filter });

    if (this.sessionId) this.getSavedSession().then(this.defineSession);
    else this.savedSession = { tasks: [] };
  },
  watch: {
    formatDate() {
      if (this.formatedDate) this.session.date = new Date(this.formatedDate);
    },
    time() {
      this.session.time = this.time;
    },
  },
};
</script>