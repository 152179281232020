<template>
  <div
    class="mb-2 pb-4 d-flex justify-space-between"
    :class="{
      'title__text-black': !$vuetify.theme.dark,
      'title__text-white': $vuetify.theme.dark,
      'text-h4': icon && !$vuetify.breakpoint.xs,
      'text-h5': !icon && !$vuetify.breakpoint.md,
      'text-subtitle-1': icon && $vuetify.breakpoint.xs,
      'text-subtitle-1': !icon && $vuetify.breakpoint.md,
    }"
  >
    <span
      class="py-2 px-6"
      :class="{
        'title__background-black white--text': !$vuetify.theme.dark,
        'title__background-white black--text': $vuetify.theme.dark,
      }"
    >
      {{ text.split(".").length > 1 ? $ml.get(text) : text }}
    </span>
    <v-icon v-if="icon" size="30" class="pr-4" @click="$emit('icon')">
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    text: String,
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="sass" scoped>
.title__text-black
  border-bottom: solid black 2px

.title__text-white
  border-bottom: solid white 2px

.title__background-black
  background-color: black

.title__background-white
  background-color: white
</style>
