var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('FiltersAndSorters',{attrs:{"icon":"mdi-scoreboard-outline","initial":"date","title":_vm.$ml.get('matches.title'),"sorters":_vm.sortersConfig},on:{"search":function (sort) { return _vm.search(sort); },"filters-reset":_vm.reset}},[_c('template',{slot:"filters"},[_c('v-autocomplete',{attrs:{"label":_vm.$ml.get('defaults.teams'),"items":_vm.teams,"item-text":"name","item-value":"_id","hide-details":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","disabled":!_vm.$auth.user.isAdmin},model:{value:(_vm.filters.team),callback:function ($$v) {_vm.$set(_vm.filters, "team", $$v)},expression:"filters.team"}}),_c('div',{staticClass:"ml-4"},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$ml.get('defaults.date'),"prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.dateText),callback:function ($$v) {_vm.dateText=$$v},expression:"dateText"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1)],1)],1)],2),_c('v-container',{staticStyle:{"min-width":"500px"}},[(_vm.matchesSearch.length)?_c('div',[_c('v-row',{staticClass:"d-flex justify-space-between text-h5 mb-2",class:{
          'separator-white': _vm.$vuetify.theme.dark,
          'separator-black': !_vm.$vuetify.theme.dark,
        }},[_c('v-col',[_c('span',{staticClass:"py-2 px-6 text-h6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("teams.title"))+" ")])]),_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"py-2 px-6 text-h6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("matches.rival"))+" ")])]),_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"py-2 px-6 text-h6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("matches.result"))+" ")])]),_c('v-col',{staticClass:"d-none d-md-block text-right"},[_c('span',{staticClass:"py-2 px-6 text-h6",class:{
              'white--text background-black': !_vm.$vuetify.theme.dark,
              'black--text background-white': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.$ml.get("defaults.date"))+" ")])])],1),_vm._l((_vm.matchesSearch),function(match){return _c('v-row',{key:match._id,staticClass:"my-1 text-h6 d-flex justify-space-between",class:{
          'match-white': _vm.$vuetify.theme.dark,
          'match-black': !_vm.$vuetify.theme.dark,
        },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.callOpenDetails(match._id)}}},[_c('v-col',{},[_vm._v(_vm._s(match.team.name))]),_c('v-col',{staticClass:"text-center"},[_vm._v(_vm._s(match.rival))]),_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(match.result[0])+" - "+_vm._s(match.result[1])+" ")]),_c('v-col',{staticClass:"d-none d-md-block text-end"},[_vm._v(" "+_vm._s(match.date[0])+" | "+_vm._s(match.date[1])+"h ")])],1)}),_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.pageLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],2):_c('div',[_c('p',{staticClass:"text-h4 text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$ml.get("matches.noMatches"))+" ")])])]),_c('v-btn',{attrs:{"fab":"","large":"","fixed":"","bottom":"","right":"","dark":"","color":_vm.$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'},on:{"click":function($event){return _vm.openNewDetails()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }