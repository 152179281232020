<template>
  <section>
    <FiltersAndSorters
      icon="$team"
      initial="creation"
      :title="$ml.get('defaults.teams')"
      :sorters="sortersConfig"
      @search="(sort) => search(sort)"
      @filters-reset="reset"
    >
      <template slot="filters">
        <v-text-field
          :label="$ml.get('defaults.name')"
          id="filter-players-name"
          v-model="filters.name"
          hide-details
          dense
          clearable
        />
      </template>
    </FiltersAndSorters>

    <v-container>
      <div v-if="teamsSearch.length">
        <v-row
          class="d-flex justify-space-between text-h5 mb-2"
          :class="{
            'separator-white': $vuetify.theme.dark,
            'separator-black': !$vuetify.theme.dark,
          }"
        >
          <v-col>
            <span
              class="py-2 px-6 text-h6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("teams.category") }}
            </span>
          </v-col>
          <v-col class="text-center">
            <span
              class="py-2 px-6 text-h6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("defaults.sport") }}
            </span>
          </v-col>
          <v-col class="d-none d-sm-block text-right">
            <span
              class="py-2 px-6 text-h6"
              :class="{
                'white--text background-black': !$vuetify.theme.dark,
                'black--text background-white': $vuetify.theme.dark,
              }"
            >
              {{ $ml.get("teams.season") }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-for="team in teamsSearch"
          :key="team._id"
          style="cursor: pointer"
          class="my-1 text-h6 d-flex justify-space-between"
          :class="{
            'team-white': $vuetify.theme.dark,
            'team-black': !$vuetify.theme.dark,
          }"
          @click="() => callOpenDetails(team._id)"
        >
          <v-col class="mx-6">{{ team.name }}</v-col>
          <v-col class="ml-4 text-center">
            {{ team.sport ? $ml.get(`defaults.${team.sport}`) : "-" }}
          </v-col>
          <v-col class="d-none d-sm-block mx-6 text-right">
            {{ team.season || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-pagination v-model="page" :length="pageLength"></v-pagination>
          </v-col>
        </v-row>
      </div>

      <v-col v-else>
        <p class="text-h4 text-center mt-4">
          {{ $ml.get("teams.noTeams") }}
        </p>
      </v-col>
    </v-container>
    <v-btn
      fab
      large
      fixed
      bottom
      right
      dark
      :color="$vuetify.theme.dark ? 'WSSpink' : 'WSSblue'"
      @click="openNewDetails()"
      v-if="this.$auth.user.isAdmin"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import FiltersAndSorters from "../../components/FiltersAndSorters/FiltersAndSorters.vue";

const sortersConfig = [
  {
    text: "alphabetic",
    icon: "mdi-sort-alphabetical-ascending",
    invertedIcon: "mdi-sort-alphabetical-descending",
    translation: "filter.alphabetic",
  },
  {
    text: "creation",
    icon: "mdi-sort-calendar-ascending",
    invertedIcon: "mdi-sort-calendar-descending",
    translation: "filter.creation",
  },
];

const defaultFilter = () => ({
  name: "",
  sorter: { filter: "creation", state: true },
});

export default {
  name: "Teams",
  components: { FiltersAndSorters },
  data: () => ({
    teamsSearch: [],
    page: 1,
    total: 1,
    filters: defaultFilter(),
    sortersConfig,
    dialog: false,
  }),
  created() {
    this.search();
  },
  methods: {
    ...mapActions(["getSearch"]),
    ...mapMutations(["openDetails"]),
    search(sort) {
      const filter = { ...this.filters, page: this.page };
      if (!this.$auth.user.isAdmin) filter.id = this.$auth.user.teams;
      if (sort) filter.sorter = sort;
      this.getSearch({ type: "teams", filter }).then(({ page, total }) => {
        this.teamsSearch = page;
        this.total = total;
      });
    },
    reset() {
      this.page = 1;
      this.filters = defaultFilter();
      this.search();
    },
    callOpenDetails(id) {
      this.openDetails({ type: "teams", id });
    },
    openNewDetails() {
      this.openDetails({ type: "teams", id: null });
    },
  },
  computed: {
    ...mapGetters(["details"]),
    pageLength() {
      return Math.ceil(this.total / 10);
    },
  },
  watch: {
    page() {
      this.search();
    },
    details() {
      if (!this.details) this.search();
    },
  },
};
</script>

<style lang="sass" scoped>
.separator-black
  border-bottom: solid black 2px

.separator-white
  border-bottom: solid white 2px

.team-black
  border-bottom: solid black 1px

.team-white
  border-bottom: solid white 1px

.background-black
  background-color: black

.background-white
  background-color: white
</style>
