<template>
  <v-text-field
    :id="id"
    :label="labelTranslation ? $ml.get(label) : label"
    :append-icon="icon"
    :rules="rules"
    :type="type"
    :readonly="readonly"
    v-model="content"
    filled
    hide-details
    class="mb-4"
  />
</template>

<script>
export default {
  name: "text-field",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    icon: String,
    value: [String, Number],
  },
  data: () => ({
    content: "",
  }),
  computed: {
    labelTranslation() {
      return this.label.split(".").length > 1;
    },
  },
  watch: {
    content() {
      this.$emit("input", this.content);
    },
    value() {
      this.content = this.$props.value;
    },
  },
  mounted() {
    this.content = this.$props.value;
  },
};
</script>